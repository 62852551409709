import React from 'react';
import { checkWebp } from '../../../../../utils/checkWebp';
import { t } from '../../../../../../js/common/translations';
import ReasonImage from '../ReasonImage';
import ReasonText from '../ReasonText';
import { ReasonOne as StyledReasonOne, ReasonTextWrapper } from '../styles';

import ReasonOneWebp from '../../../../../../images/tenant/chooseTabas/reason_1.webp';
import ReasonOneJpg from '../../../../../../images/tenant/chooseTabas/reason_1.jpg';

const ReasonOne = () => {
  const isWebp = checkWebp();

  return (
    <StyledReasonOne>
      <ReasonImage src={isWebp ? ReasonOneWebp : ReasonOneJpg} />
      <ReasonTextWrapper>
        <ReasonText
          title={t('tenant_page.choose_tabas.reasons.reason_one.title')}
          description={t('tenant_page.choose_tabas.reasons.reason_one.description_html')}
        />
      </ReasonTextWrapper>
    </StyledReasonOne>
  );
};

export default ReasonOne;
