import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const SliderContainer = styled(Swiper)`
  .swiper-button-prev, .swiper-button-next {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  height: 100%;

  .swiper-wrapper {
    height: 100%;
    width: 100%;
  }

  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
`;
