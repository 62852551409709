import styled from 'styled-components';
import { AppBar, Button } from '../elements';

export const ButtonBarItem = styled(Button).attrs({
  size: 'medium',
  sx: { m: 0.25 }
})`
  flex-shrink: 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 0.8rem;
  }
`;

export const BarContainer = styled(AppBar)`
  padding: 0.5rem 1rem;
  top: 56px;
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
  z-index: 899;
  overflow-x: auto;
`;
