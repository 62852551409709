import styled from 'styled-components';
import { Box, Typography } from '../UI/elements';
import testimonialsBg from '../../images/home/testimonials-bg.svg';

export const HeroContainer = styled(Box)`
  width: 100%;
  height: calc(100vh - 128px);
  position: relative;
`;

export const HeroImg = styled(Box)`
  width: 100%;
  height: calc(100vh - 128px);
  display: flex;
`;

export const HeroTextWrapper = styled(Box)`
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')}{
    top: 50%;
    padding: 1rem;
  }
`;

export const HeroTitle = styled(Typography)`
  white-space: nowrap;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Cambon';

  ${({ theme }) => theme.breakpoints.down('md')}{
    white-space: pre-wrap;
    text-align: center;
  }
`;

export const HeroSubtitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 400;
  font-family: 'Cambon';
  opacity: 0.7;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('md')}{
    text-align: center;
  }
`;

export const FilterWrapper = styled(Box)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.875rem;
  border-radius: 4px;

  ${({ theme }) => theme.breakpoints.down('md')}{
    flex-direction: column;
  }
`;

export const Sections = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 2.5rem 3.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 2.5rem 1rem;
  }
`;

export const Title = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Cambon';
  text-align: center;
`;

export const Subtitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 400;
  font-family: 'Cambon';
  opacity: 0.7;
  width: 100%;
  text-align: center;
`;

export const ButtonsWrapper = styled(Box)`
  margin-top: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiTabs-scroller {
      width: 260px;
    }
  }
`;

export const FurnishedApartmentTitles = styled(Typography)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.75rem;
  font-weight: 700;
  font-family: "Cambon";
  color: ${({ theme }) => theme.palette.error[70]};
`;

export const BarWrapper = styled(Box)`
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 1rem;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.secondary.main};

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: auto;
    flex-direction: column;
  }
`;

export const TestimonialsSection = styled(Box).attrs({
  className: 'slider-reviews',
  component: 'section'
})`
  background-image: url(${testimonialsBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const TopChoicesTextWrapper = styled(Box)`
  height: 280px;
  background-color: ${({ theme }) => theme.palette.primary[10]};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: auto;
    text-align: center;
  }
`;

export const TopChoicesPictures = styled(Box)`
  height: 280px;
  border-radius: 4px;
  object-fit: cover;
`;

export const EasyRentalBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.error[70]};
  border-radius: 4px;
  width: 100%;
  height: 312px;
  display: flex;
  border-bottom: 3.5rem solid #ffffff80;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 2.5rem 0;
  margin-top: 2rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 390px;
    padding: 1rem;
    text-align: center;
  }
`;

export const EasyRentalTitle = styled(Typography)`
  font-size: 3.5rem;
  font-weight: 700;
  font-family: 'Cambon';
  color: ${({ theme }) => theme.palette.secondary.dark};
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 2.5rem;
  }
`;

export const EasyRentalSubtitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Cambon';
  color: ${({ theme }) => theme.palette.secondary.dark};
  opacity: 50%;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.75rem;
  }
`;

export const SectionSides = styled(Box)`
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 390px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
