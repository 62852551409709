import styled from 'styled-components';
import { Box, Typography } from '../UI/elements';

export const SectionHero = styled(Box)`
  justify-content: center;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
`;

export const Hero = styled(Box)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const HeroTitle = styled(Typography)`
  font-size: 9.5rem;
  font-weight: 800;
  margin: 0 1rem;
  color: ${({ theme }) => theme.palette.secondary.main};
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 6rem;
    line-height: 7rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 4rem;
    line-height: 5rem;
  }
`;

export const ApartmentsSection = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

export const ApartmentsSectionTextWrapper = styled(Box)`
  width: 100%;
  text-align: center;
`;

export const SectionTitle = styled(Typography).attrs({
  fontFamily: 'Cambon',
  fontSize: 40,
  fontWeight: 600,
  textAlign: 'center',
  mb: 0.5
})``;
