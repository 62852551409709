import styled from 'styled-components';
import { Box, TextField, Button, FormControl } from '../../elements';
import LocationBg from '../../../../images/react_icons/ellipse.svg';

export const BackgroundBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-image: url(${LocationBg});
    background-repeat: no-repeat;
    background-position: center -70vw;
    background-size: 100vw 100vh;
  }
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 0.5rem;
  & .Mui-error {
    color: ${({ theme }) => theme.palette.primary.main}
  }
`;

export const StyledButton = styled(Button).attrs({
  variant: 'outlined',
  size: 'large',
  fullWidth: true
})`
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.border} !important;

`;

export const StyledFormControl = styled(FormControl)`
  & .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
