import React, { useState, useRef } from 'react';
import {
  Box,
  Link,
  Button,
  Container,
  Checkbox,
  Typography,
  FormControlLabel,
  SvgIcon
} from '../../elements';
import { t } from '../../../../js/common/translations';
import { ThemeProvider } from '../../../contexts/theme';
import inputValidator from '../../../utils/inputValidator';
import LogoWithSlogan from '../LogoWithSlogan';
import { BackgroundBox, StyledTextField, StyledFormControl } from './RegistrationForm.styles';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

const Profile = ({ url, currentLocale, loginUrl, isWebView = false }) => {
  const formRef = useRef(null);
  const currBreakpoint = useBreakpoint();
  const isMobile = ['xs'].includes(currBreakpoint);

  const [profileValues, setProfileValues] = useState({
    fullName: '',
    birthdate: '',
    phoneNumber: '',
    agreement: false
  });

  const [profileErrorsText, setProfileErrorsText] = useState([]);

  const formValidations = [
    ['fullName', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['birthdate', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['agreement', { validation: 'required', errorMessage: t('form_errors.agreement_checkbox_not_checked') }]
  ];
  const handleChange = (inputName) => ({ target: { type, value, checked } }) => {
    const inputValue = type === 'checkbox' ? checked : value;
    const validate = {
      [inputName]: inputValue
    };

    const errors = inputValidator(validate, formValidations.filter(el => el[0] === inputName));

    setProfileValues(state => ({
      ...state,
      [inputName]: inputValue
    }));
    setProfileErrorsText(errors);
  };
  const handleSubmit = () => {
    const errors = inputValidator(profileValues, formValidations);

    if (errors.length) {
      setProfileErrorsText(errors);
    } else {
      formRef.current.submit();
    }
  };

  const getErrorMessage = (inputName) => {
    return profileErrorsText.filter(el => el.field === inputName)[0]?.error;
  };

  return (
    <ThemeProvider>
      {!isWebView && (
        <BackgroundBox>
          {isMobile && (
            <LogoWithSlogan />
          )}
          <Box sx={{
            ...(isMobile ? {
              padding: '1rem 1rem 5rem 1rem'
            } : {
              paddingY: '5rem'
            })
          }}>
            <Box overflow='hidden' display="flex"
              width="100%"
              justifyContent='center' >
              <Box
                ref={formRef}
                action={url}
                component="form"
                method="POST"
                width='100%'
                zIndex={2}
                novalidate
              >
                <Container
                  maxWidth="xs"
                  disableGutters
                  sx={{
                    p: 1,
                    bgcolor: 'secondary.main',
                    borderRadius: 2
                  }}
                >
                  <Typography
                    align="center"
                    component="h1"
                    variant="h3"
                    mb={1}
                  >
                    {t('signup_page.title')}
                  </Typography>
                  <StyledTextField
                    fullWidth
                    label={t('signup_page.fullname')}
                    name='account[fullname]'
                    id="fullname"
                    helperText={getErrorMessage('fullName')}
                    error={!!getErrorMessage('fullName')}
                    onChange={handleChange('fullName')}
                    sx={{ my: 0.5 }}
                    type="text"
                  />
                  <StyledTextField
                    name="account[language]"
                    type="text"
                    value={currentLocale}
                    hidden
                  />
                  <StyledTextField
                    fullWidth
                    label={t('signup_page.birthday')}
                    sx={{ my: 0.5 }}
                    helperText={getErrorMessage('birthdate')}
                    error={!!getErrorMessage('birthdate')}
                    id="user_birthday"
                    onChange={handleChange('birthdate')}
                    name='account[birthday]'
                    type='date'
                  />
                  <StyledTextField
                    fullWidth
                    label={t('signup_page.phone_number')}
                    type='tel'
                    id='user_phone_number'
                    name='account[phone_number]'
                    telinput='true'
                    sx={{ my: 0.5 }}
                  />
                  <StyledFormControl
                    error={!!getErrorMessage('agreement')}
                    sx={{ pb: 1 }}
                    formHelperText={getErrorMessage('agreement')}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreement"
                          icon={<SvgIcon name="unchecked_checkbox" width="1rem" height="1rem" />}
                          checkedIcon={<SvgIcon name="checked_checkbox" width="1rem" height="1rem" />}
                          sx={{ ml: 0.5 }}
                          value={profileValues.agreement}
                          onChange={handleChange('agreement')}
                        />
                      }
                      label={(
                        <Typography
                          alignSelf="center"
                          fontSize={14}
                          color="primary.main"
                        >
                          {t('dictionary.policies_agreement_text')}
                          <Link href={t('privacy_policy_path')} target="_blank" fontWeight={600} mx={0.25}>
                            {t('dictionary.policies_agreement_link')}
                          </Link>
                        </Typography>
                      )}
                    />
                  </StyledFormControl>
                  <Button
                    fullWidth
                    sx={{ mb: 0.5 }}
                    size="large"
                    onClick={handleSubmit}
                  >
                    {t('signup_page.create_login')}
                  </Button>
                  <Button
                    fullWidth
                    size="large"
                    name="back_button"
                    variant="text"
                    type="submit"
                    onKeyPress={(e) => { (e.code === 'Enter' || e.code === 'NumpadEnter') && e.preventDefault() }}
                  >
                    {t('signup_page.back')}
                  </Button>
                </Container>
              </Box>
            </Box>
            <Box textAlign="center" mt={1}>
              <Typography fontSize={14} fontWeight={500}>{t('signup_page.have_account')}</Typography>
              <Link sx={{ textDecoration: 'none' }} href={loginUrl}>
                <Typography fontSize={14} fontWeight={600} sx={{
                  lineHeight: 3, height: 40
                }}>{t('signup_page.login_here')}</Typography>
              </Link>
            </Box>
          </Box>
        </BackgroundBox>
      )
      }
    </ThemeProvider>
  );
};

export default Profile;
