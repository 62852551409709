import styled from 'styled-components';
import { Box, Container, Paper } from '../UI/elements';
import testimonialsBg from '../../images/home/testimonials-bg.svg';

export const HeroSection = styled(Box).attrs({
  component: 'section',
  className: 'hero'
})`
  height: 100vh;
  width: 100%;
  position: relative;
  `;

export const HeroImg = styled(Box).attrs({
  height: 1,
  width: 1,
  position: 'absolute'
})`
  z-index: -1;
  object-fit: cover;

  &::after{
    content: '';
    background: linear-gradient(0deg, rgba(30, 37, 54, 0.1) 0%,
                rgba(30, 37, 54, 0.2) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const HeroContent = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WhyTabasBox = styled(Paper)`
  width: 100%;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.875rem 0.875rem 0 0.875rem;
  background: ${({ theme }) => theme.palette.background.paper};
`;

export const BoxImage = styled(Box)`
  width: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  min-height: 235px;
`;

export const TestimonialsSection = styled(Box).attrs({
  className: 'slider-reviews',
  component: 'section'
})`
  background-image: url(${testimonialsBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const CorporateBanner = styled(Box).attrs({
  width: 1,
  minHeight: [400, 400, 350],
  borderRadius: { xs: 0, md: 2.5 }
})`
  position: relative;
  z-index: 1;
`;

export const CorporateBannerImg = styled(Box).attrs({
  width: 1,
  height: 1,
  borderRadius: { xs: 0, md: 3 }
})`
  object-position: right;
  object-fit: cover;
  position: absolute;

    &::after{
  border-radius: 0.75rem;
  content: '';
  background: linear-gradient(90deg, #DCD5CA 50%, rgba(220, 213, 202, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    background: linear-gradient(0deg, rgba(30, 37, 54, 0.3) 0%, rgba(30, 37, 54, 0.3) 100%);
    border-radius: 0;
  }
  `;
