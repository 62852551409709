import styled from 'styled-components';
import { Box, Button, Container, Link, Typography } from '../UI/elements';

export const TenantContainer = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const HeroSection = styled(Container).attrs({
  component: 'section',
  className: 'hero',
  px: { xs: '1rem' }
})`
`;

export const HeroTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 482px;
  height: 100%;
  padding-bottom: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: flex-end;
    height: 846px;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
    margin-top: 4.5rem;
    height: 650px;
    justify-content: flex-end;
  };
`;

const BaseHeroTitle = styled(Typography)`
  font-family: 'Cambon';
  font-size: 3.25rem;
  font-weight: 800;
  line-height: 4.25rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 2.5rem;
    line-height: 2.7rem;
    max-width: 343px;
  };
`;

export const HeroTitle = BaseHeroTitle;

export const HeroDescription = styled(Typography)`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 0.5rem;
  };
`;

export const HeroButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: row;
    width: 100%;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  };
`;

export const HeroButton = styled(Button)`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
  min-height: 3.5rem;
  min-width: 11rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  };
`;

export const HeroImagesWrapper = styled(Box)`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  transform: rotate(45deg);
  justify-items: center;
  gap: 1.25rem;
  top: -280px;
  right: -280px;
  position: absolute;

  ${({ theme }) => theme.breakpoints.down('xl')} {
    top: -4.5%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    top: -2%;
    right: -220px;
    gap: 1rem;
    z-index: -1;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: -1.5%;
    right: -100px;
    gap: 0.5rem;
    z-index: -1;
  };
`;

export const HeroDiamondImagesWrapper = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  gap: 1.25rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 1rem;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 0.5rem;
  };
`;

export const HeroDiamondImagesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.25rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 1rem;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 0.5rem;
  };
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BaseTitle = styled(Typography)`
  font-family: 'Cambon';
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.275rem;
  margin-top: 3rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    font-size: 2rem;
    line-height: 2.5rem;
  };
`;

export const Title = BaseTitle;

export const Description = styled(Typography)`
  font-family: 'Poppins';
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.185rem;
  margin-top: 1rem;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.875rem;
  };
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  };
`;

export const BannerSection = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 2rem;
  width: 100vw;

  ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
    height: 24rem;
  };
`;

export const BannerContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const BannerWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  };
`;

export const BannerLeftSide = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 30rem;
  width: 100%;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
    height: 20rem;
  };
`;

export const BannerTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: flex-end;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    align-items: center;
    width: 100%;
  };
`;

export const BannerTitle = styled(Typography)`
  font-family: 'Cambon';
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 3.275rem;
  margin-top: 3rem;
  color: ${({ theme }) => theme.palette.secondary.light};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
    font-size: 2.5rem;
    line-height: 3.3rem;
  };
`;

export const BannerDescription = styled(Typography)`
  font-family: 'Poppins';
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin-top: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.light};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 300px;
  };
`;

export const BannerButton = styled(Button)`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
  min-height: 3.5rem;
  min-width: 11.6rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 400px;
  };
`;

export const WhyChooseTabasSection = styled(Container)`
  ${({ theme }) => theme.breakpoints.down('xl')} {
    padding: 0 1rem;
  };
`;

export const ChooseTabasContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ColoredBackgroundSession = styled(Box)`
  width: 100vw;
  margin-top: 3rem;
  position: relative;
`;

export const ColoredBrackgroundContainer = styled(Box)`
  display: block;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  };
`;

export const ColoredBrackgroundMobileContainer = styled(Box)`
  display: none;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;
    background-color: #d0d6db;
    width: 100%;
    height: 350px;
    position: relative;
  };
`;

export const ColoredBackgroundWrapper = styled(Box)`
  position: absolute;
  width: 30rem;
  left: 55%;
  bottom: 40%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 410px;
    left: 47%;
    bottom: 25%;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    left: 0;
    bottom: 0;
    width: 100%;
    position: static;
    padding: 1rem;
  };
`;

export const TitleColoredBackground = styled(Typography)`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3.75rem;
  color: ${({ theme }) => theme.palette.secondary.main};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 2rem;
    line-height: 3rem;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 2rem;
    line-height: 3rem;
    margin: 2rem 0;
  };
`;

export const ColoredBackgroundBullet = styled(Box)`
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 2px;
  top: -8px;
  background-color: ${({ theme }) => theme.palette.secondary.main};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: 5rem;
    margin-left: 1rem;
  };
`;

export const ExternalAppLinkContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2rem;
`;

export const ExternaApplLink = styled(Link)`
  height: 48px;
  display: block;
  cursor: pointer;
`;
