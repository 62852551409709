import styled from 'styled-components';
import { Box, Typography } from '../UI/elements';

export const HeroContainer = styled(Box).attrs({
  component: 'section',
  display: 'flex',
  className: 'hero',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center'
})`
  height: 100%;
`;

export const HeroWrapper = styled(Box)`
  height: calc(100vh - 60px);
`;

export const Hero = styled(Box).attrs({
  height: 1,
  width: 1,
  maxHeight: { xs: 1, lg: 1000 },
  position: 'relative',
  borderRadius: 3,
  minHeight: { xs: 1, lg: 500 }
})`
  height: 100%;
  object-fit: cover;
  &::after {
    content: '';
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.95) 0%, rgba(51, 51, 51, 0) 53.13%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 1rem;
    height: 100%;
  }
`;

export const HeroInfoWrapper = styled(Box).attrs({
  position: 'absolute',
  minWidth: 272,
  maxWidth: { xs: 400, lg: 800 },
  textAlign: 'center',
  margin: 'auto',
  zIndex: 5
})``;

export const HeroTitle = styled(Typography).attrs({
  variant: 'h1',
  color: 'white',
  fontSize: '2.5rem',
  fontWeight: 'bold',
  my: 1
})``;

export const HeroPreTitle = styled(Typography).attrs({ variant: 'span' })`
  color: white;
  font-weight: bold;
  `;

export const Title = styled(Typography).attrs({
  fontSize: { xs: '1rem', md: '1.5rem' }
})`
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const AccordionAnswer = styled(Typography).attrs({
  component: 'p'
})`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const AccordionQuestion = styled(Typography).attrs({
  component: 'h4'
})`
  font-weight: bold;
  margin-bottom: 0.5;
`;
