import React from 'react';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import { Box, Container, Typography } from '../../UI/elements';
import { ContentWithTitle, ContentOrderedList } from '../../UI/modules';

const CookiesPolicy = () => {
  return (
    <ThemeProvider>
      <Box component='main' sx={{ mb: 3, backgroundColor: 'background.default' }}>
        <Container sx={{ pt: 3 }} component= 'section' className="hero">
          <Typography
            variant="h1"
            color="primary.main"
            sx={{ fontWeight: 700, fontSize: '2rem', fontFamily: 'Cambon' }}
          >
            {t('cookies_policy.title')}
          </Typography>
        </Container>
        <Container sx={{ pb: 3 }} component= 'section' className="policy-content">
          <ContentWithTitle
            subtitle={t('cookies_policy.introduction.title')}
            content={[t('cookies_policy.introduction.content')]}
          />
          <ContentWithTitle
            subtitle={t('cookies_policy.what_are_cookies.title')}
            content={[t('cookies_policy.what_are_cookies.content')]}
          />
          <ContentWithTitle
            subtitle={t('cookies_policy.do_we_use_cookies.title')}
            content={[t('cookies_policy.do_we_use_cookies.content')]}
          />
          <ContentWithTitle
            subtitle={t('cookies_policy.how_do_we_use_cookies.title')}
            content={[
              t('cookies_policy.how_do_we_use_cookies.first_content'),
              t('cookies_policy.how_do_we_use_cookies.second_content')
            ]}
          >
            <ContentOrderedList
              items={[
                t('cookies_policy.how_do_we_use_cookies.list_content.first_item'),
                t('cookies_policy.how_do_we_use_cookies.list_content.second_item'),
                t('cookies_policy.how_do_we_use_cookies.list_content.third_item'),
                t('cookies_policy.how_do_we_use_cookies.list_content.fourth_item'),
                t('cookies_policy.how_do_we_use_cookies.list_content.fifth_item')
              ]}
            />
          </ContentWithTitle>
          <ContentWithTitle
            subtitle={t('cookies_policy.how_can_you_control_cookies.title')}
            content={[
              t('cookies_policy.how_can_you_control_cookies.first_content'),
              t('cookies_policy.how_can_you_control_cookies.second_content'),
              t('cookies_policy.how_can_you_control_cookies.third_content'),
              t('cookies_policy.how_can_you_control_cookies.fourth_content')
            ]}
          />
          <ContentWithTitle
            subtitle={t('cookies_policy.updates_to_policy.title')}
            content={[t('cookies_policy.updates_to_policy.content')]}
          />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default CookiesPolicy;
