function menuStickSpacer({ document }) {
  const menu = document.querySelector('#menu-sticky-bottom');
  if (!menu) {
    return;
  }
  const menuSpacer = document.querySelector('#menu-sticky-bottom-spacer');
  const rect = menu.getBoundingClientRect();
  menuSpacer.style.minHeight = `${rect.height}px`;
}

function handleMenuSpacer(window) {
  window.addEventListener('resize', () => menuStickSpacer(window));
  menuStickSpacer(window);
}

export default handleMenuSpacer;
