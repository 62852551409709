import styled from 'styled-components';
import { Button, TextField } from '../../elements';

export const CouponButton = styled(Button).attrs({
})`
  padding: 10.5px 12px;
  height: 3rem;
  border-radius: 0.25rem;
  text-color: #FEFEFE;
  > span {
    margin: 0;
  }
`;

export const CouponTextField = styled(TextField).attrs({
  variant: 'outlined'
})`
  border-radius: 2px;
  padding-right: 0.125rem;
  flex: 1;
  height: 3rem;
`;
