import React, { useMemo } from 'react';
import { t } from '../../../js/common/translations';
import { detectOperatingSystem } from '../../utils/detectOperatingSystem';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import {
  Box,
  Dialog,
  Button,
  Drawer
} from '../elements';

const NeighbourhoodInfoSearchPageDialog = ({ onClose, open, headerContent, maxWidth, address }) => {
  const currentBreakpoint = useBreakpoint();
  const deviceOperatingSystem = useMemo(() => detectOperatingSystem(), []);

  return (
    <>
      {['xs', 'sm'].includes(currentBreakpoint) ? (
        <Drawer
          anchor="bottom"
          onClose={onClose}
          open={open}
          headerContent={headerContent}
        >
          <Box
            width="100%"
            height="175px"
            paddingBottom={1.5}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            {deviceOperatingSystem !== 'iOS' && (
              <Button
                size="large"
                variant="borderless"
                target="_blank"
                rel="nofollow"
                href={`http://maps.google.com/?q=${address}`}
              >
                {t('search_page.neighbourhood_section.get_google_directions')}
              </Button>
            )}
            {deviceOperatingSystem !== 'android' && (
              <>
                <Button
                  size="large"
                  variant="borderless"
                  target="_blank"
                  rel="nofollow"
                  href={`https://maps.apple.com/?address=${address}`}
                >
                  {t('search_page.neighbourhood_section.get_apple_directions')}
                </Button>
                <Button
                  size="large"
                  variant="borderless"
                  target="_blank"
                  rel="nofollow"
                  href={`http://maps.google.com/?q=${address}`}
                >
                  {t('search_page.neighbourhood_section.get_google_directions')}
                </Button>
              </>
            )}
          </Box>
        </Drawer>) : (
        <Dialog
          open={open}
          onClose={onClose}
          headerContent={headerContent}
        >
          <Box
            width="350px"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Button
              size="large"
              variant="borderless"
              target="_blank"
              rel="nofollow"
              href={`http://maps.google.com/?q=${address}`}
            >
              {t('search_page.neighbourhood_section.get_google_directions')}
            </Button>
            <Button
              size="large"
              variant="borderless"
              target="_blank"
              rel="nofollow"
              href={`https://maps.apple.com/?address=${address}`}
            >
              {t('search_page.neighbourhood_section.get_apple_directions')}
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default NeighbourhoodInfoSearchPageDialog;
