import React from 'react';
import Diamond from './Diamond';

const FixedUnderDiamond = () => (
  <Diamond
    width={{ xs: 250, md: 530 }}
    height={{ xs: 250, md: 530 }}
    sx={{
      position: 'absolute',
      zIndex: -1,
      left: { xs: 5, md: 180 },
      top: { xs: 90, md: 360 }
    }}
  />
);

export default FixedUnderDiamond;
