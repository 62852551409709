import styled from 'styled-components';
import { Box, Typography, Container } from '../elements';
import LocationBg from '../../../images/building_images/building_location_background.png';

export const PropertiesBox = styled(Box)`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 1rem;

  ${({ theme }) => theme.breakpoints.up('lg')}{
    overflow-x: hidden;
  }

  &::-webkit-scrollbar{
    width: 10px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb{
    background: #D3D4D4;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #B3AFB3;
  }
  &::-webkit-scrollbar-track{
    background: #F0F0F0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }
`;

export const SectionHero = styled(Box)`
  height: 492px;
  justify-content: flex-end;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;

  &::after {
    content: '';
    background: linear-gradient(0deg,rgba(51,51,51,0.5) 0%,rgba(51,51,51,0) 35%);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 100vh;
  }
`;

export const Hero = styled(Box)`
  width: 100%;
  height: 100%;
  object-position: 0 65%;
  object-fit: cover;
`;

export const BuildingName = styled(Typography)`
  font-size: 52px;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 40px;
  }
`;

export const FirstSectionTitle = styled(Typography)`
  font-size: 40px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
`;

export const FirstSection = styled(Box)`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100;
  height: 100%;
`;

export const FirstSectionDescription = styled(Typography)`
    text-align: center;
    white-space: break-spaces;
  color: ${({ theme }) => theme.palette.primary.main};
    font-size: 18px;
    margin-bottom: 3rem;
`;

export const FirstSectionPicturesWrapper = styled(Box)`
position: relative;
width: 100%;
height: 100%;

${({ theme }) => theme.breakpoints.down('md')} {
  display: flex;
  align-items: center;
  flex-direction: column;
}
`;

export const FirstSectionPictures = styled(Box)`
  min-width: 343px;
  width: 100%;
  max-width: 629px;
  height: 342px;
  border-radius: 8px 8px 80px 8px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 206px;
  }
`;

export const SecondSection = styled(Box)`
  display: flex;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`;

export const SecondSectionLeft = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: flex-start;
    height: 100%;
  }
`;

export const InfoBox = styled(Box)`
  width: 264px;
  white-space: break-spaces;
  border: 1px solid #292d3280;
  border-radius: 4px;
  padding: 32px 16px;
  position: absolute;
  top: -55%;
  right: 22px;
  z-index: -1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 24px;
  }
`;

export const SecondSectionLeftPicture = styled(Box)`
  min-width: 343px;
  width: 100%;
  max-width: 557px;
  height: 342px;
  margin-top: 3.5rem;
  border-radius: 8px 8px 80px 8px;
  z-index: -2;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 206px;
    margin-top: 1.5rem;
  }
`;

export const SecondSectionRight = styled(Box)`
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ThirdSection = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  white-space: break-spaces;
  padding: 7rem 2rem;

  &:before{
    content: '';
    background-image: url(${LocationBg});
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    z-index: 0;
    width: 100%;
    left: -2vw;
    background-position: top;
    mix-blend-mode: lighten;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0.5rem;

    &:before{
      content: '';
      background-image: none;
      width: 0;
    }
  }
`;

export const SectionsDescriptions = styled(Box)`
  font-size: 18px;
  color:${({ theme }) => theme.palette.primary.main}
`;

export const FourthSection = styled(Box)`
  white-space: break-spaces;
`;

export const BuildingApartmentsSection = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

export const BuildingApartmentsSectionTextWrapper = styled(Box)`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ThirdSectionContainer = styled(Container)`
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-left: 1rem;
      padding-right: 2rem;
      flex-direction: column;
  }
`;

export const ThirdSectionPolaroid = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  width: 262px;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('md')}{
    position: relative;
    margin-bottom: 1.5rem;
  }
`;

export const ThirdSectionPolaroidPicture = styled(Box)`
  width: 231px;
  height: 270px;
`;
