import React from 'react';
import Image from '../Image';

const StepImage = ({ src }) => (
  <Image
    src={src}
    sx={{
      width: {
        xs: '100%', sm: '286px', md: 'inherit'
      },
      height: '100%'
    }}
  />
);

export default StepImage;
