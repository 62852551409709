import React from 'react';
import { Box } from '@mui/material';

const Diamond = ({ width, height, children, sx, ...rest }) => {
  return (
    <Box
      sx={{
        borderRadius: { xs: '1.8rem', md: '3rem' },
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.100',
        ...sx
      }}
      width={width}
      height={height}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Diamond;
