const CLOSED_COVID_19_KEY = 'closed-covid-19';

function covid19Banner({ document }) {
  const closedCovid19 = localStorage.getItem(CLOSED_COVID_19_KEY) || false;
  if (!closedCovid19) {
    const covid19Banner = document.querySelector('#covid-19-banner');
    if (!covid19Banner) {
      return;
    }
    const covid19CloseButton = document.querySelector('#covid-19-banner .close');
    const covid19Space = document.querySelector('.covid-19-space');
    const header = document.querySelector('#header');
    if (!header) {
      return;
    }
    covid19CloseButton.addEventListener('click', event => {
      localStorage.setItem(CLOSED_COVID_19_KEY, true);
      covid19Banner.style.display = 'none';
      covid19Space.style.display = 'none';
      header.style.marginTop = '0';
    });
    covid19Banner.style.display = 'block';
    covid19Space.style.display = 'block';

    // resize the space
    const rect = covid19Banner.getBoundingClientRect();
    covid19Space.style.height = `${rect.height}px`;
    header.style.marginTop = `${rect.height}px`;
  }
}

export default covid19Banner;
