/* eslint-disable camelcase */
import React, { useState } from 'react';
import { t } from '../../../../js/common/translations';
import axios from 'axios';

import {
  Accordion,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  PhoneInput,
  RadioGroup,
  Select,
  SvgIcon,
  TextField,
  Typography
} from '../../elements';

import FormRadio from '../../elements/form/Radio';

import inputValidator from '../../../utils/inputValidator';
import formatCurrency from '../../../../js/common/formatCurrency';
import { isShortTerm, isLongTerm } from '../../../../js/common/apartmentRentalPeriod';

const PaymentTypeRadioButton = ({
  borders,
  colors,
  labels,
  weights,
  type,
  checked,
  compact = false,
  visible = true,
  ...rest
}) => {
  return (
    <FormRadio
      name="checkout[payment_type]"
      value={type}
      iconBgColor={{ selected: colors?.border?.selected }}
      boxSX={{
        display: visible ? 'block' : 'none',
        bgcolor: colors?.background || undefined,
        border: checked(type) ? borders?.container?.selected || 2 : borders?.container?.selected || 1,
        borderColor: checked(type) ? colors?.border?.selected || 'primary' : colors?.border?.default || 'secondary.35'
      }}
      label={
        <Box display="flex" justifyContent="space-between" width={1}>
          <Box display="flex" justifyContent={['center', 'space-between']} flexDirection="column">
            <Typography color={colors?.labels?.right.top || 'primary'} variant="h6">
              {labels.right.top}
            </Typography>
            {!compact && (
              <Typography color={colors?.labels?.right.bottom || 'primary.70'} variant="caption" fontWeight={weights?.right.bottom || 500}>
                {labels.right.bottom}
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end" textAlign={['end', 'start']}>
            <Box display="flex" flexDirection={['column', 'row']} alignItems={['flex-end', 'center']}>
              {!compact && (
                <Typography color={'primary.70'} variant="caption" pr={0.25} sx={{ textDecoration: 'line-through' }}>
                  {labels.left.middle}
                </Typography>
              )}
              <Typography color={colors?.labels?.left.top || 'primary'} variant="h6">
                {labels.left.top}
              </Typography>
            </Box>
            {!compact && (
              <Typography color={'primary'} variant="caption" fontWeight={600}>
                {labels.left.bottom}
              </Typography>
            )}
          </Box>
        </Box>
      }
      {...rest}
    />
  );
};

const CheckoutForm = ({
  policiesLinks: { cancellationLink, privacyLink },
  setCouponHelperMessage,
  setFullPaymentType,
  couponValidValue,
  currentUserAdmin,
  recaptchaSiteKey,
  paymentMethods,
  actionRoute,
  namePattern,
  paymentType,
  kiwiSources,
  rentScheduleState,
  pixActionRoute,
  willMoveWithPet,
  email,
  firstName,
  lastName,
  phone,
  idNumber,
  casatb
}) => {
  const [formValues, setFormValues] = useState({
    paymentMethodSelection: paymentMethods[0][1],
    paymentTypeSelection: paymentType,
    source: 'website',
    agreementCheckbox: false,
    identificationNumber: idNumber,
    phoneNumber: phone,
    description: '',
    firstName: firstName,
    lastName: lastName,
    email: email,
    casatb: casatb
  });

  const [guestDetailsAccordion, setGuestDetailsAccordion] = useState(true);
  const [formErrorsText, setFormErrorsText] = useState([]);
  const [loading, setLoading] = useState(false);
  const showRecaptcha = formValues.paymentMethodSelection === 'wire_transfer_international' ? 'block' : 'none';

  const formValidations = [
    ['firstName', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['firstName', { validation: () => new RegExp(namePattern).test(formValues.firstName), errorMessage: t('form_errors.text_invalid') }],
    ['lastName', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['lastName', { validation: () => new RegExp(namePattern).test(formValues.lastName), errorMessage: t('form_errors.text_invalid') }],
    ['identificationNumber', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phoneNumber', { validation: () => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(formValues.phoneNumber), errorMessage: t('form_errors.phone_invalid') }],
    ['phoneNumber', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['email', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['email', { validation: 'email', errorMessage: t('form_errors.email_invalid') }],
    ['agreementCheckbox', { validation: 'required', errorMessage: t('checkout_page.checkout_form.errors.agreement_checkbox') }]
  ];

  const getErrorMessage = (inputName) => {
    return formErrorsText.filter(el => el.field === inputName)[0]?.error;
  };

  const handleChange = (inputName) => ({ target: { value, checked } }) => {
    const validate = {
      [inputName]: inputName === 'agreementCheckbox' ? checked : value
    };

    const errors = inputValidator(validate, formValidations.filter(el => el[0] === inputName));
    setFormErrorsText(errors);

    inputName === 'paymentTypeSelection' && setFullPaymentType(value => !value);

    setFormValues(state => ({
      ...state,
      [inputName]: inputName === 'agreementCheckbox' ? checked : value
    }));
  };

  const submitForm = async(formData) => {
    setLoading(true);

    try {
      const { data: { payment_method: paymentMethod, payment_methods_response, location } } = await axios.post(actionRoute, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      switch (paymentMethod) {
      case 'boleto':
      case 'wire_transfer' || 'wire_transfer_international':
        window.location = location;
        break;
      case 'adyen_card':
        window.location = '/adyen_checkout?session=' + btoa(JSON.stringify(payment_methods_response));
        break;
      case 'pix': {
        const { data: { pix_payment_path } } = await axios.post(pixActionRoute);
        window.location = pix_payment_path;
        break;
      }
      }
    } catch (err) {
      const error = err.response.data;

      if (error?.captcha) { setFormErrorsText([{ field: 'captcha', error: t('form_errors.recaptcha') }]) }
      if (error?.coupon) {
        const couponInput = document.getElementById('checkout-coupon-input');
        setCouponHelperMessage([{ field: 'coupon', error: t('form_errors.coupon_invalid') }]);
        window.scrollTo(
          0,
          (couponInput.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top - 50)
        );
      }
    }

    setLoading(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = document.querySelector('form');
    const formData = new FormData(form);

    const errors = inputValidator(formValues, formValidations);
    setFormErrorsText(errors);

    if (errors.length) setGuestDetailsAccordion(true);
    else submitForm(formData);
  };

  return (
    <Box
      maxWidth={620}
      onSubmit={handleSubmit}
      component="form"
    >
      <Box className="checkout-type-payment">
        <Accordion
          consistentShadow
          defaultExpanded
          accordionSubtitle={formValues.paymentTypeSelection === 'pay_in_full' ? t('checkout_page.breakdown.pay_in_advance') : t('checkout_page.breakdown.pay_monthly')}
          accordionSummaryContent={<Typography fontSize="1.5rem" color="primary" variant="h5">{t('checkout_page.checkout_form.payment_type_title')}</Typography>}
          accordionDetailsContent={
            <FormControl component="fieldset" sx={{ width: 1 }}>
              <RadioGroup onChange={handleChange('paymentTypeSelection')} value={formValues.paymentTypeSelection}>
                <PaymentTypeRadioButton
                  className="checkout-type-payment-pay-in-advance"
                  type="pay_in_full"
                  borders={{ container: { default: 2, selected: 2 } }}
                  colors={{
                    border: { default: 'success.dark', selected: 'success.dark' },
                    background: 'success.light',
                    labels: { right: { top: 'success.dark', bottom: 'primary' }, left: { top: 'success.dark' } }
                  }}
                  weights={{ right: { bottom: 700 } }}
                  labels={{
                    right: {
                      top: t('checkout_page.breakdown.pay_in_advance'),
                      bottom: rentScheduleState?.breakdown?.discount_percent > 0 ? t('checkout_page.breakdown.save_discount', { discount: rentScheduleState?.breakdown?.discount_percent }) : ''
                    },
                    left: {
                      top: isShortTerm(rentScheduleState?.breakdown?.time_span) ? `R$ ${formatCurrency(rentScheduleState?.breakdown?.total_w_discount)}` : `R$ ${formatCurrency(rentScheduleState?.breakdown?.monthly_rent_with_discount_w_cleaning_fee)}/mo`,
                      middle: rentScheduleState?.breakdown?.discount_percent > 0 ? `R$ ${formatCurrency(rentScheduleState?.breakdown?.monthly_rent_w_cleaning_fee)}/mo` : '',
                      bottom: t('checkout_page.breakdown.pay_in_advance_total_with_price_label', { price: `R$ ${formatCurrency(rentScheduleState?.breakdown?.total_w_discount)}` })
                    }
                  }}
                  checked={(type) => formValues.paymentTypeSelection === type}
                  compact={isShortTerm(rentScheduleState?.breakdown?.time_span)}
                />
                <PaymentTypeRadioButton
                  visible={isLongTerm(rentScheduleState?.breakdown?.time_span)}
                  type="first_payment"
                  labels={{
                    right: {
                      top: t('checkout_page.breakdown.pay_monthly'),
                      bottom: rentScheduleState?.breakdown?.discount_percent > 0 ? t('checkout_page.breakdown.no_discount') : ''
                    },
                    left: {
                      top: `R$ ${formatCurrency(rentScheduleState?.breakdown?.monthly_rent_w_cleaning_fee)}/mo`,
                      bottom: t('checkout_page.breakdown.due_today_with_price_label', { price: `R$ ${formatCurrency(rentScheduleState?.breakdown?.payments[0].total)}` })
                    }
                  }}
                  checked={(type) => formValues.paymentTypeSelection === type}
                />
              </RadioGroup>
            </FormControl>
          }
        />
      </Box>
      <Box className="checkout-guest-details" my={1}>
        <Accordion
          consistentShadow
          accordionSubtitle={`${formValues.firstName} ${formValues.lastName}`}
          summaryProps={{
            onClick: () => setGuestDetailsAccordion(!guestDetailsAccordion)
          }}
          expanded={guestDetailsAccordion}
          accordionSummaryContent={
            <Typography
              textTransform="capitalize"
              color="primary"
              variant="h5"
              fontSize="1.5rem"
            >
              {t('checkout_page.checkout_form.title')}
            </Typography>
          }
          accordionDetailsContent={
            <>
              <Box display="flex" flexDirection={['column', 'column', 'row']}>
                <TextField
                  className="checkout-guest-details-first-name"
                  placeholder={t('checkout_page.checkout_form.fields.first_name')}
                  label={t('checkout_page.checkout_form.fields.first_name')}
                  helperText={getErrorMessage('firstName')}
                  error={!!getErrorMessage('firstName')}
                  onChange={handleChange('firstName')}
                  name="checkout[first_name]"
                  autoComplete="first-name"
                  sx={{ mr: 0.5 }}
                  id="first-name"
                  margin="dense"
                  type="text"
                  autoFocus
                  fullWidth
                  value={formValues.firstName}
                />
                <TextField
                  className="checkout-guest-details-last-name"
                  placeholder={t('checkout_page.checkout_form.fields.last_name')}
                  label={t('checkout_page.checkout_form.fields.last_name')}
                  helperText={getErrorMessage('lastName')}
                  error={!!getErrorMessage('lastName')}
                  onChange={handleChange('lastName')}
                  name="checkout[last_name]"
                  id="last-name"
                  margin="dense"
                  type="text"
                  fullWidth
                  value={formValues.lastName}
                />
              </Box>
              <Box display="flex" flexDirection={['column', 'column', 'row']}>
                <Box className="checkout-guest-details-phone" flex={1} mr={[0, 0, 0.5]}>
                  <PhoneInput
                    onChange={(value) => handleChange('phoneNumber')({ target: { value } })}
                    label={t('checkout_page.checkout_form.fields.phone')}
                    helperText={getErrorMessage('phoneNumber')}
                    error={!!getErrorMessage('phoneNumber')}
                    id="checkout-phone-input"
                    name="checkout[phone]"
                    autoFormat={false}
                    sx={{ mr: 0.5 }}
                    telinput='true'
                    margin="dense"
                    fullWidth
                    type='tel'
                    value={formValues.phoneNumber}
                  />
                </Box>
                <TextField
                  className="checkout-guest-details-id"
                  placeholder={t('checkout_page.checkout_form.fields.id_number')}
                  label={t('checkout_page.checkout_form.fields.id_number')}
                  helperText={getErrorMessage('identificationNumber')}
                  error={!!getErrorMessage('identificationNumber')}
                  onChange={handleChange('identificationNumber')}
                  name="checkout[id_number]"
                  sx={{ flex: 1 }}
                  id="id-number"
                  margin="dense"
                  type="text"
                  fullWidth
                  value={formValues.identificationNumber}
                />
              </Box>
              <TextField
                className="checkout-guest-details-email"
                placeholder={t('checkout_page.checkout_form.fields.email')}
                label={t('checkout_page.checkout_form.fields.email')}
                helperText={getErrorMessage('email')}
                error={!!getErrorMessage('email')}
                onChange={handleChange('email')}
                name="checkout[email]"
                autoComplete="email"
                margin="dense"
                type="text"
                id="email"
                fullWidth
                value={formValues.email}
              />
              <TextField
                className="checkout-guest-details-description"
                placeholder={currentUserAdmin
                  ? t('checkout_page.checkout_form.fields.description.placeholder_admin')
                  : t('checkout_page.checkout_form.fields.description.placeholder')}
                label={t('checkout_page.checkout_form.fields.description.label')}
                name="checkout[description]"
                id="checkout_description"
                margin="dense"
                type="text"
                multiline
                fullWidth
                rows={4}
              />
              <TextField
                value={couponValidValue}
                sx={{ display: 'none' }}
                name="checkout[coupon_code]"
                type="hidden"
              />
              <TextField
                value={willMoveWithPet}
                sx={{ display: 'none' }}
                name="checkout[is_moving_with_pet]"
                type="hidden"
              />
            </>
          }
        />
      </Box>
      <Box className="checkout-payment-method" mb={1}>
        <Accordion
          consistentShadow
          defaultExpanded
          accordionSubtitle={t(`checkout_page.checkout_form.fields.payment_methods.${formValues.paymentMethodSelection}`)}
          accordionSummaryContent={
            <Typography
              textTransform="capitalize"
              color="primary"
              variant="h5"
              fontSize="1.5rem"
            >
              {t('checkout_page.checkout_form.payment_method_title')}
            </Typography>
          }
          accordionDetailsContent={
            <>
              <FormControl
                component="fieldset"
                sx={{ width: 1 }}
              >
                <RadioGroup
                  id="checkout-payment-method"
                  onChange={handleChange('paymentMethodSelection')}
                  defaultValue={formValues.paymentMethodSelection}
                  value={formValues.paymentMethodSelection}
                  name="checkout[payment_method]"
                >
                  {paymentMethods.map(([_, label], index) => {
                    const isChecked = formValues.paymentMethodSelection === label;

                    return (
                      <FormRadio
                        className={`checkout-payment-method-${label}`}
                        key={index}
                        name="checkout[payment_method]"
                        value={label}
                        boxSX={{
                          border: isChecked ? 2 : 1,
                          borderColor: isChecked ? 'primary' : 'secondary.40'
                        }}
                        label={
                          <Box display="flex" justifyContent="space-between" width={1}>
                            <Typography variant="h6" color={isChecked ? 'primary' : 'primary.70'}>
                              {t(`checkout_page.checkout_form.fields.payment_methods.${label}`)}
                            </Typography>
                            <SvgIcon name={label} />
                          </Box>
                        }
                      />
                    );
                  })}
                  <FormControl>
                    <Box
                      sx={{ display: showRecaptcha }}
                      data-sitekey={recaptchaSiteKey}
                      className="g-recaptcha recaptcha-size-reset"
                      alignSelf="center"
                      mt={1}
                    />
                  </FormControl>
                </RadioGroup>
                {currentUserAdmin && (
                  <FormControl sx={{ mt: 1 }}>
                    <InputLabel id="select-source-label">Source</InputLabel>
                    <Select
                      name="checkout[source]"
                      labelId="select-source-label"
                      label="Source"
                      value={formValues.source}
                      onChange={handleChange('source')}
                    >
                      {kiwiSources.map(([labelText, value], index) => (
                        <MenuItem value={value} key={index}>
                          <Typography>{labelText}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </FormControl>
            </>
          }
        />
      </Box>
      <input name='checkout[casatb]' value={casatb} style={{ display: 'none' }} />
      <Paper sx={{ my: 1, p: 1, pb: 2 }}>
        <FormControl
          className="checkout-check-terms"
          error={!!getErrorMessage('agreementCheckbox')}
          sx={{ pb: 1 }}
          formHelperText={getErrorMessage('agreementCheckbox')}
        >
          <Box display="flex">
            <Checkbox
              onChange={handleChange('agreementCheckbox')}
              value={formValues.agreementCheckbox}
              id='agreement'
            />
            <Box fontSize="0.75rem" display="flex">
              <Typography
                alignSelf="center"
                fontSize="0.75rem"
                color="primary.70"
              >
                {t('checkout_page.checkout_form.agreement_text_1')}
                <Link href={privacyLink} target="_blank" mx={0.25}>
                  {t('checkout_page.checkout_form.agreement_link_1')}
                </Link>
                {t('checkout_page.checkout_form.agreement_text_2')}
                <Link href={cancellationLink} target="_blank" mx={0.25}>
                  {t('checkout_page.checkout_form.agreement_link_2')}
                </Link>
              </Typography>
            </Box>
          </Box>
        </FormControl>
        <Button
          className="checkout-rent-and-pay-bt"
          disabled={!!formErrorsText.length || loading}
          variant="contained"
          loading={loading}
          type="submit"
          size="large"
          fullWidth
        >
          {t('checkout_page.checkout_form.submit')}
        </Button>
      </Paper>
    </Box>
  );
};

export default CheckoutForm;
