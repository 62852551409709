import React from 'react';
import { t } from '../../../js/common/translations';

import {
  Grid,
  SvgIcon,
  Tooltip,
  Box,
  IconButton,
  Typography
} from '../../UI/elements';

const ApartmentFeatures = ({ propertyFeatures, propertyAmenities }) => {
  const features = [
    {
      presence: propertyFeatures?.withGym,
      title: t('amenities.gym'),
      iconName: 'property_gym'
    },
    {
      presence: propertyFeatures?.withSkyLounge,
      title: t('apartment_page.features.sky_loungue'),
      iconName: 'property_sky_balcony'
    },
    {
      presence: propertyFeatures?.withSauna,
      title: t('apartment_page.features.sauna'),
      iconName: 'property_sauna'
    },
    {
      presence: propertyFeatures?.withParking,
      title: t('amenities.parking'),
      tooltip: t('amenities.parking_tooltip'),
      iconName: 'property_parking_dark'
    },
    {
      presence: propertyFeatures?.withPool,
      title: t('amenities.pool'),
      iconName: 'property_pool'
    },
    {
      presence: propertyFeatures?.withElevator,
      title: t('apartment_page.features.elevator'),
      iconName: 'property_elevator'
    },
    {
      presence: propertyFeatures?.isPetFriendly,
      title: t('amenities.pet_friendly'),
      tooltip: t('amenities.pet_friendly_tooltip'),
      iconName: 'property_pets'
    },
    {
      presence: propertyFeatures?.withGatedBuilding,
      title: t('apartment_page.features.gated_building'),
      iconName: 'property_gated_building'
    },
    {
      presence: propertyFeatures?.withLaundry,
      title: t('apartment_page.features.laundry'),
      iconName: 'property_washing_machine'
    },
    {
      presence: propertyFeatures?.withDoorman,
      title: t('apartment_page.features.doorman'),
      iconName: 'property_doorman'
    },
    {
      presence: propertyAmenities?.with24hDoorman,
      title: t('amenities.doorman_24h'),
      iconName: 'doorman_24h'
    },
    {
      presence: propertyAmenities?.withRemoteDoorman,
      title: t('amenities.remote_doorman'),
      iconName: 'remote_doorman'
    },
    {
      presence: propertyFeatures?.withPlayground,
      title: t('amenities.playground'),
      iconName: 'playground'
    },
    {
      presence: propertyFeatures?.withCoworking,
      title: t('amenities.coworking'),
      iconName: 'coworking'
    },
    {
      presence: propertyFeatures?.withBreakfast,
      title: t('amenities.breakfast'),
      tooltip: t('amenities.breakfast_tooltip'),
      iconName: 'breakfast'
    }
  ];

  return (
    <Grid container>
      {features.map(({ presence, title, iconName, tooltip }, index) => (
        presence && (
          <Grid
            alignItems="center"
            component="span"
            display="flex"
            key={index}
            gap={1}
            xs={12}
            sm={6}
            md={4}
            p={1}
            item
          >
            <SvgIcon name={iconName} size="20" alt={`${t('apartment_page.alt')} ${title}`} />
            <Box>
              <Typography component="span" variant="lead" color="primary.80">{title}</Typography>
              {tooltip && (
                  <Tooltip title={<span dangerouslySetInnerHTML={{ __html: tooltip }} />} placement="top">
                    <IconButton>
                      <SvgIcon name="question_circle" alt={t('amenities.tooltip_alt')} />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
          </Grid>
        )
      ))}
    </Grid>
  );
};

export default ApartmentFeatures;
