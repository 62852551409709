import React from 'react';
import { t } from '../../../../js/common/translations';
import { Box, Container, Typography } from '../../../UI/elements';
import { ThemeProvider } from '../../../contexts/theme';
import { ContentWithTitle, ContentOrderedList } from '../../../UI/modules';

const PrivacyPolicy = () => {
  return (
    <ThemeProvider>
      <Box component='main' sx={{ mb: 3, backgroundColor: 'background.default' }}>
        <Container sx={{ pt: 3 }} component='section' className="hero">
          <Typography
            variant="h1"
            color="primary.main"
            sx={{ fontWeight: 700, fontSize: '2rem', fontFamily: 'Cambon' }}
          >
            {t('privacy_policy.title')}
          </Typography>
        </Container>
        <Container sx={{ pb: 3 }} component='section' className="policy-content">
          <ContentWithTitle
            subtitle={t('privacy_policy.introduction.title')}
            content={[
              t('privacy_policy.introduction.first_content')
            ]}
          />
          <ContentWithTitle
            subtitle={t('privacy_policy.why_do_we_need_to_have_a_privacy_policy.title')}
            content={[t('privacy_policy.why_do_we_need_to_have_a_privacy_policy.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.what_are_personal_data.title')}
            content={[t('privacy_policy.what_are_personal_data.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.what_are_sensitive_personal_data.title')}
            content={[t('privacy_policy.what_are_sensitive_personal_data.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.who_is_the_data_subject.title')}
            content={[t('privacy_policy.who_is_the_data_subject.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.who_processes_your_personal_data.title')}
            content={[t('privacy_policy.who_processes_your_personal_data.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.what_is_personal_data_processing.title')}
            content={[t('privacy_policy.what_is_personal_data_processing.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.who_is_responsible_for_personal_data_protection.title')}
            content={[t('privacy_policy.who_is_responsible_for_personal_data_protection.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.information_we_collect.title')}
            content={[t('privacy_policy.information_we_collect.content')]}
          >
            <ContentOrderedList
              items={[
                t('privacy_policy.information_we_collect.list_content.first_item'),
                t('privacy_policy.information_we_collect.list_content.second_item'),
                t('privacy_policy.information_we_collect.list_content.third_item')
              ]}
            />
          </ContentWithTitle>

          <ContentWithTitle
            subtitle={t('privacy_policy.how_we_use_your_information.title')}
            content={[t('privacy_policy.how_we_use_your_information.content')]}
          >
            <ContentOrderedList
              items={[
                t('privacy_policy.how_we_use_your_information.list_content.first_item'),
                t('privacy_policy.how_we_use_your_information.list_content.second_item'),
                t('privacy_policy.how_we_use_your_information.list_content.third_item'),
                t('privacy_policy.how_we_use_your_information.list_content.fourth_item'),
                t('privacy_policy.how_we_use_your_information.list_content.fifth_item'),
                t('privacy_policy.how_we_use_your_information.list_content.sixth_item'),
                t('privacy_policy.how_we_use_your_information.list_content.seventh_item'),
                t('privacy_policy.how_we_use_your_information.list_content.eight_item'),
                t('privacy_policy.how_we_use_your_information.list_content.ninth_item'),
                t('privacy_policy.how_we_use_your_information.list_content.tenth_item'),
                t('privacy_policy.how_we_use_your_information.list_content.eleventh_item'),
                t('privacy_policy.how_we_use_your_information.list_content.twelfth_item'),
                t('privacy_policy.how_we_use_your_information.list_content.thirteenth_item'),
                t('privacy_policy.how_we_use_your_information.list_content.fourteenth_item')
              ]}
            />
          </ContentWithTitle>

          <ContentWithTitle
            subtitle={t('privacy_policy.information_share_with_third_parties.title')}
            content={[t('privacy_policy.information_share_with_third_parties.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.personal_information.title')}
            content={[t('privacy_policy.personal_information.content')]}
          />

          <ContentWithTitle
            subtitle={t('privacy_policy.what_are_your_rights.title')}
            content={[t('privacy_policy.what_are_your_rights.content')]}
          >
            <ContentOrderedList
              items={[
                t('privacy_policy.what_are_your_rights.list_content.first_item'),
                t('privacy_policy.what_are_your_rights.list_content.second_item'),
                t('privacy_policy.what_are_your_rights.list_content.third_item'),
                t('privacy_policy.what_are_your_rights.list_content.fourth_item'),
                t('privacy_policy.what_are_your_rights.list_content.fifth_item'),
                t('privacy_policy.what_are_your_rights.list_content.sixth_item'),
                t('privacy_policy.what_are_your_rights.list_content.seventh_item'),
                t('privacy_policy.what_are_your_rights.list_content.eight_item'),
                t('privacy_policy.what_are_your_rights.list_content.ninth_item')
              ]}
            />
            <Typography variant="body1">
              {t('privacy_policy.what_are_your_rights.last_content')}
            </Typography>
          </ContentWithTitle>

          <ContentWithTitle
            subtitle={t('privacy_policy.changes_to_policy.title')}
            content={[t('privacy_policy.changes_to_policy.content')]}
          />

          <Typography variant='caption'>{t('privacy_policy.policy_version')}</Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
