import styled from 'styled-components';
import { Accordion, Box, Typography } from '../elements';

export const HorizontalDivider = styled.hr.attrs({
  sx: {
    backgroundColor: 'primary.70'
  }
})`
  margin-bottom: ${({ title }) => title ? '4rem' : 0};
  width: 100%;
  margin-top: 0;
`;

export const Title = styled(Typography)`
  font-weight: 500;
`;

export const ButtonsWrapper = styled(Box)`
  display: grid;
  height: 100%;
  text-align: center;
  width: 100%;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  row-gap: 10px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
}
`;

export const StyledAccordion = styled(Accordion).attrs({
  backgroundColor: 'secondary.main'
})`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #f2f2f2;
`;

export const AccordionTitle = styled(Typography).attrs({
  color: 'primary.main'
})`
  max-width: 289px;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 600;
`;
