import styled from 'styled-components';
import { Box, Divider, Link, Typography } from '../UI/elements';

export const DividerStyled = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  border: 1px solid ${({ theme }) => theme.palette.primary[10]};
  opacity: 0.3;
`;

export const MainContent = styled(Box).attrs({
  width: '100%',
  backgroundColor: 'secondary.main',
  border: '1px solid',
  borderColor: 'border',
  borderRadius: '4px',
  py: '2rem',
  px: { xs: '1rem', md: '2rem' }
})``;

export const Title = styled(Typography).attrs({
  component: 'h1',
  fontSize: '2.5rem',
  fontWeight: 700,
  lineHeight: '3rem'
})``;

export const Section = styled(Box).attrs({
  mt: 3.5
})``;

export const SectionTitle = styled(Typography).attrs({
  component: 'h2',
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '2rem'
})``;

export const SubTitle = styled(Typography).attrs({
  component: 'span',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '2rem',
  color: 'primary.50'
})``;

export const SubSection = styled(Box).attrs({
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
  gap: 2.5
})``;

export const SubSectionTitle = styled(Typography).attrs({
  component: 'h3',
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.75rem'
})``;

export const BlackLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const BlueLink = styled(Link).attrs({
  fontFamily: 'Poppins',
  display: 'block'
})`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.info.main};
  margin-top: 0.5rem;
  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
    text-decoration: underline;
  }
`;
