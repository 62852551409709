import styled from 'styled-components';
import { Box, Typography } from '../../../elements';
import BaseSwiper from '../../Swiper';

export const Swiper = styled(BaseSwiper)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 420px;
    overflow: visible;
    padding-top: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  };
  ${({ theme }) => theme.breakpoints.down('md')} {
    &.swiper {
      padding-top: 1.5rem;
    }
    &.swiper-slide {
      width: 100%;
    }
  };
  &.swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: block;
      visibility: initial;
      pointer-events: initial;
    }
    .swiper-slide {
      height: 435px;
    }
  };
`;

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  border: 1px solid #C4C4C480;
  height: 350px;
  width: 263px;
  border-radius: 12px;
  &:hover {
    z-index: 11;
    transform: scale(1.1);
    background-color: ${({ theme }) => theme.palette.primary.main};
    p, b {
      color: ${({ theme }) => theme.palette.secondary.main} !important;
    }
    svg * {
      stroke: ${({ theme }) => theme.palette.secondary.main};
    }
  }
  svg {
    margin-top: 2rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    pointer-events: none;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: 400px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    pointer-events: none;
    svg * {
      stroke: ${({ theme }) => theme.palette.secondary.main};
    }
    p, b {
      color: ${({ theme }) => theme.palette.secondary.main} !important;
    }
  };
`;

const BaseText = styled(Typography)`
  font-family: 'Poppins';
  text-align: center;
`;

export const CardTitle = styled(BaseText)`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.125rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 2rem;
    line-height: 2rem;
  };
`;

export const CardDescription = styled(BaseText)`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  padding: 0 1rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 1rem;
    line-height: 1.5rem;
  };
`;
