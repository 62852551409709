import React from 'react';
import { checkWebp } from '../../../../../utils/checkWebp';
import { t } from '../../../../../../js/common/translations';
import ReasonImage from '../ReasonImage';
import ReasonText from '../ReasonText';
import { ReasonTwo as StyledReasonTwo, ReasonTextWrapper } from '../styles';

import ReasonTwoWebp from '../../../../../../images/tenant/chooseTabas/reason_2.webp';
import ReasonTwoJpg from '../../../../../../images/tenant/chooseTabas/reason_2.jpg';

const ReasonTwo = () => {
  const isWebp = checkWebp();

  return (
    <StyledReasonTwo>
      <ReasonTextWrapper>
        <ReasonText
          title={t('tenant_page.choose_tabas.reasons.reason_two.title')}
          description={t('tenant_page.choose_tabas.reasons.reason_two.description_html')}
        />
      </ReasonTextWrapper>
      <ReasonImage src={isWebp ? ReasonTwoWebp : ReasonTwoJpg} />
    </StyledReasonTwo>
  );
};

export default ReasonTwo;
