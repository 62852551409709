const backButton = ({ document }) => {
  const backEls = document.querySelectorAll('.btn-back');
  const goBack = () => {
    window.history.back();
  }

  if (backEls){
    backEls.forEach(element => {
      element.addEventListener('click', goBack);
    });
  }
};

export default backButton;
