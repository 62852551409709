import React, { useState } from 'react';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { t } from '../../../../../js/common/translations';
import { Box } from '../../../elements';
import RentApartmentConfidenceSlide from './RentApartmentWithConfidenceSlide';
import Bill from '../../../../../images/react_icons/Bill';
import Credibility from '../../../../../images/react_icons/Credibility';
import Support from '../../../../../images/react_icons/Support';
import Security from '../../../../../images/react_icons/Security';
import Payment from '../../../../../images/react_icons/Payment';
import Online from '../../../../../images/react_icons/Online';
import { Swiper } from './styles';

const perView = {
  xs: 1,
  sm: 2.5,
  md: 4.5,
  lg: 4.25,
  xl: 4.5
};

const spaceBetween = {
  xs: 30,
  sm: 30,
  md: 30,
  lg: 0,
  xl: 20
};

const RentApartmentWithConfidence = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const currentBreakpoint = useBreakpoint();
  const swiperLoop = ['xs', 'sm'];
  const slidesPerView = perView[currentBreakpoint];

  const slides = [
    {
      title: t('tenant_page.rent_apartment_with_confidence.cards.card_one.title'),
      description: t('tenant_page.rent_apartment_with_confidence.cards.card_one.description_html'),
      Icon: <Bill sx={{ height: 100, width: 100 }} />
    },
    {
      title: t('tenant_page.rent_apartment_with_confidence.cards.card_two.title'),
      description: t('tenant_page.rent_apartment_with_confidence.cards.card_two.description_html'),
      Icon: <Credibility sx={{ height: 100, width: 100 }} />
    },
    {
      title: t('tenant_page.rent_apartment_with_confidence.cards.card_three.title'),
      description: t('tenant_page.rent_apartment_with_confidence.cards.card_three.description_html'),
      Icon: <Support sx={{ height: 100, width: 100 }} />
    },
    {
      title: t('tenant_page.rent_apartment_with_confidence.cards.card_four.title'),
      description: t('tenant_page.rent_apartment_with_confidence.cards.card_four.description_html'),
      Icon: <Security sx={{ height: 100, width: 100 }} />
    },
    {
      title: t('tenant_page.rent_apartment_with_confidence.cards.card_five.title'),
      description: t('tenant_page.rent_apartment_with_confidence.cards.card_five.description_html'),
      Icon: <Payment sx={{ height: 100, width: 100 }} />
    },
    {
      title: t('tenant_page.rent_apartment_with_confidence.cards.card_six.title'),
      description: t('tenant_page.rent_apartment_with_confidence.cards.card_six.description_html'),
      Icon: <Online sx={{ height: 100, width: 100 }} />
    }
  ];

  return (
    <Box
      sx={{
        marginTop: '2rem',
        width: { sm: '100%', md: 1300 },
        overflow: { sm: 'visible', md: 'hidden' }
      }}
    >
      <Swiper
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween[currentBreakpoint]}
        centeredSlides={currentBreakpoint === 'sm'}
        loop={swiperInstance ? swiperLoop.includes(currentBreakpoint) : false}
        disablePagination
        slides={slides.map(({ title, Icon, description }, index) => (
          <RentApartmentConfidenceSlide key={index} title={title} Icon={Icon} description={description} />
        ))}
      />
    </Box>
  );
};

export default RentApartmentWithConfidence;
