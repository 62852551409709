import React from 'react';
import Diamond from './Diamond';
import HeroImage from './HeroImage';

const DiamondImage = ({ width, height, src, sx, ...rest }) => {
  return (
    <Diamond width={width} height={height}>
      <HeroImage
        src={src}
        sx={{ ...sx }}
        {...rest}
      />
    </Diamond>
  );
};

export default DiamondImage;
