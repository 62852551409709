import styled from 'styled-components';
import { Box, Container, Divider, Link } from '../elements';
import LocationBg from '../../../images/react_icons/ellipse.svg';

export const StyledContainer = styled(Container).attrs({
  maxWidth: 'xs',
  disableGutters: true
})`
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 0.25rem;
  padding: 1rem 1rem 1.5rem 1rem;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const DividerWithText = styled(Divider)`
  margin: 1rem 0;
  & > span {
    padding: 0.25rem 1.5rem;
    font-family: 'Poppins';
    font-weight: 500;
    border-radius: 20rem;
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

export const StyledLink = styled(Link).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  fontWeight: '500',
  height: '2.5rem',
  underline: 'none'
})``;

export const BackgroundBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-image: url(${LocationBg});
    background-repeat: no-repeat;
    background-position: center -65vw;
    background-size: 100vw 100vh;
  }
`;
