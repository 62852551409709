import styled from 'styled-components';
import { Box, Typography } from '../UI/elements';

export const SectionHero = styled(Box)`
  justify-content: center;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
`;

export const Hero = styled(Box)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 200%);
  mix-blend-mode: multiply;
`;

export const HeroTitle = styled(Typography)`
  font-size: 3.25rem;
  font-weight: 800;
  line-height: 3.875rem;
  margin: 0 1rem;
  color: ${({ theme }) => theme.palette.secondary.main};
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const ApartmentsSection = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

export const DestinationSection = styled(Box)`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const DestinationSectionDescription = styled(Typography)`
  text-align: center;
  white-space: break-spaces;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.125rem;
  margin-bottom: 3rem;
`;

export const DestinationSectionPicturesWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const DestinationSectionPictures = styled(Box)`
  min-width: 343px;
  width: 100%;
  max-width: 629px;
  height: 365px;
  border-radius: 8px 8px 80px 8px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 221px;
  }
`;

export const ApartmentsSectionTextWrapper = styled(Box)`
  width: 100%;
  text-align: center;
`;

export const Section = styled(Box)`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 2.5rem;
`;

export const SectionTitle = styled(Typography).attrs({
  fontFamily: 'Cambon',
  fontSize: '2.5rem',
  fontWeight: 600,
  textAlign: 'center',
  mb: 0.5
})``;

export const SectionSubtitle = styled(Typography).attrs({
  fontSize: '1.125rem',
  fontWeight: 400,
  lineHeight: 1.5,
  textAlign: 'center'
})`
  opacity: 0.7;
`;

export const CelebrateSection = styled(Box)`
  display: flex;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    align-items: flex-start;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const CelebrateSectionRight = styled(Box)`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: auto 0;
  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 1rem;
    padding-top: 1.5rem;
  }
`;

export const PerfectStaySectionLeft = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: flex-start;
    height: 100%;
  }
`;

export const PerfectStaySectionLeftPicture = styled(Box)`
  min-width: 343px;
  width: 100%;
  max-width: 629px;
  height: 365px;
  border-radius: 8px 8px 80px 8px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 236px;
    margin: 1rem 0;
  }
`;
