import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Container,
  Typography,
  IconButton,
  SvgIcon,
  Divider,
  PasswordStrength,
  Link
} from '../../../UI/elements';
import { ThemeProvider } from '../../../contexts/theme';
import { t } from '../../../../js/common/translations';
import { BackgroundBox, StyledTextField, StyledButton } from './RegistrationForm.styles';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import inputValidator from '../../../utils/inputValidator';
import multiRegExpValidator from '../../../utils/multiRegExpValidator';
import LogoWithSlogan from '../LogoWithSlogan';

const Email = ({ url, isWebView = false, loginUrl }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [infoErrorText, setInfoErrorText] = useState([]);
  const [passwordValidation, setPasswordValidation] = useState(0);
  const [infoValues, setInfoValues] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const currBreakpoint = useBreakpoint();
  const isMobile = ['xs'].includes(currBreakpoint);

  const formValidations = (state) => [
    ['email', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['email', { validation: 'email', errorMessage: t('form_errors.email_invalid') }],
    ['password', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['password', { validation: 'password', errorMessage: t('form_errors.password_input') }],
    ['confirmPassword', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['confirmPassword', { validation: () => state.password === state.confirmPassword, errorMessage: t('form_errors.confirm_password_message') }]
  ];

  const handleChange = (inputName) => ({ target: { value } }) => {
    const validate = {
      [inputName]: value
    };

    setInfoValues(state => {
      const newState = { ...state, [inputName]: value };
      const errors = inputValidator(validate, formValidations(newState).filter(el => el[0] === inputName));

      setInfoErrorText(errors);

      return newState;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = inputValidator(infoValues, formValidations(infoValues));

    if (errors.length) {
      setInfoErrorText(errors);
    } else {
      event.target.submit();
    }
  };

  const getErrorMessage = (inputName) => {
    return infoErrorText.filter(el => el.field === inputName)[0]?.error;
  };

  useEffect(() => {
    const verification = multiRegExpValidator(/([a-z]+)|([A-Z]+)|(\d+)|(\W+)/g, infoValues.password);
    setPasswordValidation(verification.length);
  }, [infoValues.password]);

  return (
    <ThemeProvider>
      {!isWebView && (
        <BackgroundBox>
          {isMobile && (
            <LogoWithSlogan />
          )}
          <Box sx={{
            ...(isMobile ? {
              padding: '1rem 1rem 5rem 1rem'
            } : {
              paddingY: '5rem'
            })
          }}>
            <Box overflow='hidden' display="flex"
              width="100%"
              justifyContent='center' >
              <Box
                onSubmit={handleSubmit}
                action={url}
                component="form"
                width='100%'
                zIndex={2}
                method="POST"
                novalidate
              >
                <Container
                  maxWidth="xs"
                  disableGutters
                  sx={{
                    height: '100%',
                    p: 1,
                    bgcolor: 'secondary.main',
                    borderRadius: 2
                  }}
                >
                  <Typography
                    align="center"
                    component="h1"
                    variant="h3"
                    mb={0.5}
                  >
                    {t('signup_page.title')}
                  </Typography>
                  <StyledButton
                    startIcon={{ name: 'google_outline', size: '22' }}
                    href='/member/auth/google_oauth2'
                  >
                    {t('signup_page.signup_google')}
                  </StyledButton>
                  <Box width="100%" mb={1.5} display="flex" position="relative" justifyContent="center">
                    <Divider sx={{
                      borderColor: '#d3d4d4',
                      position: 'absolute',
                      top: '50%',
                      zIndex: 0,
                      width: '100%'
                    }} />
                    <Typography
                      bgcolor="#f8f9fe"
                      zIndex={2}
                      px={1.5}
                      py={0.25}
                      textTransform="uppercase"
                      borderRadius={30}
                      sx={{ fontWeight: 500 }}>
                      {t('login_page.or')}
                    </Typography>
                  </Box>
                  <StyledTextField
                    placeholder={t('login_page.email')}
                    type='email'
                    borderColor="primary.10"
                    helperText={getErrorMessage('email')}
                    label={t('login_page.email')}
                    fullWidth
                    error={!!getErrorMessage('email')}
                    onChange={handleChange('email')}
                    name='account[email]'
                  />
                  <StyledTextField
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('signup_page.password')}
                    borderColor="primary.10"
                    label={t('signup_page.password')}
                    name="account[password]"
                    margin="dense"
                    onChange={handleChange('password')}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => setShowPassword(!showPassword)} disableRipple>
                          <SvgIcon
                            pl={0.875}
                            name={showPassword ? 'eye_slash_password' : 'eye_password'}
                          />
                        </IconButton>
                      )
                    }}
                  />
                  {infoValues.password &&
                    <PasswordStrength length={passwordValidation} />
                  }
                  {!!getErrorMessage('password') &&
                    <Box display="flex" alignItems="baseline" mt={0.25}>
                      <SvgIcon mr={0.10} name="error_input_icon" />
                      <Typography fontSize={10} color="primary.main">{getErrorMessage('password')}</Typography>
                    </Box>
                  }
                  <StyledTextField
                    type={showConfirm ? 'text' : 'password'}
                    onChange={handleChange('confirmPassword')}
                    placeholder={t('signup_page.confirm_password')}
                    label={t('signup_page.confirm_password')}
                    borderColor="primary.10"
                    helperText={getErrorMessage('confirmPassword')}
                    margin="dense"
                    fullWidth
                    error={!!getErrorMessage('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => setShowConfirm(!showConfirm)} disableRipple>
                          <SvgIcon
                            pl={0.875}
                            name={showConfirm ? 'eye_slash_password' : 'eye_password'}
                          />
                        </IconButton>
                      )
                    }}
                  />
                  <Button
                    fullWidth
                    sx={{ mt: 0.5 }}
                    size="large"
                    type="submit"
                    disabled={!!infoErrorText.length}
                  >
                    {t('signup_page.continue')}
                  </Button>
                </Container>
              </Box>
            </Box>
            <Box textAlign="center" mt={1}>
              <Typography fontSize={14} fontWeight={500}>{t('signup_page.have_account')}</Typography>
              <Link sx={{ textDecoration: 'none' }} href={loginUrl}>
                <Typography fontSize={14} fontWeight={600} sx={{ lineHeight: 3, height: 40 }}>{t('signup_page.login_here')}</Typography>
              </Link>
            </Box>
          </Box>
        </BackgroundBox>
      )
      }
    </ThemeProvider >

  );
};

export default Email;
