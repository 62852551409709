import styled from 'styled-components';
import { Box, Typography, Link, SvgIcon, Card, CardActionArea } from '../elements';

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 1000px;
  }
`;

export const InvitesTitle = styled(Typography).attrs({
  variant: 'h1',
  my: '2rem'
})``;

export const CardsWrapper = styled(Box).attrs({
  px: '0'
})`
  width: 100%;
  margin-bottom: 3rem;
`;

export const InviteCard = styled(Card).attrs({
  variant: 'outlined'
})`
  border: 1px solid ${({ theme }) => theme.palette.border};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  overflow: hidden;
  gap: 0.25rem;
  background-color: ${({ theme }) => theme.palette.background[100]};
  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
    min-height: 127px;
  }
`;

export const InviteCardActionArea = styled(CardActionArea)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  &:hover {
    color: inherit;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`;

export const CardMainContent = styled(Box)`
  display: flex;
  gap: 0.25rem;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  width: 100%;
  padding: 0.5rem;
`;

export const InvitationInformation = styled(Box)`
  display: flex;
  gap: 0.25rem;
  padding: 1rem 0rem 1rem 1rem;
  max-width: calc(100% - (95px + 0.75rem));
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: calc(100% - (120px + 0.75rem));
  }
`;

export const CardImg = styled(Box).attrs({
  component: 'img'
})`
  width: 125px;
  border-radius: 3px;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 0 5px ${({ theme }) => theme.palette.info.light};
`;

export const CardInfoWrapper = styled(Box)`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

export const ActionButton = styled(Link)`
  position: absolute;
  padding: 0 0.25rem;
  height: 25px;
  right: 8px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  &:hover {
    color: white;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 95px;
    height: 20px;
    font-size: 10px;
    bottom: 8px;
    ${({ $isFirstChild }) => $isFirstChild && `
      bottom: calc(20px + 12px);
    `}
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    top: 8px;
    width: 120px;
    ${({ $isSecondChild }) => $isSecondChild && `
      top: calc(25px + 16px);
    `}
  }
`;

export const DialogTitle = styled(Typography)`
  font-weight: 600;
  font-weight: 1.125rem;
  line-height: 1.5rem;
`;

export const DialogSubtitle = styled(Typography)`
  font-size: 0.925rem;
  line-height: 1.3rem;
  text-align: center;
`;

export const ModalContainer = styled(Box).attrs({
  width: { xs: '100%', md: 500 },
  py: { xs: 1.5, md: 0 },
  px: { xs: 1, md: 0 }
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OwnerButtons = styled(Link)`
  display: flex;
  padding: .5rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.secondary[35]};
  text-decoration: none;
  border-radius: 6px;
`;

export const DialogButtonTitle = styled(Typography)`
  font-weight: 500;
  font-size: 0.925rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const DialogButtonSubtitle = styled(Typography)`
  font-weight: 400;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const HeaderDialogWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1rem;
`;

export const ClickableCardIcon = styled(SvgIcon).attrs({
  name: 'arrow_right_gray',
  width: '2rem'
})`
  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    width: 4rem;
    bottom: calc((100% - (25px + 5rem))/2);
    right: 0;
  }
`;
