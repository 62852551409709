import { useEffect, useState } from 'react';

export function useIsInViewport(element) {
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isInViewport = !!entries[0]?.isIntersecting;

      setIsInViewport(isInViewport);
    });

    if (element.current) {
      observer.observe(element.current);
    }

    return () => observer.disconnect();
  }, [element]);

  return isInViewport;
}
