import React from 'react';
import { CardContainer, CardTitle, CardDescription } from './styles';

const RentApartmentWithConfidenceSlide = ({ Icon, title, description }) => {
  return (
    <CardContainer>
      {Icon}
      <CardTitle>{title}</CardTitle>
      <CardDescription dangerouslySetInnerHTML={{ __html: `${description}` }} />
    </CardContainer>
  );
};

export default RentApartmentWithConfidenceSlide;
