import { Tooltip, Toast, Popover } from 'bootstrap';
import backButton from '../js/pages/member-helpers/backButton';
import '../js/pages/member-common';
import 'lazyload';
import { turnReactWarningsAsErrorsJustToWarnings } from '../js/common/misc';

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

lazyload();

setTimeout(() => {
  backButton(window);
}, 200);

// Bootrstap 5 Validation function
(function() {
  'use strict';
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation');
  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function(form) {
      form.addEventListener('submit', function(event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
}
)();

if (process.env.NODE_ENV === 'development') turnReactWarningsAsErrorsJustToWarnings();
