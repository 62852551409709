import React from 'react';
import { checkWebp } from '../../../../../utils/checkWebp';
import { t } from '../../../../../../js/common/translations';
import ReasonImage from '../ReasonImage';
import ReasonText from '../ReasonText';
import { ReasonThree as StyledReasonThree, ReasonTextWrapper } from '../styles';

import ReasonThreeWebp from '../../../../../../images/tenant/chooseTabas/reason_3.webp';
import ReasonThreeJpg from '../../../../../../images/tenant/chooseTabas/reason_3.jpg';

const ReasonThree = () => {
  const isWebp = checkWebp();

  return (
    <StyledReasonThree>
      <ReasonImage src={isWebp ? ReasonThreeWebp : ReasonThreeJpg} />
      <ReasonTextWrapper>
        <ReasonText
          title={t('tenant_page.choose_tabas.reasons.reason_three.title')}
          description={t('tenant_page.choose_tabas.reasons.reason_three.description_html')}
        />
      </ReasonTextWrapper>
    </StyledReasonThree>
  );
};

export default ReasonThree;
