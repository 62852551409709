import React from 'react';
import { Box } from '../../elements';

const Image = ({ src, sx, ...rest }) => {
  return (
    <Box
      component="picture"
      maxWidth='none'
      sx={{
        borderRadius: '8px',
        borderBottomRightRadius: '64px',
        outlineColor: (theme) => theme.palette.background.outlineImage,
        outlineStyle: 'solid',
        outlineWidth: 5,
        outlineOffset: -5,
        ...sx
      }}
      {...rest}
    >
      <Box
        component="img"
        maxWidth='none'
        src={src}
        sx={{
          borderRadius: '8px',
          borderBottomRightRadius: '64px',
          outlineColor: (theme) => theme.palette.background.outlineImage,
          outlineStyle: 'solid',
          outlineWidth: 5,
          outlineOffset: -5,
          ...sx
        }}
        {...rest}
      />
    </Box>
  );
};

export default Image;
