import styled, { css } from 'styled-components';
import { Box, Typography, Link } from '../UI/elements';

export const SuccessTypography = styled(Typography).attrs({
  gutterBottom: true,
  color: 'secondary'
})``;

export const ContentWrapper = styled(Box).attrs({
  flexDirection: 'column',
  display: 'flex',
  mx: 'auto'
})``;

export const ChipLabel = styled(Typography).attrs({
  fontSize: '0.625rem',
  color: 'primary.70',
  fontWeight: '600'
})``;

export const PolicyLink = styled(Link).attrs({
  target: '_blank',
  variant: 'lead',
  underline: 'hover',
  px: 0.5
})`
  ${({ index }) => index && css`
  border-left: 1px solid ${({ textColor }) => textColor}`}
`;

export const ItemNumberWrapper = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  minWidth: 30,
  height: 30,
  borderRadius: '50%',
  bgcolor: 'primary.5',
  mr: 0.5
})``;

export const BankInfoText = styled(Typography).attrs({
  color: 'primary.70'
})``;
