import React from 'react';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import { Box, Typography, Button, SvgIcon } from '../elements';
import {
  AccordionTitle,
  ButtonsWrapper,
  HorizontalDivider,
  StyledAccordion
} from './BottomShortcutMenu.styles';

const bedrooms = [
  {
    title: t('search_page.filter_modal.one_bedroom'),
    size: '1'
  },
  {
    title: t('search_page.filter_modal.two_bedrooms'),
    size: '2'
  },
  {
    title: t('search_page.filter_modal.three_bedrooms'),
    size: '3'
  }
];

const BottomShortcutMenu = ({ cities, baseUrl, amenities, casatb }) => {
  const currentUrl = baseUrl.split('?')[0] + '?';

  return (
    <ThemeProvider>
      <Box mt={1.5} display={casatb ? 'none' : 'block'}>
        <Box display='flex' flexDirection="column">
          <Typography
            mt={2}
            mb={0.5}
            fontWeight={700}
            sx={{
              textAlign: 'center',
              fontFamily: 'Cambon'
            }}
            fontSize={{ xs: '1.25rem', md: '1.75em' }}>
            {t('search_page.find_other')}
          </Typography>
          <HorizontalDivider title />
          <StyledAccordion
            expandIcon={
              <SvgIcon
                name="arrow_down2"
                sx={{ transform: 'rotate(0.75turn)' }}
              />
            }
            elevation={0}
            expanded
            accordionSummaryContent={
              <AccordionTitle>
                {t('search_page.neighbourhoods')}
              </AccordionTitle>
            }
            accordionDetailsContent={
              <Box
                display="flex"
                width="100%"
                flexWrap='wrap'
                justifyContent="center"
              >
                <HorizontalDivider />
                <ButtonsWrapper mt={1}>
                  {cities.map((item, i) => {
                    return (
                      <>
                        <Button
                          key={i}
                          sx={{ fontWeight: 500, lineHeight: '21px' }}
                          size='large'
                          variant={baseUrl.includes(`${item[1]}`) ? 'contained' : 'outline'}
                          href={currentUrl + `search%5Bneighbourhoods%5D%5B%5D=${item[1]}`}>
                          {item[0]}
                        </Button>
                      </>
                    );
                  })}
                </ButtonsWrapper>
              </Box>
            }
          />
          <StyledAccordion
            expandIcon={
              <SvgIcon
                name="arrow_down2"
                sx={{ transform: 'rotate(0.75turn)' }}
              />
            }
            elevation={0}
            expanded
            accordionSummaryContent={
              <AccordionTitle>
                {t('search_page.size')}
              </AccordionTitle>
            }
            accordionDetailsContent={
              <Box
                display="flex"
                width="100%"
                flexWrap='wrap'
                justifyContent="center"
              >
                <HorizontalDivider />
                <ButtonsWrapper mt={1}>
                  {bedrooms.map(({ title, size }, i) =>
                    <>
                      <Button
                        key={i}
                        sx={{ fontWeight: 500, lineHeight: '21px' }}
                        size='large'
                        variant={baseUrl.includes(`bedrooms%5D%5B%5D=${size}`) ? 'contained' : 'outline'}
                        href={currentUrl + `search%5Bbedrooms%5D%5B%5D=${size}`}>
                        {title}
                      </Button>
                    </>
                  )}
                </ButtonsWrapper>
              </Box>
            }
          />
        </Box>
        <StyledAccordion
          expandIcon={
            <SvgIcon
              name="arrow_down2"
              sx={{ transform: 'rotate(0.75turn)' }}
            />
          }
          elevation={0}
          expanded
          accordionSummaryContent={
            <AccordionTitle>
              {t('search_page.filter_modal.amenities')}
            </AccordionTitle>
          }
          accordionDetailsContent={
            <Box display="flex" width="100%" flexWrap='wrap' justifyContent="center">
              <HorizontalDivider />
              <ButtonsWrapper mt={1}>
                {amenities.map(({ title, slug }, i) =>
                  <Box key={i} >
                    <Button
                      size='large'
                      sx={{ fontWeight: 500, lineHeight: '21px' }}
                      variant={baseUrl.includes(`facilities%5D%5B%5D=${slug}`) ? 'contained' : 'outline'}
                      href={currentUrl + `search%5Bfacilities%5D%5B%5D=${slug}`}>
                      {title}
                    </Button>
                  </Box>
                )}
              </ButtonsWrapper>
            </Box>
          }
        />
      </Box>
    </ThemeProvider >
  );
};

export default BottomShortcutMenu;
