import React, { useState } from 'react';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';
import {
  Title,
  Text,
  Subtitle,
  SubtitleWrapper,
  TitleWrapper,
  DividerStyled,
  StyledButtons,
  ButtonWrapper,
  StyledAccordion,
  AccordionTitle
} from './NeighbourhoodInfoSearchPage.styles';
import {
  Box,
  Container,
  SvgIcon,
  Typography
} from '../elements';
import NeighbourhoodInfoSearchPageDialog from './NeighbourhoodInfoSearchPageDialog';

const NeighbourhoodInfoSearchPage = ({ neighbourhoodInfo }) => {
  return (
    <ThemeProvider>
      <Container disableGutters>
        {neighbourhoodInfo && (
          <Box>
            <TitleWrapper>
              <Title>
                {`${t('search_page.neighbourhood_section.discover')} ${neighbourhoodInfo.name}`}
              </Title>
            </TitleWrapper>
            <DividerStyled />
            <Box mt={1.5} mb={1.5}>
              <Text mb={1}>{neighbourhoodInfo.description_1}</Text>
              <Text>{neighbourhoodInfo.description_2}</Text>
            </Box>
            <DividerStyled />
            <Box mt={1.5} mb={1}>
              <SubtitleWrapper display="flex">
                <SvgIcon name="lightbulb" />
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '600', marginLeft: '0.75rem' }}>{t('search_page.neighbourhood_section.did_know')}</Typography>
              </SubtitleWrapper>
              <Text>{neighbourhoodInfo.curiosity}</Text>
            </Box>
            <DividerStyled />
            <SubtitleWrapper mt={2}>
              <SvgIcon name="gastronomy" />
              <Subtitle variant="h4" sx={{ my: '1rem' }}>{t('search_page.neighbourhood_section.gastronomy')}</Subtitle>
            </SubtitleWrapper>
            <Box mb={2}>
              {neighbourhoodInfo.gastronomies.map(({ name, info, phone, full_address: fullAddress }, i) => {
                const [open, setOpen] = useState(false);
                return (
                  <Box key={i}>
                    <StyledAccordion
                      expandIcon={
                        <Box width='24px'>
                          <SvgIcon sx={{ width: '100%' }} name="angle_down" />
                        </Box>
                      }
                      sx={{ marginTop: 0.5, marginBottom: 0.5 }}
                      accordionSummaryContent={
                        <Box sx={{ p: 0.75 }}>
                          <Box>
                            <AccordionTitle>{name}</AccordionTitle>
                            <Typography component="p" fontSize="0.75rem">{info}</Typography>
                          </Box>
                        </Box>
                      }
                      accordionDetailsContent={
                        <Box width="100%">
                          <DividerStyled />
                          <ButtonWrapper
                            display="flex"
                            mt={1.5}
                          >
                            <StyledButtons
                              startIcon={{ name: 'map' }}
                              onClick={() => setOpen(true)}
                            >
                              {t('search_page.neighbourhood_section.get_directions')}
                            </StyledButtons>
                            {
                              phone &&
                              (
                                <StyledButtons startIcon={{ name: 'phone' }} href={`tel:${phone}`}>
                                  {t('search_page.neighbourhood_section.make_a_call')}
                                </StyledButtons>
                              )
                            }
                          </ButtonWrapper>
                        </Box>
                      }
                    />
                    <Box>
                      <NeighbourhoodInfoSearchPageDialog
                        open={open}
                        onClose={() => setOpen(false)}
                        headerContent={(
                          <Typography variant="h4"> {t('search_page.neighbourhood_section.get_directions')}</Typography>
                        )}
                        address={fullAddress}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <SubtitleWrapper mt={2} mb={1}>
              <SvgIcon name="culture" />
              <Subtitle>{t('search_page.neighbourhood_section.culture')}</Subtitle>
            </SubtitleWrapper>
            {neighbourhoodInfo.cultures.map(({ name, info, phone, full_address: fullAddress }, i) => {
              const [open, setOpen] = useState(false);
              return (
                <Box key={i}>
                  <StyledAccordion
                    expandIcon={
                      <Box width='24px'>
                        <SvgIcon sx={{ width: '100%' }} name="angle_down" />
                      </Box>
                    }
                    sx={{ marginTop: 0.5, marginBottom: 0.5 }}
                    accordionSummaryContent={
                      <Box sx={{ py: 0.75, px: 0.5 }}>
                        <Box>
                          <AccordionTitle>{name}</AccordionTitle>
                          <Typography component="p" fontSize="0.75rem">{info}</Typography>
                        </Box>
                      </Box>
                    }
                    accordionDetailsContent={
                      <Box width="100%">
                        <DividerStyled />
                        <ButtonWrapper
                          display="flex"
                          mt={1.5}
                        >
                          <StyledButtons
                            startIcon={{ name: 'map' }}
                            onClick={() => setOpen(true)}
                          >
                            {t('search_page.neighbourhood_section.get_directions')}
                          </StyledButtons>
                          {
                            phone &&
                            (
                              <StyledButtons startIcon={{ name: 'phone' }} href={`tel:${phone}`}>
                                {t('search_page.neighbourhood_section.make_a_call')}
                              </StyledButtons>
                            )
                          }
                        </ButtonWrapper>
                      </Box>
                    }
                  />
                  <Box>
                    <NeighbourhoodInfoSearchPageDialog
                      open={open}
                      onClose={() => setOpen(false)}
                      headerContent={(
                        <Typography variant="h4"> {t('search_page.neighbourhood_section.get_directions')}</Typography>
                      )}
                      address={fullAddress}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box >
        )}
      </Container >
    </ThemeProvider>
  );
};

export default NeighbourhoodInfoSearchPage;
