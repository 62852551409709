import React, { useLayoutEffect, useState, useRef } from 'react';
import {
  Box,
  Container,
  Grid,
  SvgIcon,
  Typography,
  HtmlTranslation
} from 'components/UI/elements';
import {
  MissionWrapper,
  MissionTitle,
  MissionContent,
  CurrentJobsButton,
  BusinessTeamWrapper,
  WorkWithUsCardPretitle,
  WorkWithUsCardTitle,
  WorkWithUsCardContent,
  ImgDiv,
  PrinciplesBox,
  PrinciplesCardsBox,
  PrinciplesCardBox,
  PrinciplesCardTitle,
  BenefitsCardsBox,
  BenefitsCardDescription,
  CoreBusiness,
  TheTeam,
  HeroInfoWrapper,
  Hero,
  HeroContainer
} from './WorkWithUs.styles';
import { CTABottomBar } from 'components/UI/modules';
import { checkWebp } from '../utils/checkWebp';
import { t } from '../../js/common/translations';
import { ThemeProvider } from '../contexts/theme';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { useIsInViewport } from '../hooks/useIsInViewport';
import heroBackgroundLg from '../../images/work_with_us/work_hero_lg.png';
import heroBackgroundMd from '../../images/work_with_us/work_hero_md.png';
import heroBackgroundSm from '../../images/work_with_us/work_hero_sm.png';
import { specialCharacterRenderer } from '../utils/specialCharacterRenderer';
import heroBackgroundWebpLg from '../../images/work_with_us/work_hero_lg.webp';
import heroBackgroundWebpMd from '../../images/work_with_us/work_hero_md.webp';
import heroBackgroundWebpSm from '../../images/work_with_us/work_hero_sm.webp';
import teamPic1 from '../../images/work_with_us/team-pic-1.png';
import teamPic2 from '../../images/work_with_us/team-pic-2.png';
import teamPic3 from '../../images/work_with_us/team-pic-3.png';
import teamPic4 from '../../images/work_with_us/team-pic-4.png';
import teamPic1Webp from '../../images/work_with_us/team-pic-1.webp';
import teamPic2Webp from '../../images/work_with_us/team-pic-2.webp';
import teamPic3Webp from '../../images/work_with_us/team-pic-3.webp';
import teamPic4Webp from '../../images/work_with_us/team-pic-4.webp';

const WorkWithUs = () => {
  const heroButton = useRef(null);
  const currentBreakpoint = useBreakpoint();
  const isHeroButtonVisible = useIsInViewport(heroButton);
  const [isWebp, setIsWebp] = useState(false);
  const [displayedHeroImage, setDisplayedHeroImage] = useState(null);
  const [displayedTeamImage, setdisplayedTeamImage] = useState();
  const [displayedCoreImage, setdisplayedCoreImage] = useState();
  const coreImagesJpg = [{ image: teamPic1, alt: t('other_alt.work_with_us.team_1') }, { image: teamPic2, alt: t('other_alt.work_with_us.team_2') }];
  const coreImagesWebp = [{ image: teamPic1Webp, alt: t('other_alt.work_with_us.team_1') }, { image: teamPic2Webp, alt: t('other_alt.work_with_us.team_2') }];
  const teamImagesJpg = [{ image: teamPic3, alt: t('other_alt.work_with_us.team_3') }, { image: teamPic4, alt: t('other_alt.work_with_us.team_4') }];
  const teamImagesWebp = [{ image: teamPic3Webp, alt: t('other_alt.work_with_us.team_3') }, { image: teamPic4Webp, alt: t('other_alt.work_with_us.team_4') }];

  useLayoutEffect(() => {
    const checkWebpSupport = checkWebp();
    setIsWebp(checkWebpSupport);
    setdisplayedCoreImage(
      isWebp ? coreImagesWebp : coreImagesJpg
    );
    setdisplayedTeamImage(
      isWebp ? teamImagesJpg : teamImagesWebp
    );
    setDisplayedHeroImage(
      isWebp
        ? heroImageFiles.webp[currentBreakpoint]
        : heroImageFiles.jpg[currentBreakpoint]
    );
  }, [heroImageFiles, currentBreakpoint]);

  return (
    <ThemeProvider>
      <Box component='main'>
        <Container disableGutters maxWidth='xl' sx={{ p: 1 }}>
          <HeroContainer component="section" className="hero">
            <Hero component="picture">
              <Hero component="img" src={displayedHeroImage} alt={t('other_alt.work_with_us.hero')} />
            </Hero>
            <Box
              width="100px"
              height="100px"
              top="100px"
              position="absolute"
              ref={heroButton}
            />
            <HeroInfoWrapper>
              <Box
                display="flex"
                width={1}
                py={[2.125, 1.5]}
                px={[0, 2]}
                justifyContent= 'flex-end'
                flexDirection= 'column'
                alignItems= {['center', 'flex-start']}
              >
                <Typography
                  variant="h1"
                  color="secondary"
                  sx={{
                    mb: 1,
                    typography: ['h2', 'h1'],
                    fontWeight: [800, 700],
                    '&&': {
                      fontFamily: 'Cambon'
                    }
                  }}
                >
                  <HtmlTranslation
                    text = {t('work_with_us.hero.title_html')}
                  >
                  </HtmlTranslation>
                </Typography>
                <CurrentJobsButton
                  color="secondary"
                  sx={{
                    mt: 1,
                    display: ['none', 'flex']
                  }}
                  href={t('work_with_us.our-mission.btn_link')}
                  target='_blank'
                  rel="nofollow"
                >
                  {t('work_with_us.our-mission.btn')}
                </CurrentJobsButton>
              </Box>
            </HeroInfoWrapper>
          </HeroContainer>
        </Container>
        <Container
          maxWidth='xl'
          disableGutters
          p={0}
        >
          <MissionWrapper component="section" className='mission'>
            <MissionTitle>
              {t('work_with_us.our-mission.section')}
            </MissionTitle>
            <MissionContent>
              {t('work_with_us.our-mission.title')}
            </MissionContent>
          </MissionWrapper>
          <BusinessTeamWrapper
            pt={2}
            mb={[0, 0, 2]}
            pb={[4.5, 9]}
            component="section"
            className="team"
          >
            <CoreBusiness
              pt= {[2, 3, 5]}
            >
              <Container
                sx={{
                  flexDirection: 'column',
                  pb: 3
                }}
                align="center"
              >
                <WorkWithUsCardTitle>
                  {t('work_with_us.core.title')}
                </WorkWithUsCardTitle>
                <WorkWithUsCardContent>
                  { t('work_with_us.core.description')}
                </WorkWithUsCardContent>
              </Container>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: ['column', 'row'],
                  justifyContent: 'space-evenly',
                  alignItems: 'center'
                }}>
                {displayedCoreImage?.map((item, index) => (
                  <ImgDiv key={index}
                    sx={{
                      width: ['296px', '310px', '452px'],
                      mt: [2, 2, 0],
                      mx: [0, 0, 1]
                    }}>
                    <picture>
                      <img
                        src={item.image}
                        alt={item.alt}
                      />
                    </picture>
                  </ImgDiv>
                ))}
              </Container>
            </CoreBusiness>
            <TheTeam
              sx={{
                paddingTop: [2, 3, 5]
              }}>
              <Container
                sx={{ flexDirection: 'column', pb: 3 }}
                align="center"
              >
                <WorkWithUsCardTitle>
                  {t('work_with_us.team.title')}
                </WorkWithUsCardTitle>
                <WorkWithUsCardContent>
                  { t('work_with_us.team.description')}
                </WorkWithUsCardContent>
              </Container>
              <Container
                sx={{
                  py: 0,
                  display: 'flex',
                  flexDirection: ['column', 'row'],
                  justifyContent: 'space-evenly',
                  alignItems: 'center'
                }}>
                {displayedTeamImage && displayedTeamImage.map((item, index) => (
                  <ImgDiv
                    sx={{
                      display: 'flex',
                      width: ['296px', '310px', '452px'],
                      mt: [2, 2, 0],
                      mx: [0, 0, 1]

                    }}
                    key={index}>
                    <img
                      src={item.image}
                      alt={item.alt}
                    />
                  </ImgDiv>
                ))}
              </Container>
            </TheTeam>
          </BusinessTeamWrapper>
          <div style={{ display: 'none' }} >
            <PrinciplesBox sx={{ pt: 2.5 }}>
              <WorkWithUsText props={ourPrinciplesElement} containerSx={{ flexDirection: 'column', pb: 3 }}/>
              <PrinciplesCardsBox sx={{ pt: 1, justifyContent: 'center' }}>
                {principlesElements.map((element, index) => (
                  <PrinciplesCardBox key={index}>
                    <SvgIcon name={element.icon} sx={{ mb: 1 }}/>
                    <PrinciplesCardTitle>
                      {element.title}
                    </PrinciplesCardTitle>
                  </PrinciplesCardBox>
                ))}
              </PrinciplesCardsBox>
              <CurrentJobsButton
                sx={{ my: 2.5 }}
                href='https://www.linkedin.com/company/tabas/jobs/'
                target='_blank'
                rel="nofollow"
              >
                {t('work_with_us.our-mission.btn')}
              </CurrentJobsButton>
            </PrinciplesBox>
            <Box
              sx={{
                background: '#F8F9FE',
                pb: 5,
                pt: 2.5
              }}
              component='section'
            >
              <WorkWithUsText props={benefitsElement} containerSx={{ flexDirection: 'column', pb: 0 }} />
              <Container sx={{ mt: 1.875 }}>
                <Grid container spacing={1} >
                  {benefitsCardsElements.map((element, index) => (
                    <Grid
                      item
                      xs={12} sm={6} md={6} lg={4}
                      key={index}
                    >
                      <BenefitsCardsBox sx={{ p: 1 }}>
                        <SvgIcon name='checkbox' />
                        <Typography
                          variant="h6"
                          thickness="600"
                          pb={0.25}
                        >
                          {element.title}
                        </Typography>
                        <BenefitsCardDescription>
                          {element.description}
                        </BenefitsCardDescription>
                      </BenefitsCardsBox>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </div>
        </Container>
      </Box>
      <CTABottomBar
        actionButtonProps={{
          href: t('work_with_us.our-mission.btn_link'),
          children: t('work_with_us.our-mission.btn'),
          target: '_blank',
          sx: {
            minWidth: ['auto', 250]
          }
        }}
        rel="nofollow"
        text={t('work_with_us.cta.description')}
        visible={!isHeroButtonVisible}
      />
    </ThemeProvider>
  );
};

const WorkWithUsText = ({ props, containerSx = {} }) => (
  <>
    {props.map((element, index) => {
      const title = specialCharacterRenderer(element.title);
      return (
        <Container
          sx={containerSx}
          align="center"
          key={index}
        >
          <WorkWithUsCardPretitle>
            {element.pretitle}
          </WorkWithUsCardPretitle>
          <WorkWithUsCardTitle>
            {title}
          </WorkWithUsCardTitle>
          <WorkWithUsCardContent>
            {element.description}
          </WorkWithUsCardContent>
        </Container>
      );
    })}
  </>
);

const heroImageFiles = {
  webp: {
    xs: heroBackgroundWebpSm,
    sm: heroBackgroundWebpMd,
    md: heroBackgroundWebpMd,
    lg: heroBackgroundWebpLg,
    xl: heroBackgroundWebpLg
  },
  jpg: {
    xs: heroBackgroundSm,
    sm: heroBackgroundMd,
    md: heroBackgroundMd,
    lg: heroBackgroundLg,
    xl: heroBackgroundLg
  }
};

const benefitsCardsElements = [
  {
    title: t('work_with_us.benefits.feature.flexible_benefits'),
    description: t('work_with_us.benefits.description.flexible_benefits')
  },
  {
    title: t('work_with_us.benefits.feature.health_plan'),
    description: t('work_with_us.benefits.description.health_plan')
  },
  {
    title: t('work_with_us.benefits.feature.flexible_vacations'),
    description: t('work_with_us.benefits.description.flexible_vacations')
  },
  {
    title: t('work_with_us.benefits.feature.totalpass'),
    description: t('work_with_us.benefits.description.totalpass')
  },
  {
    title: t('work_with_us.benefits.feature.moodar'),
    description: t('work_with_us.benefits.description.moodar')
  },
  {
    title: t('work_with_us.benefits.feature.homeoffice_kit'),
    description: t('work_with_us.benefits.description.homeoffice_kit')
  }
];

const principlesElements = [
  {
    icon: 'stopwatch',
    title: t('work_with_us.principles.time')
  },
  {
    icon: 'medal',
    title: t('work_with_us.principles.standards')
  },
  {
    icon: 'heart_medical',
    title: t('work_with_us.principles.ownership')
  },
  {
    icon: 'flask',
    title: t('work_with_us.principles.experiment')
  }
];

const ourPrinciplesElement = [
  {
    pretitle: t('work_with_us.principles.section'),
    title: t('work_with_us.principles.title'),
    description: t('work_with_us.principles.description')
  }
];

const benefitsElement = [
  {
    pretitle: t('work_with_us.benefits.tabers'),
    title: t('work_with_us.benefits.benefits_and_perks'),
    description: t('work_with_us.benefits.benefits_and_perks_description')
  }
];

export default WorkWithUs;
