import styled, { css } from 'styled-components';
import { Box, Typography, Button, Container, Grid } from '../UI/elements';

export const HeroWrapper = styled(Box)`
    display: flex;
    height: calc(95vh - 3.5rem);
  
  
  
    ${({ backgroundImg }) => backgroundImg && css`
      background:
        linear-gradient(0deg, rgba(41, 45, 50, 0.7) 0%, rgba(51, 51, 51, 0) 70.56%),
        center / cover no-repeat url(${backgroundImg});
    `}
  `;

export const MissionWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 4rem 1.5rem;
  
    ${({ theme }) => theme.breakpoints.up('sm')} {
      padding: 2.5rem 1.5rem;
    }
  
    ${({ theme }) => theme.breakpoints.up('lg')} {
      padding: 4.5rem 1.5rem 3.375rem 1.5rem;
    }
  `;

export const MissionTitle = styled(Typography).attrs({
  variant: 'subtitle2',
  component: 'span',
  thickness: '600'
})`
    color: ${({ theme }) => theme.palette.primary['70']};
    text-transform: uppercase;
  `;

export const MissionContent = styled(Typography).attrs({
  variant: 'h5',
  component: 'h2',
  thickness: '500'
})`
    font-size: 1.563rem;
    font-style: normal;
    line-height: 2.375rem;
    text-align: center;
  
    ${({ theme }) => theme.breakpoints.up('lg')} {
      margin: 0 8rem;
    }
  `;

export const CurrentJobsButton = styled(Button).attrs({
  size: 'large'
})`
    max-width: fit-content;
  `;

export const BusinessTeamWrapper = styled(Box)`
    background-color: #F8F9FE;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    ${({ theme }) => theme.breakpoints.up('lg')} {
      padding: 0 0 4.625rem 0;
    }
  `;

export const WorkWithUsCardPretitle = styled(Typography).attrs({
  variant: 'subtitle2',
  thickness: '600'
})`
    color: ${({ theme }) => theme.palette.primary['60']};
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.375rem;
  `;

export const WorkWithUsCardTitle = styled(Typography).attrs({
  variant: 'h1',
  component: 'h2',
  fontFamily: 'Cambon'
})`
    color: ${({ theme }) => theme.palette.primary.main};
    margin-bottom: 1rem;
  `;

export const WorkWithUsCardContent = styled(Typography).attrs({
  variant: 'body1',
  component: 'span'
})`
    max-width: 800px;
    color: ${({ theme }) => theme.palette.primary['70']};
  `;

export const ImgDiv = styled(Box)`
    height: 347px;
    display: flex;
    flex-direction: row;
  
    :last-child {
      margin-bottom: 0;
    }
  
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  `;

export const PrinciplesBox = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin: 0 6.125rem;
      justify-content: space-around;
  
      > div:first-child {
       padding: 0 5rem;
    }
    }
  `;

export const PrinciplesCardsBox = styled(Box)`
    display: flex;
    flex-direction: column;
  
    ${({ theme }) => theme.breakpoints.up('sm')} {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 500px;
    }
  
    ${({ theme }) => theme.breakpoints.up('lg')} {
      max-width: none;
    }
  `;

export const PrinciplesCardBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 188px;
    width: 295px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    margin-bottom: 2rem;
    padding-top: 2.25rem;
  
    ${({ theme }) => theme.breakpoints.up('sm')} {
      height: 163px;
      width: 202px;
      margin: 1rem;
    }
  
    ${({ theme }) => theme.breakpoints.up('lg')} {
      margin: 1rem;
    }
  `;

export const PrinciplesCardTitle = styled(Typography).attrs({
  variant: 'h5',
  thickness: '600'
})`
    font-size: 1.375rem;
    line-height: 2.063rem;
    text-align: center;
    white-space: pre-line;
  
    ${({ theme }) => theme.breakpoints.up('sm')} {
      font-size: 0.875rem;
      line-height: 1.313rem;
    }
  `;

export const BenefitsCardsBox = styled(Grid)`
    background-color: ${({ theme }) => theme.palette.secondary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
  `;

export const BenefitsCardDescription = styled(Typography).attrs({
  variant: 'body2',
  thickness: '500'
})`
    color: ${({ theme }) => theme.palette.primary.light};
    font-style: normal;
    text-align: center;
    padding: 0 1.625rem;
  
    ${({ theme }) => theme.breakpoints.up('sm')} {
      max-width: 347px;
      max-height: 168px;
    }
  `;
export const CoreBusiness = styled(Box)`
    display: flex;
    flex-direction: column;`;

export const TheTeam = styled(Box)`
    display: flex;
    flex-direction: column;
  `;

export const HeroInfoWrapper = styled(Box).attrs({
  position: 'absolute',
  minWidth: 272,
  m: { xs: 1, md: 3 },
  zIndex: 5
})``;

export const Hero = styled(Box).attrs({
  height: 1,
  width: 1,
  maxHeight: { xs: 1, lg: 1000 },
  position: 'relative',
  borderRadius: 3,
  minHeight: { xs: 1, lg: 800 }
})`
    height: calc(97vh - 60px);
    object-fit: cover;
  `;

export const HeroContainer = styled(Box).attrs({
  component: 'section',
  display: 'flex',
  className: 'hero',
  position: 'relative',
  alignItems: 'flex-end',
  justifyContent: { xs: 'center', sm: 'flex-start' }
})`
  
  &::after{
      content: '';
      background: linear-gradient(0deg, rgba(51, 51, 51, 0.95)
                                0%, rgba(51, 51, 51, 0) 53.13%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 1rem;
      height: 100%;
    }
  `;
