import React, { useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import { checkWebp } from '../utils/checkWebp';
import { useBreakpoint } from '../hooks/useBreakpoint';

import {
  Accordion,
  Box,
  Button,
  Container,
  Divider,
  Dialog,
  Drawer,
  Grid,
  Paper,
  SvgIcon,
  Typography,
  HtmlTranslation
} from '../UI/elements';
import {
  HeroContainer,
  PreText,
  StepsTitle,
  StepsDescription,
  LandlordWrapper,
  PlusMinusIconExpansion,
  HeroImg,
  HeroInfoWrapper
} from './Landlord.styles';
import { CTABottomBar, Swiper, Timeline, LandlordContactForm } from '../UI/modules';

import heroBackgroundWepbLg from '../../images/landlords_page/hero-lg.webp';
import heroBackgroundPngLg from '../../images/landlords_page/hero-lg.png';
import heroBackgroundWepbSm from '../../images/landlords_page/hero-sm.webp';
import heroBackgroundPngSm from '../../images/landlords_page/hero-sm.png';

import benefits1Png from '../../images/landlords_page/benefits_1.png';
import benefits1Webp from '../../images/landlords_page/benefits_1.webp';
import benefits2Png from '../../images/landlords_page/benefits_2.png';
import benefits2Webp from '../../images/landlords_page/benefits_2.webp';
import benefits3Png from '../../images/landlords_page/benefits_3.png';
import benefits3Webp from '../../images/landlords_page/benefits_3.webp';
import benefits4Png from '../../images/landlords_page/benefits_4.png';
import benefits4Webp from '../../images/landlords_page/benefits_4.webp';

import imageSlider2Png from '../../images/landlords_page/overlay_slider_2.png';
import { useIsInViewport } from '../hooks/useIsInViewport';

const Landlord = ({ actionRoute, recaptchaSiteKey }) => {
  const [isWebp, setIsWebp] = useState(false);
  const currentBreakpoint = useBreakpoint();
  const [displayedHeroImage, setDisplayedHeroImage] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  const heroButton = useRef(null);
  const isHeroButtonVisible = useIsInViewport(heroButton);

  const isMobile = ['xs', 'sm'].includes(currentBreakpoint);

  useLayoutEffect(() => {
    const checkWebpSupport = checkWebp();
    setIsWebp(checkWebpSupport);

    setDisplayedHeroImage(
      isWebp
        ? heroImageFiles.webp[currentBreakpoint]
        : heroImageFiles.jpg[currentBreakpoint]
    );
  }, [heroImageFiles, currentBreakpoint]);

  const benefitsCards = [
    {
      image: isWebp ? benefits1Webp : benefits1Png,
      iconName: 'user_check_landlord',
      text: t('landlords_page.benefits.features.high-quality-tenants'),
      description: t('landlords_page.how-it-works.steps.residents_desc'),
      alt: t('owner_alt.benefits_1')
    },
    {
      image: isWebp ? benefits2Webp : benefits2Png,
      iconName: 'calendar_landlord',
      text: t('landlords_page.benefits.features.long-term'),
      description: t('landlords_page.how-it-works.steps.agreement_desc'),
      alt: t('owner_alt.benefits_2')
    },
    {
      image: isWebp ? benefits3Webp : benefits3Png,
      iconName: 'headphones_landlord',
      text: t('landlords_page.benefits.features.owner-support'),
      description: t('landlords_page.how-it-works.steps.support_desc'),
      alt: t('owner_alt.benefits_3')
    },
    {
      image: isWebp ? benefits4Webp : benefits4Png,
      iconName: 'paper_write_light_landlord',
      text: t('landlords_page.benefits.features.property-management'),
      description: t('landlords_page.how-it-works.steps.maintenance_desc'),
      alt: t('owner_alt.benefits_4')
    }
  ];

  const LandlordBenefitsCards = ({ image, iconName, text, index, alt }) => {
    const isMdSize = ['md'].includes(currentBreakpoint);

    return (
      <Box
        position='relative'
        m={1.70}
        ml={isMdSize ? 1 : 2}
        mt={{ 1: 4, 2: 0, 3: 2 }[index]}
        borderRadius={3}
        minWidth={271}
        height={248}
        sx={{
          background: `center / cover no-repeat url(${image})`,
          border: 'none'
        }}>
        <Box component="picture" width={1} height={1}>
          <Box component="source" srcSet={image} />
          <Box
            component="img"
            width={1} height={1}
            alt={alt}
          />
        </Box>
        <Paper
          elevation={5}
          bgcolor="secondary.main"
          sx={{
            position: 'absolute',
            left: 25,
            bottom: -25,
            height: 80,
            width: 268,
            display: 'flex',
            borderRadius: 4
          }}
        >
          <SvgIcon name={iconName} alt={` ${t('dictionary.alt_img')}${text}`} size='36' mx={1} />
          <Typography alignSelf="center" variant="h6" component="h3">{text}</Typography>
        </Paper>
      </Box>
    );
  };

  return (
    <ThemeProvider>
      <Box mx={1}>
        <Container disableGutters maxWidth="xl" sx={{ py: 1 }}>
          <HeroContainer>
            <HeroImg component="picture">
              <HeroImg
                component="img"
                src={displayedHeroImage}
                alt={t('owner_alt.hero')}
              />
            </HeroImg>
            <Box
              width="100px"
              height="100px"
              top="100px"
              position="absolute"
              ref={heroButton}
            />
            <HeroInfoWrapper>
              <Container disableGutters maxWidth="xl" sx={{ borderRadius: 2, py: 2 }}>
                <Box alignSelf='center' component="span">
                  <Box
                    sx={{ backdropFilter: isMobile && 'blur(4px)' }}
                    bgcolor={isMobile && 'rgba(255, 255, 255, 0.5)'}
                    mx={[0.5, 0.5, 4]}
                    borderRadius={3}
                    maxWidth={{ sm: 520, md: 520 }}
                    opacity={0.5}
                    my={0.5}
                    p={0.5}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        mb: 1,
                        typography: ['h4', 'h1'],
                        fontWeight: [800, 700],
                        '&&': {
                          fontFamily: 'Cambon'
                        }
                      }}
                    >
                      <HtmlTranslation text={t('landlords_page.hero.title_html')} />
                    </Typography>
                    <Typography variant="lead">
                      {t('landlords_page.hero.description')}
                    </Typography>
                  </Box>
                  <Button
                    className="partner-with-us-bt-fixed"
                    sx={{
                      display: ['none', 'none', 'inline-flex'],
                      mt: 1.5,
                      ml: 4
                    }}
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => setOpenForm(true)}
                  >
                    {t('landlords_page.cta.form_button')}
                  </Button>
                </Box>
              </Container>
            </HeroInfoWrapper>
          </HeroContainer>
        </Container>
        <Container sx={{ mt: 3 }} disableGutters maxWidth="xl">
          <Box component="section" className='benefits'>
            <Grid container spacing={2}>
              <Grid item md={4} lg={5} alignSelf='center'>
                <PreText>
                  {t('landlords_page.benefits.section')}
                </PreText>
                <Typography
                  component="h2"
                  variant="h1"
                  my={0.5}
                  sx={{ fontFamily: 'Cambon' }}
                >
                  <HtmlTranslation text={t('landlords_page.benefits.title_html')} />
                </Typography>
                <Typography
                  component="p"
                  variant="h5"
                  color="primary.80"
                  thickness={500}
                  my={1}
                  sx={{ typography: { xs: 'h5', md: 'h6' } }}
                >
                  {t('landlords_page.benefits.description_1')}
                </Typography>
                <Typography
                  component="p"
                  color="primary.80"
                  thickness={500}
                  sx={{ typography: { xs: 'h5', md: 'h6' } }}
                >
                  {t('landlords_page.benefits.description_2')}
                </Typography>
              </Grid>
              {isMobile ? (
                <Grid item xs={12} mt={1}>
                  <Swiper
                    spaceBetween={16}
                    slides={benefitsCards.map(({ image, text, description, alt }, index) => (
                      <Box key={index} pb={3}>
                        <Box
                          sx={{
                            background: `center / cover no-repeat url(${image})`,
                            borderRadius: 3,
                            height: 315,
                            width: 1
                          }}
                          alt={alt}
                        />
                        <Box>
                          <Typography
                            component="h3"
                            variant="h2"
                            fontWeight="800"
                            sx={{ my: 1, fontFamily: 'Cambon' }}
                          >
                            {text}
                          </Typography>
                          <Typography component="p" variant='h6' color='primary.70'>{description}</Typography>
                        </Box>
                      </Box>
                    ))}
                  />
                </Grid>
              ) : (
                <Grid
                  justifyContent="right"
                  flexWrap="wrap"
                  display="flex"
                  md={8}
                  lg={7}
                  item
                >
                  {benefitsCards.map((element, index) => (
                    <LandlordBenefitsCards key={index} index={index} {...element} />
                  ))}
                </Grid>
              )}
            </Grid>
          </Box>
          <LandlordWrapper component="section" className="how-to">
            <PreText>{t('landlords_page.how-it-works.section')}</PreText>
            <Typography
              component="h2"
              variant='h1'
              mt={0.5}
              mb={[2.5, 2.5, 4]}
              sx={{ fontFamily: 'Cambon' }}
            >
              <HtmlTranslation text={t('landlords_page.how-it-works.title_html')} />
            </Typography>
            {isMobile ? (
              stepsItems.map(({ content, iconName }, index) => (
                <Box key={index} mx={1}>
                  <SvgIcon name={iconName} alt={`${t('dictionary.alt_img')}${t('content.detailContent')}`} mt={index === 0 ? 0 : 2} mb={1} />
                  {content}
                </Box>
              ))
            ) : (
              <Timeline
                timelineItem={stepsItems}
              />
            )}
          </LandlordWrapper>
          <Box component="picture"
            sx={{
              borderRadius: 3,
              width: [1, '100%', '100%'],
              height: ['100%', '100%', '100%']
            }}>
            <Box
              component="img"
              src={imageSlider2Png} sx={{
                borderRadius: 3,
                objectFit: 'cover',
                width: [1, '100%', '100%'],
                height: ['100%', '100%', '100%']
              }}
              alt={t('about_us_alt.card_three')}
            />
          </Box>
          <LandlordWrapper sx={{ mt: [5, 5, 7] }} component="section" className="faq">
            <PreText>{t('landlords_page.qa.section')}</PreText>
            <Typography
              variant="h2"
              fontWeight="800"
              sx={{ fontFamily: 'Cambon' }}
            >
              {t('landlords_page.qa.title')}
            </Typography>
            {qaItems.map(({ summaryContent, detailContent }, index) => (
              <Container maxWidth="sm" key={index}>
                <Accordion
                  expandIcon={<PlusMinusIconExpansion />}
                  elevation={0}
                  sx={{ bgcolor: 'secondary.light', my: 2 }}
                  accordionSummaryContent={
                    <Typography sx={{ maxWidth: 289 }} color="primary.main" textAlign="left" variant="h3">
                      {summaryContent}
                    </Typography>
                  }
                  accordionDetailsContent={
                    <Typography color="primary.70" component="p" textAlign="left" fontWeight={400} variant="h6">
                      {detailContent}
                    </Typography>
                  }
                />
                <Divider sx={{ mx: 1 }} />
              </Container>
            ))}
          </LandlordWrapper>
          {isMobile ? (
            <Drawer
              anchor="bottom"
              open={openForm}
              onClose={() => setOpenForm(false)}
              headerContent={
                <Typography variant="h4">
                  {t('landlords_page.hero.contact_form.title')}
                </Typography>
              }
            >
              <LandlordContactForm
                recaptchaSiteKey={recaptchaSiteKey}
                onClose={() => setOpenForm(false)}
                url={actionRoute}
              />
            </Drawer>
          ) : (
            <Dialog
              minwidth600="true"
              headerContent={
                <Typography variant="h2">{t('landlords_page.hero.contact_form.title')}</Typography>
              }
              open={openForm}
              onClose={() => setOpenForm(false)}
            >
              <LandlordContactForm
                recaptchaSiteKey={recaptchaSiteKey}
                onClose={() => setOpenForm(false)}
                url={actionRoute}
              />
            </Dialog>
          )}
        </Container>
      </Box >
      <CTABottomBar
        actionButtonProps={{
          className: 'partner-with-us-bt-scroll',
          onClick: () => setOpenForm(true),
          children: t('landlords_page.cta.form_button')
        }}
        text={t('landlords_page.cta.form_description')}
        visible={!isHeroButtonVisible}
      />
    </ThemeProvider >
  );
};

const qaItems = [
  {
    summaryContent: t('landlords_page.qa.questions.question_guaranteed'),
    detailContent: t('landlords_page.qa.questions.answer_guaranteed')
  },
  {
    summaryContent: t('landlords_page.qa.questions.question_maintenance_coverage'),
    detailContent: t('landlords_page.qa.questions.answer_maintenance_coverage')
  }
];

const stepsItems = [
  {
    content:
      <>
        <StepsTitle>{t('landlords_page.how-it-works.steps.analysis_title')}</StepsTitle>
        <StepsDescription>{t('landlords_page.how-it-works.steps.analysis_description')}</StepsDescription>
      </>,
    iconName: 'paper_search_landlord',
    alt: t('landlords_page.how-it-works.steps.analysis_description')
  },
  {
    content:
      <>
        <StepsTitle>{t('landlords_page.how-it-works.steps.visit_title')}</StepsTitle>
        <StepsDescription>{t('landlords_page.how-it-works.steps.visit_description')}</StepsDescription>
      </>,
    iconName: 'user_landlord',
    alt: t('landlords_page.how-it-works.steps.visit_title')
  },
  {
    content:
      <>
        <StepsTitle>{t('landlords_page.how-it-works.steps.lease_title')}</StepsTitle>
        <StepsDescription>{t('landlords_page.how-it-works.steps.lease_description')}</StepsDescription>
      </>,
    iconName: 'paper_write_light_landlord',
    alt: t('landlords_page.how-it-works.steps.lease_title')
  },
  {
    content:
      <>
        <StepsTitle>{t('landlords_page.how-it-works.steps.guarantee_title')}</StepsTitle>
        <StepsDescription>{t('landlords_page.how-it-works.steps.guarantee_description')}</StepsDescription>
      </>,
    iconName: 'money_landlord',
    alt: t('landlords_page.how-it-works.steps.guarantee_title')
  }
];

const heroImageFiles = {
  webp: {
    xs: heroBackgroundWepbSm,
    sm: heroBackgroundWepbSm,
    md: heroBackgroundWepbLg,
    lg: heroBackgroundWepbLg,
    xl: heroBackgroundWepbLg
  },
  jpg: {
    xs: heroBackgroundPngSm,
    sm: heroBackgroundPngSm,
    md: heroBackgroundPngLg,
    lg: heroBackgroundPngLg,
    xl: heroBackgroundPngLg
  }
};

export default Landlord;
