import React from 'react';
import SwiperCore, { Navigation, Pagination, Zoom, Keyboard } from 'swiper';
import { ThemeProvider } from '../../contexts/theme';
import { SliderContainer } from './ApartmentGallerySlider.styles';
import { SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/zoom/zoom.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/lazy/lazy.min.css';

SwiperCore.use([Pagination, Navigation, Zoom, Keyboard]);

const ApartmentGallerySlider = ({ children, slides, ...rest }) => (
  <ThemeProvider>
    <SliderContainer
      keyboard={{ enabled: true }}
      centeredSlides={true}
      zoom={true}
      navigation={true}
      pagination={{
        clickable: true
      }}
      {...rest}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          {slide}
        </SwiperSlide>
      ))}
    </SliderContainer>
  </ThemeProvider>
);

export default ApartmentGallerySlider;
