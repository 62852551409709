import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';

import {
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Paper,
  SvgIcon,
  Typography
} from '../UI/elements';

import { NumberOfNightsTag } from './Checkout.styles';
import { useBreakpoint } from '../hooks/useBreakpoint';

import { daysDiff, format, DATEPICKER_FORMAT } from '../../js/common/dates';

import BreakdownDetails from '../UI/modules/checkout-info/BreakdownDetails';
import RentScheduleDetails from '../UI/modules/checkout-info/RentScheduleDetails';
import CheckoutForm from '../UI/modules/checkout-info/CheckoutForm';
import CouponForm from '../UI/modules/checkout-info/CouponForm';
import CheckoutErrorModal from '../UI/modules/CheckoutErrorModal';

moment.locale(document.documentElement.lang);

const Checkout = (props) => {
  const {
    propertyName,
    propertyImg,
    propertyCity,
    propertyNeighbourhood,
    checkInDate,
    checkOutDate,
    detailsBreakdown,
    previewBreakdown,
    paymentType,
    userNamePattern,
    fullPrice,
    currentUserAdmin,
    paymentMethods,
    policiesLinks,
    actionRoute,
    recaptchaSiteKey,
    kiwiSources,
    currentLocale,
    reservationInvalidDates,
    apartmentShowPath,
    pixActionRoute,
    isPetFriendly,
    email,
    firstName,
    lastName,
    phone,
    idNumber,
    casatb
  } = props;
  const currBreakpoint = useBreakpoint();
  const showBreakdownDetailsMobileInfo = ['xs'].includes(currBreakpoint);

  const numberOfNights = daysDiff(new Date(checkInDate), new Date(checkOutDate));

  const dates = {
    checkInDate: format(checkInDate, DATEPICKER_FORMAT),
    checkOutDate: format(checkOutDate, DATEPICKER_FORMAT)
  };

  const loadScheduledPayments = (code, willMovingWithPet = false) => {
    const selectedPaymentType = document.querySelector('[name="checkout[payment_type]"]:checked').value;

    return axios.get(previewBreakdown, {
      params: {
        coupon_code: code,
        start_date: checkInDate,
        end_date: checkOutDate,
        payment_type: selectedPaymentType,
        is_moving_with_pet: willMovingWithPet
      }
    });
  };

  const [breakdownDrawerOpen, setBreakdownDrawerOpen] = useState(false);
  const [rentScheduleDrawerOpen, setRentScheduleDrawerOpen] = useState(false);
  const [fullPaymentType, setFullPaymentType] = useState(paymentType === 'pay_in_full');
  const [rentScheduleState, setRentScheduleState] = useState({});
  const [detailsBreakdownState, setDetailsBreakdownState] = useState(detailsBreakdown);
  const [couponValidValue, setCouponValidValue] = useState('');
  const [couponValue, setCouponValue] = useState('');
  const [couponHelperMessage, setCouponHelperMessage] = useState([]);
  const params = new URLSearchParams(window.location.href);
  const isMovingWithPet = params.get('is_moving_with_pet');
  const isMovingWithPetBoolean = isMovingWithPet === 'true';
  const [willMoveWithPet, setWillMoveWithPet] = useState(isMovingWithPetBoolean);

  useEffect(async() => {
    await loadScheduledPayments(couponValidValue, willMoveWithPet)
      .then(response => {
        const { data } = response;
        setRentScheduleState(data);
      }
      ).catch(error => console.log(error));
  }, [previewBreakdown]);

  return (
    <ThemeProvider>
      <Container maxWidth="xl" disableGutters>
        <Grid
          container
          spacing={1}
          p={1}
          justifyContent="center"
        >
          <Dialog
            open={reservationInvalidDates}
            showCloseIcon={false}
            headerContent={(
              <Typography variant="h4">
                {t('invalid_dates_checkout_modal.title')}
              </Typography>
            )}
            sx={{ width: 1 }}
          >
            <CheckoutErrorModal
              description={t('invalid_dates_checkout_modal.description')}
              url={apartmentShowPath}
            />
          </Dialog>
          {showBreakdownDetailsMobileInfo ? (
            <Grid item md={12} bgcolor="secondary.light" >
              <Container display="flex" disableGutters>
                <Paper
                  elevation={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setBreakdownDrawerOpen(true);
                  }}
                >
                  <Box display="flex" p={1}>
                    <Box
                      sx={{
                        objectFit: 'cover',
                        maxWidth: '104px',
                        maxHeight: '89px',
                        borderRadius: 1,
                        mr: 0.5
                      }}
                      alt={t('checkout_page.alt_img') + propertyName}
                      variant="outlined"
                      src={propertyImg}
                      component="img"
                    />
                    <Box>
                      <Typography
                        color="primary"
                        display="flex"
                        variant="lead"
                        sx={{ opacity: 0.8 }}
                      >
                        {propertyCity}
                        {<span>&nbsp;•&nbsp;</span>}
                        {propertyNeighbourhood}
                      </Typography>
                      <Typography color="primary" variant="h6" sx={{ overflowWrap: 'anywhere' }}>
                        {propertyName}
                      </Typography>
                      <Box display="flex" flexWrap="wrap">
                        <SvgIcon mr={0.5} name="checkout_calendar_icon" />
                        <Typography fontSize="0.75rem" color="primary.70">{dates.checkInDate}</Typography>
                        <SvgIcon mx={0.4} name="checkout_arrow_right" />
                        <Typography fontSize="0.75rem" color="primary.70">{dates.checkOutDate}</Typography>
                      </Box>
                      <NumberOfNightsTag>
                        {`${numberOfNights} ${t('checkout_page.nights')}`}
                      </NumberOfNightsTag>
                    </Box>
                  </Box>
                  <Typography
                    display={'block'}
                    sx={{
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      py: 0.25
                    }}
                    color="primary.70"
                    bgcolor="secondary.light"
                  >
                    {fullPaymentType ? (t('checkout_page.breakdown.add_coupon_code')) : (t('checkout_page.breakdown.details_breakdown'))}
                  </Typography>
                </Paper>
              </Container>
            </Grid>
          ) : (
            <Grid item sm={5} order={{ sm: 2 }}>
              <Paper
                elevation={1}
                sx={{
                  position: 'fixed',
                  width: ['none', 300, 415]
                }}
              >
                <Box
                  sx={{
                    display: 'block',
                    p: [1, 1, 1, 1.5],
                    overflowY: 'auto',
                    maxHeight: '90vh'
                  }}>
                  <Box
                    sx={{
                      objectFit: 'cover',
                      borderRadius: 2,
                      maxHeight: '13.75rem',
                      width: 1
                    }}
                    alt={t('checkout_page.alt_img') + propertyName}
                    variant="outlined"
                    src={propertyImg}
                    component="img"
                  />
                  <Box px={1} my={1}>
                    <Typography
                      display="flex"
                      variant="lead"
                      sx={{ opacity: 0.8 }}
                    >
                      {propertyCity}
                      {<span>&nbsp;</span>}
                      <Box component="span">
                    • {propertyNeighbourhood}
                      </Box>
                      {<span>&nbsp;</span>}
                    </Typography>
                    <Typography color="primary" variant="h5">
                      {propertyName}
                    </Typography>
                  </Box>
                  <BreakdownDetails
                    data-preview-url={previewBreakdown}
                    data-breakdown-start-date={checkInDate}
                    data-breakdown-end-date={checkOutDate}
                    checkInDate={dates.checkInDate}
                    checkOutDate={dates.checkOutDate}
                    detailsBreakdown={detailsBreakdownState}
                    numberOfNights={numberOfNights}
                    handleOpenRentScheduleDrawer={() => setRentScheduleDrawerOpen(true)}
                    fullPaymentType={fullPaymentType}
                    isPetFriendly={isPetFriendly}
                    loadScheduledPayments={loadScheduledPayments}
                    setDetailsBreakdownState={setDetailsBreakdownState}
                    setRentScheduleState={setRentScheduleState}
                    willMoveWithPet={willMoveWithPet}
                    setWillMoveWithPet={setWillMoveWithPet}
                    couponValidValue={couponValidValue}
                  />
                  <CouponForm
                    setDetailsBreakdownState={setDetailsBreakdownState}
                    setCouponHelperMessage={setCouponHelperMessage}
                    loadScheduledPayments={loadScheduledPayments}
                    detailsBreakdownState={detailsBreakdownState}
                    setRentScheduleState={setRentScheduleState}
                    setCouponValidValue={setCouponValidValue}
                    couponHelperMessage={couponHelperMessage}
                    detailsBreakdown={detailsBreakdown}
                    couponValidValue={couponValidValue}
                    couponValue={couponValue}
                    setCouponValue={setCouponValue}
                    fullPaymentType={fullPaymentType}
                    willMoveWithPet={willMoveWithPet}
                  />
                </Box>
              </Paper>
            </Grid>
          )}
          {showBreakdownDetailsMobileInfo ? (
            <Drawer
              withBackButton={rentScheduleDrawerOpen}
              backUrl={() => setRentScheduleDrawerOpen(false)}
              onClose={() => {
                setBreakdownDrawerOpen(!breakdownDrawerOpen);
                setRentScheduleDrawerOpen(false);
              }}
              anchor="bottom"
              open={breakdownDrawerOpen}
              data-preview-url={previewBreakdown}
              data-breakdown-start-date={checkInDate}
              data-breakdown-end-date={checkOutDate}
              headerContent={fullPaymentType ? (t('checkout_page.breakdown.add_coupon_code')) : (t('checkout_page.breakdown.title'))}
            >
              {rentScheduleDrawerOpen ? (
                <RentScheduleDetails
                  rentScheduleState={rentScheduleState}
                  onClose={() => setRentScheduleDrawerOpen(false)}
                />
              ) : (
                <Box mx={1}>
                  <Box>
                    <BreakdownDetails
                      fullPaymentType={fullPaymentType}
                      checkInDate={dates.checkInDate}
                      checkOutDate={dates.checkOutDate}
                      detailsBreakdown={detailsBreakdownState}
                      numberOfNights={numberOfNights}
                      onClose={() => setBreakdownDrawerOpen(!breakdownDrawerOpen)}
                      handleOpenRentScheduleDrawer={() => setRentScheduleDrawerOpen(true)}
                    />
                  </Box>
                  <Box mb={1}>
                    <CouponForm
                      setDetailsBreakdownState={setDetailsBreakdownState}
                      setCouponHelperMessage={setCouponHelperMessage}
                      loadScheduledPayments={loadScheduledPayments}
                      detailsBreakdownState={detailsBreakdownState}
                      setRentScheduleState={setRentScheduleState}
                      setCouponValidValue={setCouponValidValue}
                      couponHelperMessage={couponHelperMessage}
                      detailsBreakdown={detailsBreakdown}
                      couponValidValue={couponValidValue}
                      couponValue={couponValue}
                      setCouponValue={setCouponValue}
                      fullPaymentType={fullPaymentType}
                    />
                  </Box>
                </Box>
              )}
            </Drawer>
          ) : (
            <Dialog
              onClose={() => setRentScheduleDrawerOpen(false)}
              open={rentScheduleDrawerOpen}
              headerContent={
                <Typography color="primary" variant="h5">
                  {t('scheduled_payments.js.modal.title')}
                </Typography>
              }
              maxWidth="xs"
            >
              <RentScheduleDetails
                rentScheduleState={rentScheduleState}
                onClose={() => setRentScheduleDrawerOpen(false)}
              />
            </Dialog>
          )}
          <Grid item sm={7} order={{ sm: 1 }} display="flex" justifyContent="flex-end">
            <CheckoutForm
              detailsBreakdown={detailsBreakdownState}
              setFullPaymentType={setFullPaymentType}
              recaptchaSiteKey={recaptchaSiteKey}
              couponValidValue={couponValidValue}
              currentUserAdmin={currentUserAdmin}
              paymentMethods={paymentMethods}
              policiesLinks={policiesLinks}
              namePattern={userNamePattern}
              kiwiSources={kiwiSources}
              actionRoute={actionRoute}
              paymentType={paymentType}
              fullPrice={fullPrice}
              language={currentLocale}
              rentScheduleState={rentScheduleState}
              pixActionRoute={pixActionRoute}
              willMoveWithPet={willMoveWithPet}
              casatb={casatb}
              email={email}
              firstName={firstName}
              lastName={lastName}
              phone={phone}
              idNumber={idNumber}
            />
          </Grid>
        </Grid>
      </Container>

    </ThemeProvider>
  );
};

export default Checkout;
