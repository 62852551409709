import React from 'react';
import { ReasonImage as StyledReasonImage } from './styles';

const ReasonImage = ({ src }) => {
  return (
    <StyledReasonImage src={src} />
  );
};

export default ReasonImage;
