import React, { useEffect } from 'react';
import formatCurrency from '../../../../js/common/formatCurrency';
import { t } from '../../../../js/common/translations';
import {
  Box,
  IconButton,
  SvgIcon,
  Typography
} from '../../elements';
import inputValidator from '../../../utils/inputValidator';

import { CouponButton, CouponTextField } from './CouponForm.styles';
import theme from '../../../theme';

const CouponForm = ({
  setDetailsBreakdownState,
  setCouponHelperMessage,
  loadScheduledPayments,
  detailsBreakdownState,
  setRentScheduleState,
  setCouponValidValue,
  couponHelperMessage,
  couponValidValue,
  couponValue,
  setCouponValue,
  detailsBreakdown,
  fullPaymentType,
  willMoveWithPet
}) => {
  const updateDetailsBreakdownWithCoupon = data => {
    setDetailsBreakdownState({
      first_month_rent: detailsBreakdownState.first_month_rent,
      rent_total: { display: true, price: `R$ ${formatCurrency(data.breakdown.total_rent)}` },
      discount_code_applied: {
        display: true,
        couponFinalPrice: `R$ ${formatCurrency(data.coupon.total_coupon_discount)}`,
        notApplyWhenPayAdvance: data.coupon.not_apply_when_pay_advance,
        couponKind: data.coupon.kind,
        couponValue: data.coupon.value
      },
      discountAndCoupon: data.breakdown.total_w_discount,
      long_term_first_payment_discount: detailsBreakdownState.long_term_first_payment_discount,
      cleaning_fee: detailsBreakdownState.cleaning_fee,
      total_monthly_cleaning_fee: detailsBreakdownState.total_monthly_cleaning_fee,
      deposit: detailsBreakdownState.deposit,
      final_cleaning_fee: detailsBreakdownState.final_cleaning_fee,
      fire_insurance: detailsBreakdownState.fire_insurance,
      pet_fee: detailsBreakdownState.pet_fee,
      due_today: { display: true, title: t('checkout_page.breakdown.due_today_label'), price: `R$ ${formatCurrency(data.breakdown.payments[0].total)}` },
      total_breakdown: { display: false, title: t('checkout_page.breakdown.due_short_period_label'), price: `R$ ${formatCurrency(data.breakdown.total_w_discount)}` }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (fullPaymentType && detailsBreakdownState?.discount_code_applied?.notApplyWhenPayAdvance) {
      resetDetailsBreakdown();
    }
  }, [fullPaymentType]);

  const getScheduledPayments = async(value) => {
    try {
      return (await loadScheduledPayments(value, willMoveWithPet)).data;
    } catch (error) {
      console.error(error);
      const { data } = error.response;
      data?.error?.coupon && setCouponHelperMessage([{
        field: 'couponValue',
        error: t('checkout_page.checkout_form.feedback.coupon_invalid')
      }]);
    }
  };

  const resetDetailsBreakdown = async() => {
    const { data } = await loadScheduledPayments(undefined, willMoveWithPet);
    setDetailsBreakdownState((prev) => ({
      ...prev,
      total_breakdown: { display: false, title: t('checkout_page.breakdown.due_short_period_label'), price: `R$ ${formatCurrency(data.breakdown.total_w_discount)}` },
      due_today: { display: false, title: t('checkout_page.breakdown.due_today_label'), price: `R$ ${formatCurrency(data.breakdown.payments[0].total)}` }
    }));
    setRentScheduleState(await getScheduledPayments());
    setCouponHelperMessage([]);
    setCouponValidValue('');
    setCouponValue('');
  };

  const resetCouponValues = async(errors, value) => {
    setCouponHelperMessage(errors);
    setCouponValidValue('');
    setCouponValue(value);
  };

  const setRentSchedule = async() => {
    const scheduledPayments = await getScheduledPayments(couponValue);
    updateDetailsBreakdownWithCoupon(scheduledPayments);
    setRentScheduleState(scheduledPayments);
    setCouponValidValue(couponValue);
    setCouponHelperMessage([{
      field: 'couponValue',
      error: t('checkout_page.checkout_form.feedback.coupon_valid')
    }]);
  };

  const handleChange = (value) => {
    const errors = inputValidator(
      { couponValue: value }, [['couponValue', { validation: () => /^\S*$/.test(value), errorMessage: t('checkout_page.checkout_form.feedback.coupon_invalid') }]]
    );
    resetCouponValues(errors, value);
  };

  const getHelperMessage = (inputName) => {
    return couponHelperMessage.filter(el => el.field === inputName)[0]?.error;
  };

  return (
    <Box textAlign={'left'}>
      <Typography
        textTransform={'uppercase'}
        fontWeight="600"
        variant="lead"
      >
        {t('checkout_page.checkout_form.coupon_title')}
      </Typography>
      <Box pt={0.5}>
        <Box alignText="center" display="flex">
          <CouponTextField
            onKeyDown={(e) => { e.keyCode === 13 && setRentSchedule() }}
            error={!!getHelperMessage('couponValue') && !couponValidValue}
            onChange={(e) => handleChange(e.target.value)}
            helperText={getHelperMessage('couponValue')}
            InputLabelProps={{ shrink: true }}
            success={couponValidValue}
            id="checkout-coupon-input"
            value={couponValue}
            autoFocus
            label=" "
            InputProps={{
              readOnly: couponValidValue,
              notched: false,
              endAdornment:
                couponValidValue || couponHelperMessage[0]?.error ? (
                  <IconButton
                    onClick={resetDetailsBreakdown}
                    sx={{
                      '&:hover span': {
                        backgroundColor: theme.palette.primary['10']
                      }
                    }}
                  >
                    <SvgIcon name='x_mark' width='1rem' zIndex='10' />
                  </IconButton>
                ) : (
                  null
                )
            }}
            sx={{
              input: {
                height: '1.5rem',
                padding: '0.75rem 1rem'
              },
              fieldset: {
                borderColor: '#E1DEDE'
              },
              marginBottom: '0.5rem'
            }}
          />
          <CouponButton
            color={couponValidValue ? 'success' : couponHelperMessage[0]?.error ? 'error' : 'primary'}
            onClick={setRentSchedule}
            disabled={!couponValue}
          >
            <Typography
              color='secondary'
              textTransform={'uppercase'}
              fontWeight='500'
            >
              {t('checkout_page.checkout_form.coupon_apply')}
            </Typography>
          </CouponButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CouponForm;
