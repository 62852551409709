import styled from 'styled-components';
import { Box } from '../../../elements';

export const WrapperStepText = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 26rem;
  color: ${({ theme }) => theme.palette.primary[50]};

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 21rem;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 1rem;
    width: 100%;
  };
`;
