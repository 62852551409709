import styled from 'styled-components';
import { Box, Typography, Divider, Button, Accordion } from '../elements';

export const Title = styled(Typography).attrs({
  fontSize: { xs: '1.25rem', md: '1.75rem' },
  component: 'h2'
})`
  font-weight: bold;
  font-family: 'Cambon';
`;

export const TitleWrapper = styled(Box)`
  display:flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;
export const Text = styled(Typography).attrs({
  component: 'p',
  my: '0.5rem',
  color: 'primary.main',
  fontSize: { xs: '0.8rem', md: '1rem' }
})`
`;

export const Subtitle = styled(Typography).attrs({
  component: 'h3',
  color: 'primary.main'
})`
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 0.75rem;
`;

export const SubtitleWrapper = styled(Box)`
  margin-bottom: 0.75rem;
  display: flex;
`;

export const DividerStyled = styled(Divider).attrs({
  color: 'primary.40'
})``;

export const StyledButtons = styled(Button).attrs({
  variant: 'borderless'
})`
  font-size: 0.85rem;
  padding-left: 0.5rem;
`;

export const ButtonWrapper = styled(Box).attrs({
  justifyContent: { xs: 'center', md: 'flex-start' }
})`
  width: max-content;
  display: flex;
`;

export const StyledAccordion = styled(Accordion)`
  border: 1px solid #F2F2F2;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: none;
`;

export const AccordionTitle = styled(Typography).attrs({
  color: 'primary.main',
  componenet: 'h4'
})`
  font-size: 0.875rem;
  font-weight: 600;
`;
