import React from 'react';
import Diamond from './Diamond';

const FixedTopDiamond = () => (
  <Diamond
    width={{ md: 430 }}
    height={{ md: 430 }}
    sx={{
      position: 'absolute',
      zIndex: -1,
      left: -137,
      top: -355,
      transform: 'rotate(45deg)',
      display: { xs: 'none', md: 'block' }
    }}
  />
);

export default FixedTopDiamond;
