import React from 'react';
import { ThemeProvider } from '../../contexts/theme';
import {
  Box,
  Button,
  Typography
} from '../elements';

const CheckoutErrorModal = ({ description, url }) => (
  <ThemeProvider>
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
      <Typography>
        {description}
      </Typography>
    </Box>

    <Box
      sx={{
        mt: 1.5,
        py: 1,
        display: 'flex',
        justifyContent: { xs: 'space-around', md: 'center' }
      }}
    >
      <Button
        size="large"
        type="submit"
        variant="contained"
        href={url}
      >
        {'OK'}
      </Button>
    </Box>
  </ThemeProvider>
);

export default CheckoutErrorModal;
