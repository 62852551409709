import axios from 'axios';
import customEvent from '../common/customEvent';
import elementQuery from '../common/elementQuery';
import cookiesBanner from '../common/cookiesBanner';
import mobileMenuToggle from '../common/mobileMenuToggle';
import languageSwitcher from '../common/languageSwitcher';
import covid19Banner from './covid19Banner';
import headerSpacer from './headerSpacer';
import menuStickSpacer from './menuStickSpacer';
import intlPhoneInput from '../common/intlPhoneInput/index';
import '../../common/translations';

customEvent(window);
elementQuery(window);
cookiesBanner(window);
mobileMenuToggle(window);
languageSwitcher(window);
covid19Banner(window);
headerSpacer(window);
menuStickSpacer(window);
intlPhoneInput({ http: axios });
