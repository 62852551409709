import React from 'react';
import Diamond from './Diamond';

const FixedBottomDiamond = () => (
  <Diamond
    width={{ xs: 100, md: 280 }}
    height={{ xs: 100, md: 280 }}
    sx={{
      position: 'absolute',
      zIndex: -1,
      left: { xs: '70%', md: 650, lg: 600, xl: 650 },
      top: { xs: '73%', md: 735, lg: 645, xl: 735 }
    }}
  />
);

export default FixedBottomDiamond;
