import React, { useState } from 'react';
import {
  Box,
  Typography,
  Drawer,
  Dialog,
  SvgIcon,
  Button,
  Toast,
  CardMedia
} from '../elements';
import { ThemeProvider } from '../../contexts/theme';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { t } from '../../../js/common/translations';
import { useToggle } from '../../hooks/useToggle';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {
  Container,
  InvitesTitle,
  CardsWrapper,
  CardInfoWrapper,
  ActionButton,
  ModalContainer,
  DialogTitle,
  DialogSubtitle,
  OwnerButtons,
  DialogButtonTitle,
  DialogButtonSubtitle,
  HeaderDialogWrapper,
  CardMainContent,
  InvitationInformation,
  ClickableCardIcon,
  InviteCard,
  InviteCardActionArea
} from './InvitesPage.styles';
import copyToClipboard from '../../utils/copyToClipboard';
import { CentralizedBox, NoReservationText } from './ReservationList.styles';

const InvitesPage = ({
  apartmentsRootPath,
  reservationInvites = []
}) => {
  const currentBreakpoint = useBreakpoint();
  const mobile = ['xs', 'sm'].includes(currentBreakpoint);
  const [removeInvite, setRemoveInvite] = useToggle();
  const [toastOpen, setToastOpen] = useState();
  const [confirmToastOpen, setConfirmToastOpen] = useToggle();

  if (toastOpen) {
    setTimeout(() => {
      setToastOpen(false);
    }, 5000);
  }

  const removeInvitation = async(reservationInvite, setOpenModal) => {
    const origin = window.location.origin;
    const updatePath = `${origin}${reservationInvite.update_path}`;

    if (!reservationInvite.update_path) {
      console.error('Something went wrong trying to remove the invitation, please try again later.');
      return;
    }

    const formData = new FormData();
    formData.append('reservation_invite[status]', 'Declined');
    if (reservationInvite.ownership === 'owner') {
      formData.append('revoke_share_link', true);
    }
    const response = await axios.patch(updatePath, formData);

    if (response.data.status !== 200) {
      console.error(response.data.error);
      setOpenModal();
      return;
    }

    setRemoveInvite();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const acceptInvitation = async(reservationInvite) => {
    const origin = window.location.origin;
    const updatePath = `${origin}${reservationInvite.update_path}`;

    if (!reservationInvite.update_path) {
      console.error('Something went wrong trying to accept your invitation, please try again later.');
      return;
    }

    const formData = new FormData();
    formData.append('reservation_invite[status]', 'Approved');
    const response = await axios.patch(updatePath, formData);

    if (response.data.status !== 200) {
      console.error(response.data.error);
      return;
    }

    setConfirmToastOpen();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const statusText = {
    guest: t('invitations_page.invited_by'),
    owner: t('invitations_page.shared_with')
  };

  const ModalContent = (reservationInvite, setOpenModal) => (
    <ModalContainer>
      <Box
        display={removeInvite ? 'none' : 'flex'}
        alignItems="center"
        flexDirection="column"
      >
        <SvgIcon name="blue_info" size="72" mb={1} />
        <DialogTitle>
          { t(`member_reservation.reservation_invite.remove_invite_${reservationInvite.ownership}_title`) }
        </DialogTitle>
        <DialogSubtitle pb={2.5}>
          { t(`member_reservation.reservation_invite.remove_invite_${reservationInvite.ownership}_subtitle`) }
        </DialogSubtitle>
        <Box px={{ xs: 1, md: 0 }} display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          <Button
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              setOpenModal();
            }}
            mr={0.25}
            sx={{
              width: { xs: '100%', md: 'auto' }
            }}
          >
            {t('member_reservation.reservation_invite.remove_invite_button_no')}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              removeInvitation(reservationInvite, setOpenModal);
            }}
            ml={0.25}
            sx={{
              width: { xs: '100%', md: 'auto' }
            }}
          >
            { t(`member_reservation.reservation_invite.remove_invite_${reservationInvite.ownership}_button_yes`) }
          </Button>
        </Box>
      </Box>
      <Box
        display={!removeInvite ? 'none' : 'flex'}
        alignItems="center"
        flexDirection="column"
      >
        <SvgIcon name="success_circle_outline" size="72" />
        <Typography
          fontSize="1.125rem"
          fontWeight={600}
          lineHeight='24px'
          mt={1.5}
        >
          {t('member_reservation.reservation_invite.remove_invite_removed_title')}
        </Typography>
        <Typography
          fontSize="0.875rem"
          lineHeight='21px'
          textAlign="center"
        >
          {t('member_reservation.reservation_invite.remove_invite_removed_description')}
        </Typography>
        {reservationInvite.ownership === 'owner' && (
          <Typography
            fontSize="0.875rem"
            textAlign="center"
            pb={1.5}
          >
            {t('member_reservation.reservation_invite.remove_invite_removed_invite_again')}
          </Typography>
        )}
      </Box>
    </ModalContainer>
  );

  const resendContent = (reservationInvite, setOpenModal) => {
    return {
      header: <HeaderDialogWrapper>
        <Typography component="h2" color="primary.light" variant="h6" fontWeight="500">
          {t('member_reservation.reservation_invite.dialog_subtitle')}
        </Typography>
        <Typography component="h1" color="primary.main" variant="h4">
          {t('member_reservation.reservation_invite.dialog_title_owner')}
        </Typography>
      </HeaderDialogWrapper>,
      content: <ModalContainer onClick={(e) => e.stopPropagation()}>
        <OwnerButtons
          href={`https://wa.me/?text=${t('invitations_page.body_message_line_1')}${t('invitations_page.body_message_line_2', { owner: reservationInvite.owner_name })}${t('invitations_page.body_message_line_3')}${t('invitations_page.body_message_line_4', { share_link: reservationInvite.reservation.share_reservation_url })}`}
          my={0.5}
        >
          <SvgIcon mx={1} name="whatsapp" size='20' />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <DialogButtonTitle>
              {t('member_reservation.reservation_invite.whatsapp')}
            </DialogButtonTitle>
            <DialogButtonSubtitle>
              {t('member_reservation.reservation_invite.whatsapp_description')}
            </DialogButtonSubtitle>
          </Box >
        </OwnerButtons >
        <OwnerButtons
          href={`mailto:?to=&subject=${t('invitations_page.email_subject_message', { owner_name: reservationInvite.owner_name })}&body=${t('invitations_page.body_message_line_1')}${t('invitations_page.body_message_line_2', { owner: reservationInvite.owner_name })}${t('invitations_page.body_message_line_3')}${t('invitations_page.body_message_line_4', { share_link: reservationInvite.reservation.share_reservation_url })}`}
          my={0.5}
        >
          <SvgIcon mx={1} name="envelope" size='20' />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <DialogButtonTitle>
              {t('member_reservation.reservation_invite.email')}
            </DialogButtonTitle>
            <DialogButtonSubtitle>
              {t('member_reservation.reservation_invite.email_description')}
            </DialogButtonSubtitle>
          </Box>
        </OwnerButtons>
        <OwnerButtons
          my={0.5}
          onClick={(e) => {
            e.stopPropagation();
            setToastOpen(true);
            if (reservationInvite.reservation.share_reservation_url) copyToClipboard(reservationInvite.reservation.share_reservation_url);
          }}
        >
          <SvgIcon mx={1} name="chain_link" size='20' />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <DialogButtonTitle>
              {t('member_reservation.reservation_invite.copy_paste')}
            </DialogButtonTitle>
            <DialogButtonSubtitle>
              {t('member_reservation.reservation_invite.copy_paste_description')}
            </DialogButtonSubtitle>
          </Box>
        </OwnerButtons>
        <hr style={{ marginTop: '1rem', borderColor: 'primary.main' }} />
        <DialogButtonSubtitle mt={0.25} mb={2} color="primary.light">
          {t('member_reservation.reservation_invite.owner_dialog_reminder')}
        </DialogButtonSubtitle>
        <Toast
          type="success"
          text={t('member_reservation.reservation_invite.link_copied')}
          open={toastOpen}
          onClose={() => {
            setOpenModal();
            setToastOpen(false);
          }}
        />
      </ModalContainer >
    };
  };

  if (reservationInvites.length === 0) {
    return (
      <CentralizedBox>
        <SvgIcon name="empty_reservations" />
        <NoReservationText>{t('invitations_page.empty_invite')}</NoReservationText>
        <Button
          size="large"
          onClick={() => (window.location.href = apartmentsRootPath)}
          sx={{
            width: '80vw',
            maxWidth: '400px'
          }}
        >
          {t('bookings_page.find_btn')}
        </Button>
      </CentralizedBox>
    );
  }

  return (
    <ThemeProvider>
      <Container>
        <InvitesTitle>{t('invitations_page.title')}</InvitesTitle>
        <CardsWrapper>
          {reservationInvites.map((item, i) => {
            const inviteWaitingToAccept = item.status === 'Waiting' && item.ownership === 'guest';
            const [openModal, setOpenModal] = useToggle();

            return (
              <InviteCard key={uuidv4()}>
                <Box width="100%" height="100%" position="relative">
                  <InviteCardActionArea
                    href={item.booking_path}
                    disabled={inviteWaitingToAccept}
                  >
                    <CardMainContent sx={{ ...((inviteWaitingToAccept) && { opacity: 0.5 }) }}>
                      <CardMedia
                        component="img"
                        image={item.reservation.photo}
                        sx={{
                          borderRadius: '3px',
                          height: mobile ? '50px' : '100px',
                          width: mobile ? '50px' : '100px'
                        }}
                      />
                      <CardInfoWrapper>
                        <Box>
                          <Typography fontSize="0.625rem" color="primary.60">
                            {item.reservation.internal_id} • {item.reservation.start_date}
                          </Typography>
                          <Typography fontWeight={600}>{item.reservation.listing_name}</Typography>
                          <Typography fontSize="0.625rem" color="primary.60">{t('invitations_page.apartment_number_floor', { apartment_number: item.reservation.number, floor: item.reservation.floor_number })}</Typography>
                        </Box>
                        {!mobile && <Box>
                          <Typography
                            fontSize="0.625rem"
                            fontWeight={600}
                          >
                            { statusText[item.ownership] }
                          </Typography>
                          <Typography color="primary.main" opacity={0.7}>
                            { item.status === 'Approved' && item.ownership === 'owner'
                              ? item.guest_name : item.status === 'Approved'
                                ? item.owner_name : item.ownership === 'guest'
                                  ? t('invitations_page.guest_pending_invite', { owner: item.owner_name })
                                  : t('invitations_page.owner_pending_invite', { guest: item.guest_name })
                            }
                          </Typography>
                        </Box>}
                      </CardInfoWrapper>
                      {(!inviteWaitingToAccept) && <ClickableCardIcon />}
                    </CardMainContent>
                    {mobile && <InvitationInformation sx={{ ...((inviteWaitingToAccept) && { opacity: 0.5 }) }}>
                      <Typography
                        fontSize="0.625rem"
                        color="primary.main"
                        fontWeight={500}
                        minWidth="80px"
                      >
                        { statusText[item.ownership] }
                      </Typography>
                      <Typography
                        color="primary.main"
                        fontSize="0.625rem"
                        opacity={0.7}
                        fontWeight={400}
                      >
                        { item.status === 'Approved' && item.ownership === 'owner'
                          ? item.guest_name : item.status === 'Approved'
                            ? item.owner_name : item.ownership === 'guest'
                              ? t('invitations_page.guest_pending_invite', { owner: item.owner_name })
                              : t('invitations_page.owner_pending_invite', { guest: item.guest_name })
                        }
                      </Typography>
                    </InvitationInformation>}
                    {mobile ? (
                      <Drawer
                        headerContent={''}
                        onClose={(e) => {
                          e.stopPropagation();
                          setOpenModal();
                        }}
                        anchor='bottom'
                        open={openModal}>
                        {(item.status === 'Approved' || (item.status === 'Waiting' && item.ownership === 'guest')) &&
                      ModalContent(item, setOpenModal)
                        }
                        {(item.ownership === 'owner' && item.status === 'Waiting') && resendContent(item, setOpenModal).content}
                      </Drawer>
                    ) : (
                      <Dialog
                        open={openModal}
                        onClose={(e) => {
                          e.stopPropagation();
                          setOpenModal();
                        }}
                        sx={{
                          width: 1,
                          '& .MuiDialog-paper': { backgroundColor: item.ownership === 'owner' ? 'background.100' : 'secondary.main' },
                          '& .MuiDialogContent-root': { backgroundColor: 'secondary.main' }
                        }}
                        headerContent={
                          (item.ownertship === 'owner' && item.status === 'Waiting')
                            ? resendContent(item, setOpenModal).header
                            : ''}
                      >
                        {(item.status === 'Approved' || (item.status === 'Waiting' && item.ownership === 'guest')) && ModalContent(item, setOpenModal)}
                        {(item.ownership === 'owner' && item.status === 'Waiting') &&
                      resendContent(item, setOpenModal).content
                        }
                      </Dialog>
                    )}
                  </InviteCardActionArea>
                  {(item.ownership === 'owner' && item.status === 'Waiting') && (
                    <ActionButton
                      backgroundColor="warning.70"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal();
                      }}
                    >
                      {t('invitations_page.resend_invite')}
                    </ActionButton>
                  )}
                  {(item.status === 'Approved' || (item.status === 'Waiting' && item.ownership === 'guest')) && (
                    <ActionButton
                      backgroundColor="error.70"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal();
                      }}
                      $isSecondChild={(item.status === 'Waiting' && item.ownership === 'guest')}
                    >
                      {item.ownership === 'owner' ? t('invitations_page.remove_invite') : t('invitations_page.decline_invite')}
                    </ActionButton>
                  )}
                  {(item.ownership === 'guest' && item.status === 'Waiting') && (
                    <ActionButton
                      onClick={(e) => {
                        e.stopPropagation();
                        acceptInvitation(item);
                      }}
                      backgroundColor="success.main"
                      $isFirstChild={true}
                    >
                      {t('invitations_page.accept_invite')}
                    </ActionButton>
                  )}
                </Box>
              </InviteCard>
            );
          })}
        </CardsWrapper>
        <Toast
          type="success"
          text={t('invitations_page.accept_invite_success_message')}
          open={confirmToastOpen}
          onClose={() => setConfirmToastOpen(false)}
        />
      </Container>
    </ThemeProvider>
  );
};

export default InvitesPage;
