import React from 'react';
import { Box, Typography } from '../../../elements';
import Image from '../Image';

const RentApartmentSlide = ({ image, title, description }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', sm: 'row' },
        justifyContent: { xs: 'flex-end', sm: 'space-evenly' },
        height: { xs: '500px', sm: '300px' }
      }}
    >
      <Image
        src={image}
        sx={{
          width: { xs: '100%', md: 'inherit' }, height: { xs: '205px', sm: '245px' }, marginTop: 'auto', marginBottom: '3rem'
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginLeft: { xs: '0', sm: '1rem' },
          width: { xs: '100%', md: '520px' },
          gap: { xs: '0.75rem', md: '1rem' }
        }}
      >
        <Typography
          fontFamily="Poppins"
          fontWeight={600}
          fontSize={{ xs: '2rem', sm: '1.7rem', md: '2rem' }}
          lineHeight="2.5rem"
          color="primary.main"
          height={{ xs: '80px' }}
        >
          {title}
        </Typography>
        <Typography
          component="span"
          fontSize="1rem"
          lineHeight="1.5rem"
          mb={{ xs: '0.5rem', sm: 0 }}
          dangerouslySetInnerHTML={{ __html: `${description}` }}
        />
      </Box>
    </Box>
  );
};

export default RentApartmentSlide;
