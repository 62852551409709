import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  Box,
  FormControlLabel,
  Radio
} from '../';
import CheckIcon from '../../../../images/react_icons/Check';

const PaymentTypeRadioIconContainer = styled(Box)`
  border: ${({ checked, theme }) => `1px solid ${checked ? 'transparent' : theme.palette.primary['70']}`};
  border-radius: 100px;

  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.palette.primary.main};

  line-height: 0px;
  padding: 3px;
`;

export default ({ name, value, label, iconBgColor, boxSX, ...rest }) => {
  const iconBg = useMemo(() => ({ selected: '', default: 'secondary.main', ...iconBgColor }), [iconBgColor]);

  return (
    <Box
      px={1}
      py={{ xs: 1, sm: 0.6875 }}
      my={0.25}
      sx={{ borderRadius: 1.5, ...boxSX, cursor: 'pointer' }}
      {...rest}
    >
      <FormControlLabel
        sx={{ width: 1, m: 0 }}
        disableTypography
        name={name}
        value={value}
        control={
          <Radio
            sx={{ mr: 1, p: 0 }}
            checkedIcon={
              <PaymentTypeRadioIconContainer backgroundColor={iconBg.selected} checked>
                <CheckIcon sx={{ color: '#FEFEFE', height: 14, width: 14 }} />
              </PaymentTypeRadioIconContainer>
            }
            icon={
              <PaymentTypeRadioIconContainer backgroundColor={iconBg.default}>
                <Box sx={{ height: 14, width: 14 }} />
              </PaymentTypeRadioIconContainer>
            }
          />
        }
        label={label}
      />
    </Box>
  );
};
