import React from 'react';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import {
  SectionHero,
  Hero,
  BuildingName,
  FirstSectionTitle,
  FirstSection,
  FirstSectionDescription,
  FirstSectionPicturesWrapper,
  FirstSectionPictures,
  SecondSection,
  SecondSectionLeft,
  InfoBox,
  SecondSectionLeftPicture,
  SecondSectionRight,
  ThirdSection,
  SectionsDescriptions,
  FourthSection,
  BuildingApartmentsSection,
  BuildingApartmentsSectionTextWrapper,
  ThirdSectionContainer,
  ThirdSectionPolaroid,
  ThirdSectionPolaroidPicture
} from './BuildingsPage.styles';
import { PropertyCardSlider, CTABottomBar } from '.';
import { Box, Container, Typography, HtmlTranslation, SvgIcon } from '../elements';
import placeholderHero from '../../../images/building_images/placeholder_hero.png';
import placeholderSectionOne1 from '../../../images/building_images/placeholder_1section_1.png';
import placeholderSectionOne2 from '../../../images/building_images/placeholder_1section_2.png';
import placeholderSection2 from '../../../images/building_images/placeholder_2section.png';
import placeholderLocationOne from '../../../images/building_images/placeholder_locations_1.png';
import placeholderLocationTwo from '../../../images/building_images/placeholder_locations_2.png';

const BuildingsPage = ({
  building, casatb
}) => {
  const mobile = ['xs'].includes(useBreakpoint());
  const hasProperties = !!building.first_seven_properties && !!building.first_seven_properties.length;

  const photosByPlace = building.photos.reduce((
    places,
    // eslint-disable-next-line camelcase
    { img_alt, url_for_photo_image_variant_r2f_1280x769: url }
  ) => ({
    ...places,
    [img_alt.toLowerCase().replace(/\s/g, '_')]: url
  }), {});

  return (
    <ThemeProvider>
      <SectionHero mb={2}>
        <Hero component='picture'>
          <Hero component='img' src={photosByPlace.building_hero || placeholderHero} alt={t('buildings_page.alt_1', { building_name: building.name })} />
        </Hero>
        <Box position='absolute' zIndex={1} sx={{ textAlign: 'center' }} mb={2.5} >
          <BuildingName className='text-tabas-salmon' fontFamily="Cambon" component="h1">
            <HtmlTranslation text={t('buildings_page.name', { building_name: building.name })} />
          </BuildingName>
          <Typography fontSize={40} fontWeight={700} fontFamily="Cambon" color="secondary.main" component="span">
            {building.subtitle ? (
              <HtmlTranslation text={building.subtitle} />
            ) : (
              `${t('buildings_page.building')}`
            )}
          </Typography>
        </Box>
      </SectionHero>
      <Container disableGutters sx={{ paddingLeft: { xs: 1, sm: 2 }, paddingRight: { xs: 1, sm: 2 }, paddingBottom: { xs: hasProperties ? 0 : 4, sm: hasProperties ? 0 : 5 } }}>
        <FirstSection>
          <FirstSectionTitle mb={1} fontFamily="Cambon" component="h2">
            <HtmlTranslation text={building && building.description_title_01} />
          </FirstSectionTitle>
          <FirstSectionDescription>
            <HtmlTranslation text={building.description_content_01} />
          </FirstSectionDescription>
          <FirstSectionPicturesWrapper mb={{ xs: 1, md: 3.5 }}>
            <FirstSectionPictures component="picture" left={0} top={{ xs: 0, md: 20 }} position={{ xs: 'relative', md: 'absolute' }} zIndex={10} my={{ xs: 1, md: 0 }}>
              <FirstSectionPictures component='img' src={photosByPlace.building_page_section_one_1 || placeholderSectionOne1} alt={t('buildings_page.alt_2', { building_name: building.name })} sx={{ objectFit: 'cover' }} />
            </FirstSectionPictures>
            <FirstSectionPictures component="picture" right={0} top={{ xs: 0, md: 125 }} position={{ xs: 'relative', md: 'absolute' }} zIndex={4} my={{ xs: 1, md: 0 }}>
              <FirstSectionPictures component="img" src={photosByPlace.building_page_section_one_2 || placeholderSectionOne2} alt={t('buildings_page.alt_3', { building_name: building.name })} sx={{ objectFit: 'cover' }} />
            </FirstSectionPictures>
            <Box height={{ xs: 0, md: 500 }} />
          </FirstSectionPicturesWrapper>
        </FirstSection>
        <SecondSection alignItems={building.second_section_info_box ? 'flex-end' : 'flex-start'} >
          <SecondSectionLeft mr={1.5}>
            {!!building.second_section_info_box && (
              <Box height='100%' display='flex' justifyContent={{ xs: 'center', md: 'flex-start', zIndex: 2 }} mb={1} pb={2}>
                <InfoBox>
                  <HtmlTranslation text={building.second_section_info_box} />
                </InfoBox>
              </Box>
            )}
            <SecondSectionLeftPicture component="picture" display={{ xs: 'none', md: 'flex' }}>
              <SecondSectionLeftPicture component="img" sx={{ objectFit: 'cover' }} alt={t('buildings_page.alt_4', { building_name: building.name })} src={photosByPlace.building_page_section_two || placeholderSection2}
              />
            </SecondSectionLeftPicture>
          </SecondSectionLeft>
          <SecondSectionRight>
            <Typography fontSize={18} sx={{ whiteSpace: 'break-spaces' }}>
              <HtmlTranslation text={building.description_content_02} />
            </Typography>
          </SecondSectionRight>
          <Box width="100%" display={{ xs: 'flex', md: 'none' }} justifyContent='center' mt={{ xs: 0, sm: 2 }}>
            <SecondSectionLeftPicture component="picture" >
              <SecondSectionLeftPicture component="img" sx={{ objectFit: 'cover' }} src={photosByPlace.building_page_section_two || placeholderSection2}
              />
            </SecondSectionLeftPicture>
          </Box>
        </SecondSection>
      </Container>
      {hasProperties && (
        <>
          <BuildingApartmentsSection mt={6} py={{ xs: 1, md: 3.5 }}>
            <Container>
              <BuildingApartmentsSectionTextWrapper mb={3.5} display='flex' flexDirection="column" alignItems='center'>
                <Typography component="h2" fontSize={{ xs: 25, md: 40 }} fontWeight={800} fontFamily='cambon' mr={0.65}>{t('buildings_page.looking')}</Typography>
                <Typography component="h2" fontSize={{ xs: 25, md: 40 }} fontWeight={600} fontFamily='cambon'>{t('buildings_page.for_apartment')}</Typography>
                <SectionsDescriptions component="span" fontSize={{ xs: 16, md: 18 }} width={{ xs: '60%', md: '100%' }}>{t('buildings_page.explore', { building_name: building.name })}</SectionsDescriptions>
              </BuildingApartmentsSectionTextWrapper>
              <PropertyCardSlider properties={building.first_seven_properties} />
            </Container>
          </BuildingApartmentsSection>
        </>
      )}
      {!!building.location_description && (
        <ThirdSection>
          <ThirdSectionContainer disableGutters>
            <Box display="flex" flexDirection='column' width={{ xs: '100%', md: '50%', zIndex: 2 }} >
              <Typography sx={{ color: 'secondary.main', fontSize: 40, fontWeight: 700 }} fontFamily="Cambon" mb={2} component="h2">
                {building.location_title}
              </Typography>
              <Typography fontSize={18} sx={{ color: 'secondary.main' }}><HtmlTranslation text={building.location_description} /></Typography>
            </Box>
            <Box
              position="relative"
              width={{ xs: '100%', md: '50%' }}
              display="flex"
              justifyContent={{ xs: 'center', sm: 'space-around' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems='center'
              height={{ xs: 'auto', sm: '540px' }}
              mt={{ xs: 2.5, md: 0 }}>
              <ThirdSectionPolaroid py={1} left={{ xs: 0, md: 65 }} top={0} zIndex={2}>
                <ThirdSectionPolaroidPicture component="picture" >
                  <ThirdSectionPolaroidPicture component="img" src={photosByPlace.building_page_location_1 || placeholderLocationOne} sx={{ objectFit: 'cover' }} />
                </ThirdSectionPolaroidPicture>
                <Box mt={1}>
                  <Typography color='primary.main'>
                    <HtmlTranslation text={building.location_photo_title_1} />
                  </Typography>
                </Box>
              </ThirdSectionPolaroid>
              <ThirdSectionPolaroid p={1} right={0} bottom={0} zIndex={1}>
                <ThirdSectionPolaroidPicture component="picture" >
                  <ThirdSectionPolaroidPicture component="img" src={photosByPlace.building_page_location_2 || placeholderLocationTwo} sx={{ objectFit: 'cover' }} />
                </ThirdSectionPolaroidPicture>
                <Box mt={1}>
                  <Typography color='primary.main'>
                    <HtmlTranslation text={building.location_photo_title_2} />
                  </Typography>
                </Box>
              </ThirdSectionPolaroid>
            </Box>
          </ThirdSectionContainer>
        </ThirdSection>
      )}
      <FourthSection pt={3.5} pb={2.5}>
        <Container disableGutters sx={{ paddingLeft: { xs: 1, sm: 2 }, paddingRight: { xs: 1, sm: 2 } }}>
          <Typography component="h2" fontSize={40} fontWeight={700} sx={{ textAlign: 'center' }} fontFamily='Cambon' mb={1}>
            <HtmlTranslation text={building.description_title_03} />
          </Typography>
          <SectionsDescriptions>
            <HtmlTranslation text={building.description_content_03} />
          </SectionsDescriptions>
        </Container>
      </FourthSection>
      <CTABottomBar
        actionButtonProps={{
          children: (
            <>
              <SvgIcon name={mobile ? 'whatsapp_light' : 'whatsapp'} width={20} height={20} mr={0.25} />
              {t('buildings_page.whatsapp_cta')}
            </>
          ),
          href: `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')}&building_name=${building.name}`,
          target: '_blank',
          className: 'whatsapp-link whatsapp-link-content-scroll'
        }}
        text={t('buildings_page.rent_with_us', { building_name: building.name })}
      />
    </ThemeProvider>
  );
};

export default BuildingsPage;
