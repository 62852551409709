import styled from 'styled-components';
import { Box, Typography } from '../UI/elements';

export const NumberOfNightsTag = styled(Box).attrs({
  component: 'span'
})`
  color: ${({ theme }) => theme.palette.success.dark};
  background-color: ${({ theme }) => theme.palette.success.light};

  padding: 0.25rem 0.75rem;

  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.palette.success.dark};
`;

export const NightsTagText = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125rem;
`;
