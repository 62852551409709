import styled from '@emotion/styled';
import { Box } from '../../../elements';
import Image from '../Image';

const Reason = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 3rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: space-between;
    gap: 16px;
  };
`;

export const ReasonTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 486px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 463px;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  };
`;

export const ReasonOne = styled(Reason)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  };
`;

export const ReasonTwo = styled(Reason)`
  flex-direction: row;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  };
`;

export const ReasonThree = styled(Reason)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
  };
`;

export const ReasonImage = styled(Image)`
  width: 400px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 315px;
  };

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    margin-top: 1rem;
  };
`;

export const WrapperReasonText = styled(Box)`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 1rem;
    width: 100%;
  };
`;
