import React from 'react';
import { Box } from '@mui/material';

const HeroImage = ({ sx, src, ...rest }) => {
  return (
    <Box
      component="picture"
      maxWidth='none'
      sx={{
        transform: 'rotate(-22deg)',
        objectFit: 'cover',
        ...sx
      }}
      {...rest}
    >
      <Box
        component="img"
        maxWidth='none'
        sx={{
          transform: 'rotate(-22deg)',
          objectFit: 'cover',
          ...sx
        }}
        src={src}
        {...rest}
      />
    </Box>
  );
};

export default HeroImage;
