import React from 'react';
import styled, { css } from 'styled-components';
import { Box, SvgIcon } from '../UI/elements';

import heroSm from '../../images/corporate/hero/sm.png';
import heroMd from '../../images/corporate/hero/md.png';
import heroLg from '../../images/corporate/hero/lg.png';
import heroSmWebp from '../../images/corporate/hero/sm.webp';
import heroMdWebp from '../../images/corporate/hero/md.webp';
import heroLgWebp from '../../images/corporate/hero/lg.webp';
import firstSlideSm from '../../images/corporate/apartments/first_slide_sm.png';
import firstSlideMd from '../../images/corporate/apartments/first_slide_md.png';
import firstSlideLg from '../../images/corporate/apartments/first_slide_lg.png';
import secondSlideSm from '../../images/corporate/apartments/second_slide_sm.png';
import secondSlideMd from '../../images/corporate/apartments/second_slide_md.png';
import secondSlideLg from '../../images/corporate/apartments/second_slide_lg.png';
import thirdSlideSm from '../../images/corporate/apartments/third_slide_sm.png';
import thirdSlideMd from '../../images/corporate/apartments/third_slide_md.png';
import thirdSlideLg from '../../images/corporate/apartments/third_slide_lg.png';

export const heroImages = {
  webp: {
    xs: heroSmWebp,
    sm: heroSmWebp,
    md: heroMdWebp,
    lg: heroLgWebp,
    xl: heroLgWebp
  },
  png: {
    xs: heroSm,
    sm: heroSm,
    md: heroMd,
    lg: heroLg,
    xl: heroLg
  }
};

export const slidesImages = {
  first: {
    xs: firstSlideSm,
    sm: firstSlideSm,
    md: firstSlideMd,
    lg: firstSlideLg,
    xl: firstSlideLg
  },
  second: {
    xs: secondSlideSm,
    sm: secondSlideSm,
    md: secondSlideMd,
    lg: secondSlideLg,
    xl: secondSlideLg
  },
  third: {
    xs: thirdSlideSm,
    sm: thirdSlideSm,
    md: thirdSlideMd,
    lg: thirdSlideLg,
    xl: thirdSlideLg
  }
};

const glassBackground = css`
  backdrop-filter: blur(4px);
  background: rgba(254, 254, 254, 0.5);
`;

export const HeroContainer = styled(Box).attrs({
  component: 'section',
  display: 'flex',
  className: 'hero',
  position: 'relative',
  alignItems: 'flex-end',
  justifyContent: 'center'
})`

&::after{
    content: '';
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.95)
                              0%, rgba(51, 51, 51, 0) 53.13%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 1rem;
    height: 100%;
  }
`;

export const Hero = styled(Box).attrs({
  height: 1,
  width: 1,
  maxHeight: { xs: 1, lg: 1000 },
  position: 'relative',
  borderRadius: 3,
  minHeight: { xs: 1, lg: 800 }
})`
  height: calc(97vh - 60px);
  object-fit: cover;
`;

export const HeroInfoWrapper = styled(Box).attrs({
  position: 'absolute',
  minWidth: 272,
  m: { xs: 1, md: 3 },
  zIndex: 5
})``;

export const CorporateCard = styled(Box)`
  border-radius: .75rem;
  min-height: 220px;
  padding: 1.5rem;
  transition: all 0.3s ease-in-out;
  ${({ mobile }) => mobile ? css`
    box-shadow: 0px 0px 12px rgba(41, 45, 50, 0.16);
    margin: 2rem 1rem 3rem 1rem;
    max-width: 300px;
  ` : css`
    &:hover {
      box-shadow: 0px 0px 12px rgba(41, 45, 50, 0.16);
    }
  `}
`;

export const Slider = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  background-image: ${({ bgImageSrc }) => `url("${bgImageSrc}")`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.5rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 100%;
    padding: 0.5rem;
  }
`;

export const SliderWrapper = styled(Box)`
  border-radius: 0.75rem;
  overflow: overlay;
  height: 100%;
  width: 100%;
  user-select: none;

  .swiper-wrapper {
    .swiper-slide:first-child {
      ${Slider} {
        border-radius: .75rem 0 0 .75rem;
      }
    }
    .swiper-slide:last-child {
      ${Slider} {
        border-radius: 0 .75rem .75rem 0;
      }
    }
  }
`;

export const ClientSliderTextArea = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 1rem;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 440px;
  }
  ${glassBackground}
`;

export const CityCard = styled(Box)`

  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  display: flex;
  align-items: end;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
  }
`;

export const CityName = styled.figcaption`
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-right: 2rem;
  margin-left: 2rem;
  ${glassBackground}
`;

export const CitiesContainer = styled(Box)`
  border-radius: 0.25rem;
  height: 400px;
  box-shadow: 0px 0px 12px rgba(41, 45, 50, 0.16);
  position: relative;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }

  ${CityCard}:first-child img {
      left: 1rem;
      width: calc(60% - 8px);
      height: 100%;
      object-fit: cover;
      border-radius: .25rem 0 0 .25rem;
      clip-path: polygon(0 0, 85% 0, 78% 100%, 0% 100%);
  }
  ${CityCard}:last-child  img {
    right: 1rem;
    width: calc(60% - 8px);
    height: 100%;
    object-fit: cover;
    border-radius: 0 .25rem .25rem 0;
    clip-path: polygon(22% 0, 100% 0, 100% 100%, 15% 100%);
  }
`;

export const SectionSliderWrapper = styled(Box)`
  height: calc(100vh - 56px);
  min-height: 300;
  max-height: 80vh;
  position: relative;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 600px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-height: 1000px;
  }
`;

export const IconRounded = styled(Box).attrs(({ name }) => ({
  children: <SvgIcon name={name} />,
  size: 26
}))`
  padding: 15px;
  background: ${({ theme }) => theme.palette.primary[10]};
  width: 56px;
  height: 56px;
  border-radius: 50rem;
`;
