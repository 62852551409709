import React from 'react';
import { Typography } from '../../../elements';
import { WrapperReasonText } from './styles';

const ReasonText = ({ title, description }) => (
  <WrapperReasonText>
    <Typography
      fontSize="2rem"
      fontWeight={600}
      fontFamily="Poppins"
      lineHeight="2.313rem"
      color="primary.main"
    >
      {title}
    </Typography>
    <Typography
      fontSize="1rem"
      fontWeight={400}
      fontFamily="Poppins"
      lineHeight="1.875rem"
      marginTop="0.75rem"
    >
      <span dangerouslySetInnerHTML={{ __html: `${description}` }} />
    </Typography>
  </WrapperReasonText>
);

export default ReasonText;
