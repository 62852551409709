import React from 'react';
import { checkWebp } from '../utils/checkWebp';
import faqHeroJpg from '../../images/faq/hero1920.jpg';
import faqHeroWebp from '../../images/faq/hero1920.webp';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import {
  HeroContainer,
  Hero,
  HeroInfoWrapper,
  HeroTitle,
  HeroWrapper,
  Title,
  AccordionAnswer,
  AccordionQuestion
} from './Faq.styles';
import {
  Container,
  Typography,
  Box,
  Accordion,
  Button
} from '../UI/elements';

const Faq = ({ collection, buttonContact }) => {
  console.log(collection);
  const isWebp = checkWebp();
  const heroImage = {
    webp: faqHeroWebp,
    jpg: faqHeroJpg
  };

  return (
    <ThemeProvider>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <HeroWrapper sx={{ py: 1, display: { xs: 'none', md: 'flex' }, px: { xs: 1, xl: 0 } }}>
          <HeroContainer>
            <Hero component="picture">
              <Hero component="img"src={heroImage[isWebp ? 'webp' : 'png']} alt={`${t('dictionary.alt_img')} hero`}/>
            </Hero>
            <HeroInfoWrapper>
              <Typography
                component="span"
                color="white"
                sx={{
                  fontWeigth: 'bold',
                  fontSize: '1rem'
                }}>
                {t('faq_page.pre-title')}
              </Typography>
              <HeroTitle
                sx={{ fontFamily: 'Cambon' }}
              >
                {t('faq_page.title')}
              </HeroTitle>
              <Typography
                component="span"
                color="white"
                sx={{
                  fontSize: '1rem'
                }}
              >
                {t('faq_page.sub-title')}
              </Typography>
            </HeroInfoWrapper>
          </HeroContainer>
        </HeroWrapper>
        <Box maxWidth={800} mx={{ xs: 1, sm: 3 }}>
          <Box
            sx={{
              mx: 1,
              mt: 5,
              display: { sm: 'block', md: 'none' }
            }}
          >
            <Title sx={{ fontFamily: 'Cambon', fontSize: '1.5rem' }}>
              {t('faq_page.pre-title')}
            </Title>
            <Typography>{t('faq_page.title')}</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              my: 4
            }}
          >
            <Title
              component="h2"
              sx={{ display: { xs: 'none', md: 'block' }, fontFamily: 'Cambon' }} >
              {t('faq_page.doubts-title')}
            </Title>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {collection && collection.map((item, index) => (
              <Box key={index} sx={{ width: '100%' }}>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    marginBottom: 0
                  }}
                  accordionSummaryContent={
                    <Title style={{ marginBottom: 0 }} component="h3">
                      {item.title}
                    </Title>
                  }
                  accordionDetailsContent={
                    item.items.map((items, index) => (
                      <Box key={index} sx={{ mb: 1.5 }}>
                        <AccordionQuestion id={items.html_id}>
                          {items.question}
                        </AccordionQuestion>
                        <AccordionAnswer>
                          {items.answer}
                        </AccordionAnswer>
                      </Box>
                    ))}
                />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              my: 3,
              width: '100%',
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'center' },
              flexDirection: 'column'
            }}
          >
            <Box sx={{ maxWidth: '100%' }}>
              <Title component="h2" sx={{ fontFamily: 'Cambon', fontSize: '1.5rem' }}>
                {t('faq_page.cant-find')}
              </Title>
              <Typography
                component="span"
                sx={{
                  fontSize: '0.95rem',
                  fontWeight: '300'
                }}
              >
                {t('faq_page.cant-find-text')}
              </Typography>
            </Box>
            <Button
              sx={{
                mt: 2,
                width: '100%'
              }}
              variant="outlined"
              href={buttonContact}
              size="large"
            >
              {t('faq_page.cant-find-btn')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Faq;
