import React from 'react';
import { Box } from '../../../elements';
import StepText from './StepText';
import StepImage from './StepImage';

import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { checkWebp } from '../../../../utils/checkWebp';
import { t } from '../../../../../js/common/translations';

import NumberTwo from '../../../../../images/tenant/timeline/number_2.svg';
import StepTwoWebp from '../../../../../images/tenant/timeline/step_2.webp';
import StepTwoJpg from '../../../../../images/tenant/timeline/step_2.jpg';
import CasatbStepTwo from '../../../../../images/casatb/tenant/timeline-two.png';

const StepTwo = ({ casatb }) => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = ['xs'].includes(currentBreakpoint);
  const isWebp = checkWebp();
  const isFloatPosition = !isMobile && currentBreakpoint !== 'sm';

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: `${isMobile ? 'center' : 'space-between'}`,
      alignItems: 'center',
      right: `${isFloatPosition ? '40px' : '0'}`,
      position: 'relative',
      flexDirection: `${isMobile ? 'column-reverse' : 'row'}`
    }}
    >
      <StepImage src={casatb ? CasatbStepTwo : (isWebp ? StepTwoWebp : StepTwoJpg)} />
      {!isMobile && (
        <Box
          component="img"
          src={NumberTwo}
          position="relative"
          right={{ sm: '4px', md: '62px' }}
        />
      )}
      <StepText
        title={t('tenant_page.timeline.step_two.title')}
        description={t('tenant_page.timeline.step_two.description_html')}
      />
    </Box>
  );
};

export default StepTwo;
