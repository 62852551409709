import React from 'react';
import DiamondImage from './DiamondImage';
import { checkWebp } from '../../../../utils/checkWebp';

import HeroImageThreeWebp from '../../../../../images/tenant/hero_3.webp';
import HeroImageThreeJpg from '../../../../../images/tenant/hero_3.jpg';
import CasatbDiamondThree from '../../../../../images/casatb/tenant/diamond-three.png';

const DiamondHeroImageThree = ({ casatb }) => {
  const isWebp = checkWebp();

  return (
    <DiamondImage
      width={{ xs: 140, sm: 250, lg: 360 }}
      height={{ xs: 140, sm: 250, lg: 360 }}
      sx={{
        width: { xs: 175, sm: 330, lg: 470 },
        height: { xs: 175, sm: 330, lg: 470 }
      }}
      src={casatb ? CasatbDiamondThree : (isWebp ? HeroImageThreeWebp : HeroImageThreeJpg)}
    />
  );
};

export default DiamondHeroImageThree;
