import styled from 'styled-components';
import { Box, Typography } from '../UI/elements';
import cites from '../../images/about_us/cites.png';
import kitchen from '../../images/about_us/kitchen.png';
import woman from '../../images/about_us/woman-smile.png';
import dinoMeoni from '../../images/about_us/roles/dino-meoni.png';
import leonardoMorgatto from '../../images/about_us/roles/leonardo-morgatto.png';
import simoneSurdi from '../../images/about_us/roles/simone-surdi.png';
import brazilJournalLogo from '../../images/series-a-news/brazil-journal-logo.png';
import estadaoLogo from '../../images/series-a-news/estadao-logo.svg';
import bloombergLogo from '../../images/series-a-news/bloomberg-linea-logo.png';
import valorEconomico from '../../images/series-a-news/valor-economico-logo.png';
import flavioMendonca from '../../images/casatb/about_us/flavio-mendonca.png';
import muriloGirio from '../../images/casatb/about_us/murilo-girio.png';

export const leadersImages = {
  dinoMeoni: dinoMeoni,
  leonardoMorgatto: leonardoMorgatto,
  simoneSurdi: simoneSurdi,
  flavio: flavioMendonca,
  murilo: muriloGirio
};

export const seriesALogos = {
  brazilJournalLogo: brazilJournalLogo,
  estadaoLogo: estadaoLogo,
  bloombergLogo: bloombergLogo,
  valorEconomico: valorEconomico
};

export const slideImages = {
  first: cites,
  second: kitchen,
  third: woman
};

export const HeroWrapper = styled(Box).attrs({
})`
  display: flex;
  height: calc(100vh - 60px);
`;

export const HeroContainer = styled(Box).attrs({
  component: 'section',
  className: 'hero',
  position: 'relative'
})`
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Hero = styled(Box).attrs({
  height: 1,
  position: 'absolute',
  minHeight: { xs: 600, md: 500 }
})`
  width: 100%;
  border-radius: 12px;
  height: 100%;
  object-fit: cover;

  &::after {
    content: '';
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.95) 0%, rgba(51, 51, 51, 0) 53.13%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 12px;
    height: 100%;
  }
`;

export const HeroInfoWrapper = styled(Box).attrs({
  minWidth: 272,
  textAlign: 'center',
  m: { xs: 1, md: 3 },
  zIndex: 5
})``;

export const Section = styled(Box).attrs({
  component: 'section'
})`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  display: ${({ casatb }) => casatb ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-evenly;
  flex-direction: ${({ seriesA }) => seriesA ? 'column' : 'row'};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 1rem;
  margin-top: 2rem;


  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    flex-direction: column;
    margin: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: ${({ inverted }) => inverted ? 'column-reverse' : 'column'} ;
    padding: 1rem;
  }
`;

export const SectionPreTitle = styled(Typography).attrs({
  className: 'text-tabas-salmon'
})`
  font-weight: 600;
  text-transform: uppercase;
`;

export const SectionContent = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  color: ${({ theme }) => theme.palette.primary[70]};
  max-width: 550px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    line-height: 1.25rem;
  }
`;

export const Slider = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  background-image: ${({ bgImageSrc }) => `url("${bgImageSrc}")`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 100%;
    padding: 0.5rem;
  }
`;

export const Scroller = styled(Box)`
  grid-template-columns: 100px 100px;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  width: 100%;
  white-space: normal;
  flex-wrap: nowrap;
  overflow-x: hidden;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    overflow-x: scroll;
  }
`;

export const ScrollWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${({ seriesA }) => seriesA ? '280px' : '175px'} ;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
`;

export const Title = styled(Typography).attrs({
  variant: 'h1',
  component: 'h2',
  sx: { fontFamily: 'Cambon' }
})`
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const PreTitle = styled(Typography).attrs({
  className: 'text-tabas-salmon',
  component: 'span'
})`
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ImgWithInfo = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 56px;
  width: 100%;
  background-color: #37465999;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
`;
