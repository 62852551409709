import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  SvgIcon,
  TextField,
  Typography
} from '../elements';
import EmailResend from '../../../images/react_icons/EmailResend';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';
import inputValidator from '../../utils/inputValidator';
import Toast from '../elements/Toast';
import { DividerWithText, StyledContainer, StyledLink, BackgroundBox } from './LoginForm.styles';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import LogoWithSlogan from './LogoWithSlogan';

const icons = (type) => {
  return {
    emailResend: EmailResend
  }[type];
};

const LoginForm = ({ errorMessage, isWebView = false }) => {
  const [type, setType] = useState('info');
  const [message, setMessage] = useState('');
  const [icon, setIcon] = useState('');
  const [buttonMessage, setButtonMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [formValues, setFormValues] = useState({
    showPassword: false,
    remember_me_checkbox: '0',
    email: '',
    password: ''
  });
  const [formErrorsText, setFormErrorsText] = useState([]);

  const formValidations = [
    ['email', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['email', { validation: 'email', errorMessage: t('form_errors.email_invalid') }],
    ['password', { validation: 'required', errorMessage: t('form_errors.empty_field') }]
  ];

  const handleChange = (inputName) => ({ target: { value, checked } }) => {
    const validate = {
      [inputName]: value
    };

    const errors = inputValidator(validate, formValidations.filter(el => el[0] === inputName));

    setFormValues(state => ({
      ...state,
      [inputName]: inputName === 'remember_me_checkbox' ? +checked : value
    }));
    setFormErrorsText(errors);
  };

  const handleShowPassword = () => {
    setFormValues({
      ...formValues,
      showPassword: !formValues.showPassword
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = inputValidator(formValues, formValidations);

    if (errors.length) {
      setFormErrorsText(errors);
    } else {
      sessionStorage.setItem('tabas-user-email', formValues.email);
      event.target.submit();
    }
  };

  const getErrorMessage = (inputName) => {
    return formErrorsText.filter(el => el.field === inputName)[0]?.error;
  };

  const setToastAttributes = ({ type, message, buttonMessage, icon = undefined }) => {
    setType(type);
    setMessage(message);
    setButtonMessage(buttonMessage);
    setIcon(icon);
    setOpen(true);
  };

  const sendReconfirmationEmail = async (email) => {
    try {
      await fetch('/member/confirmation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(email)
      });
    } catch (err) {
      setToastAttributes({ type: 'error', message: t('error.request'), buttonMessage: '' });
      console.error(err);
    }
  };

  const onAfterClickButton = () => {
    setToastAttributes({ type: 'success', message: t('devise.confirmations.send_instructions'), buttonMessage: '' });
  };

  useEffect(() => {
    if (errorMessage) {
      if (errorMessage === t('devise.failure.unconfirmed')) {
        setToastAttributes({ type: 'info', message: errorMessage, buttonMessage: t('login_page.resend_confirmation_email'), icon: 'emailResend' });
      } else {
        setToastAttributes({ type: 'error', message: errorMessage, buttonMessage: '' });
      }
    }
  }, [errorMessage]);

  const currBreakpoint = useBreakpoint();
  const isMobile = ['xs'].includes(currBreakpoint);

  return (
    <ThemeProvider>
      {!isWebView && (
        <BackgroundBox>
          {isMobile && (
            <LogoWithSlogan />
          )}
          <Box sx={{
            ...(isMobile ? {
              padding: '1rem 1rem 5rem 1rem'
            } : {
              paddingY: '3rem'
            })
          }}>
            <StyledContainer>
              <Typography
                align="center"
                component="h1"
                fontSize='1.5rem'
                variant="h3"
                mb={1}
              >
                {t('login_page.title')}
              </Typography>
              <Box>
                <Box
                  action={t('login_page.session_path')}
                  component="form"
                  onSubmit={handleSubmit}
                  method="POST"
                  novalidate
                >
                  <TextField
                    helperText={getErrorMessage('email')}
                    placeholder={t('login_page.email')}
                    error={!!getErrorMessage('email')}
                    onChange={handleChange('email')}
                    label={t('login_page.email')}
                    name="member[email]"
                    autoComplete="email"
                    margin="dense"
                    type="text"
                    autoFocus
                    fullWidth
                    sx={{ marginBottom: 0.5 }}
                  />
                  <TextField
                    sx={{ mb: 0 }}
                    type={formValues.showPassword ? 'text' : 'password'}
                    helperText={getErrorMessage('password')}
                    placeholder={t('login_page.password')}
                    error={!!getErrorMessage('password')}
                    onChange={handleChange('password')}
                    label={t('login_page.password')}
                    autoComplete="current-password"
                    name="member[password]"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      endAdornment:
                        <IconButton onClick={handleShowPassword} disableRipple>
                          <SvgIcon
                            pl={0.875}
                            name={formValues.showPassword ? 'eye_slash_password' : 'eye_password'}
                          />
                        </IconButton>
                    }}
                  />
                  <Box
                    justifyContent="space-between"
                    flexDirection="row"
                    display="flex"
                    marginTop={0.5}
                    marginBottom={1.5}
                  >
                    <Box display="flex" flexDirection="row">
                      <Checkbox
                        onClick={handleChange('remember_me_checkbox')}
                        name="member[remember_me]"
                        value={formValues.remember_me_checkbox}
                        sx={{ padding: 0, marginRight: 0.5 }}
                      />
                      <Typography variant="lead" alignSelf="center" fontSize={14} fontWeight="400">
                        {t('login_page.remember_password')}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    sx={{ marginBottom: 0.25 }}
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    {t('login_page.login')}
                  </Button>
                </Box>
                <StyledLink href={t('login_page.new_member_password_path')}>
                  {t('login_page.forgot_password')}
                </StyledLink>
                <DividerWithText>
                  {t('login_page.or')}
                </DividerWithText>
                <Button
                  startIcon={{ name: 'google_outline', size: '22' }}
                  href='/member/auth/google_oauth2'
                  variant="outlined"
                  sx={{
                    marginY: 0.5,
                    borderColor: 'border'
                  }}
                  size="large"
                  fullWidth
                >
                  {t('login_page.login_google')}
                </Button>
              </Box>
            </StyledContainer>
            <Typography
              variant="lead"
              display="flex"
              justifyContent="center"
              margin="1rem 0 0.5rem 0"
            >
              {t('login_page.ask_account')}
            </Typography>
            <StyledLink href={t('login_page.new_member_registration_path')}>
              {t('login_page.sign_up')}
            </StyledLink>
          </Box>
          <Toast
            type={type}
            text={message}
            buttonText={buttonMessage}
            icon={icons(icon)}
            onClickButton={async () => sendReconfirmationEmail({ member: { email: sessionStorage.getItem('tabas-user-email') } })}
            onAfterClickButton={onAfterClickButton}
            onClose={() => setOpen(false)}
            open={open}
          />
        </BackgroundBox>
      )}
    </ThemeProvider>
  );
};

export default LoginForm;
