function headerSpacer({ document }) {
  const headerSpacer = document.querySelector('#header-spacer');
  if (!headerSpacer) {
    return;
  }
  const header = document.querySelector('#header');
  if (!header) {
    return;
  }
  const rect = header.getBoundingClientRect();
  headerSpacer.style.paddingBottom = `${rect.height}px`;
}

function handleHeaderSpacer(window) {
  window.addEventListener('resize', () => headerSpacer(window));
  headerSpacer(window);
}

export default handleHeaderSpacer;
