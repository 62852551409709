import React from 'react';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import { useBreakpoint } from '../hooks/useBreakpoint';
import {
  SectionHero,
  Hero,
  HeroTitle,
  ApartmentsSection,
  ApartmentsSectionTextWrapper,
  SectionTitle
} from './BlackFriday.styles';
import { PropertyCardSlider, CTABottomBar } from '../UI/modules';
import { Box, Container, HtmlTranslation, SvgIcon } from '../UI/elements';
import Hero5Apartments from '../../images/new_year/hero_5apartments.png';
import Hero3Apartments from '../../images/new_year/hero_3apartments.png';
import Hero1Apartments from '../../images/new_year/hero_1apartments.png';

const BlackFriday = ({
  propertiesList = [],
  propertiesWithPrice = {},
  propertiesWithOriginalPrice = {}
}) => {
  const currentBreakpoint = useBreakpoint();
  const extraSmallScreen = ['xs'].includes(currentBreakpoint);
  const mediumScreen = ['md'].includes(currentBreakpoint);
  const largeScreen = ['lg', 'xl'].includes(currentBreakpoint);

  return (
    <ThemeProvider>
      <SectionHero sx={{ height: '100vh' }}>
        <Hero component="picture">
          <Hero backgroundColor="black" />
        </Hero>
        <Box position="absolute" zIndex={1} sx={{ textAlign: 'center', bottom: '0' }} mb={6}>
          <HeroTitle fontFamily="Cambon">
            <HtmlTranslation text={t('black_friday_page.title')} />
          </HeroTitle>
          {largeScreen ? (
            <Box component="img" pt={3} px={1} src={Hero5Apartments} maxHeight="10rem" />
          ) : mediumScreen ? (
            <Box component="img" pt={3} px={1} src={Hero3Apartments} maxHeight="12rem" />
          ) : (
            <Box component="img" pt={3} px={1} src={Hero1Apartments} maxHeight="14rem"/>
          )}
        </Box>
      </SectionHero>
      <ApartmentsSection py={{ xs: 1, md: 3.5 }}>
        <Container sx={{ px: { xs: 1, md: 2 } }} maxWidth="xl">
          <ApartmentsSectionTextWrapper mb={2} display="flex" flexDirection="column" alignItems="center">
            <SectionTitle>
              {t('black_friday_page.apartments_section.title')}
            </SectionTitle>
          </ApartmentsSectionTextWrapper>
          <PropertyCardSlider
            properties={propertiesList}
            withBody={false}
            seasonalLink={true}
            season="black_friday"
            customPrices={propertiesWithPrice}
            customOriginalPrices={propertiesWithOriginalPrice}
          />
        </Container>
      </ApartmentsSection>
      <CTABottomBar
        actionButtonProps={{
          children: (
            <>
              <SvgIcon name={extraSmallScreen ? 'whatsapp_light' : 'whatsapp'} size={20} mr={0.25} />
              {t('black_friday_page.whatsapp_us')}
            </>
          ),
          href: `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('black_friday_page.whatsapp_message')}`,
          target: '_blank',
          className: 'whatsapp-link'
        }}
        text={t('black_friday_page.whatsapp_text')}
      />
    </ThemeProvider>
  );
};

export default BlackFriday;
