import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Link,
  TextField
} from '../elements';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';
import inputValidator from '../../utils/inputValidator';

const ProfileForm = ({
  actionUrl,
  birthday,
  changePasswordUrl,
  email,
  fullname,
  phoneNumber,
  idNumber
}) => {
  const [profileFormErrorsText, setProfileFormErrorsText] = useState([]);
  const [profileFormValues, setProfileFormValues] = useState({
    birthday: birthday || '',
    email: email || '',
    fullname: fullname || '',
    phoneNumber: phoneNumber || '',
    idNumber: idNumber || ''
  });

  const idNumberValidation = (value) => {
    return /^\d{11}$|^[A-Z]+/.test(value) || !value;
  };

  const formValidations = [
    ['birthday', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['fullname', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phoneNumber', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['idNumber', { validation: (value) => idNumberValidation(value), errorMessage: t('form_errors.invalid_document') }]
  ];

  const handleChange = (inputName) => ({ target: { value } }) => {
    if (inputName === 'idNumber') value = value.split(/\.|-/).join(''); // no dots or hyphens are added

    const validate = {
      [inputName]: value
    };

    const errors = inputValidator(validate, formValidations.filter(el => el[0] === inputName));

    setProfileFormValues(state => ({
      ...state,
      [inputName]: value

    }));
    setProfileFormErrorsText(errors);
  };

  const getErrorMessage = (inputName) => {
    return profileFormErrorsText.filter(el => el.field === inputName)[0]?.error;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = inputValidator(profileFormValues, formValidations);

    if (errors.length) {
      setProfileFormErrorsText(errors);
    } else {
      event.target.submit();
    }
  };

  return (
    <ThemeProvider>
      <Box
        action={actionUrl}
        component="form"
        className="needs-validation"
        id="edit_user_80"
        onSubmit={handleSubmit}
        method='POST'
        novalidate
      >
        <Container
          disableGutters
          maxWidth="sm"
          sx={{
            borderRadius: 2,
            bgcolor: 'secondary.main',
            mt: [4, 2, 3],
            p: 3
          }}
        >
          <TextField
            error={!!getErrorMessage('fullname')}
            fullWidth
            helperText={getErrorMessage('fullname')}
            id= 'user_fullname'
            label= {t('personal_information_page.fullname')}
            name= 'user[fullname]'
            onChange={handleChange('fullname')}

            sx={{
              my: 0.75
            }}
            value= {profileFormValues.fullname}
          />
          <TextField
            error={!!getErrorMessage('idNumber')}
            fullWidth
            helperText={getErrorMessage('idNumber')}
            id= 'user_id_number'
            label= {t('personal_information_page.id_number')}
            name= 'user[id_number]'
            onChange={handleChange('idNumber')}
            sx={{
              my: 0.75
            }}
            value={profileFormValues.idNumber}
          />
          <TextField
            disabled
            error={!!getErrorMessage('email')}
            fullWidth
            id= 'user_email'
            helperText={getErrorMessage('email')}
            label= {t('login_page.email')}
            name= 'user[email]'
            onChange={handleChange('email')}
            sx={{
              my: 0.75
            }}
            value= {profileFormValues.email}
          />
          <TextField
            error={!!getErrorMessage('birthday')}
            fullWidth
            helperText={getErrorMessage('birthday')}
            id= 'user_birthday'
            label= {t('personal_information_page.birthday')}
            name= 'user[birthday]'
            onChange={handleChange('birthday')}
            sx={{
              my: 0.75
            }}
            type= 'date'
            value= {profileFormValues.birthday}
          />
          <TextField
            fullWidth
            id= 'user_phone_number'
            label= { t('personal_information_page.phone_number')}
            name= 'user[phone_number]'
            onBlur={handleChange('phoneNumber')}
            telinput= 'true'
            type= 'tel'
            sx={{
              my: 0.75
            }}
            value= {profileFormValues.phoneNumber}
          />
          <Box className="invalid-phone-number d-none text-tabas-dark mt-1 fs-8">
            {t('form_errors.phone_invalid')}
          </Box>
          <Box sx={{ mt: 1 }}>
            <Link
              href= {changePasswordUrl}
              underline="hover"
            >
              {t('personal_information_page.change_password')}
            </Link>
            <Box
              sx={{
                display: 'flex',
                my: 1,
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Button
                size="large"
                sx={{
                  mt: 1
                }}
                target="_blank"
                type="submit"
                variant="contained"
              >
                {t('personal_information_page.submit')}
              </Button>
            </Box>
          </Box>
          <input type='hidden' name='_method' value='patch' />
          <input type='hidden' name='utf8' value='✓' />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ProfileForm;
