import React from 'react';
import styled from 'styled-components';
import { Box, Typography, SvgIcon, Button } from '../UI/elements';

export const HeroContainer = styled(Box).attrs({
  component: 'section',
  display: 'flex',
  alignItems: { xs: 'flex-end', md: 'center' },
  className: 'hero',
  position: 'relative',
  justifyContent: 'center'
})`
`;

export const HeroImg = styled(Box).attrs({
  height: 1,
  width: 1,
  maxHeight: { xs: 1, lg: 1000 },
  position: 'relative',
  borderRadius: 3,
  minHeight: { xs: 1, lg: 800 }
})`
  height: calc(97vh - 56px);
  object-fit: cover;
`;

export const HeroInfoWrapper = styled(Box).attrs({
  position: 'absolute',
  width: 1,
  minWidth: 272
})``;

export const PreText = styled(Typography).attrs({
  textTransform: 'uppercase',
  color: 'primary.80',
  variant: 'lead',
  component: 'span',
  fontWeight: 600
})``;

export const StepsTitle = styled(Typography).attrs({
  variant: 'h3',
  color: 'primary.main',
  fontWeight: 800,
  mb: 1,
  sx: {
    fontFamily: 'Cambon'
  }
})``;

export const StepsDescription = styled(Typography).attrs({
  color: 'primary.main',
  fontWeight: 500,
  variant: 'lead',
  component: 'p'
})``;

export const LandlordWrapper = styled(Box).attrs({
  disableGutters: 'true',
  bgcolor: 'secondary.light',
  textAlign: 'center',
  borderRadius: 3,
  my: 4,
  pt: 2,
  pb: 3
})``;

const TabasHandleWrapper = styled(Box).attrs({
  display: 'flex',
  position: 'relative',
  placeContent: 'center',
  height: '100%',
  justifyItems: 'center'
})``;

export const TabasImgSlider = ({ mobile }) => (
  <TabasHandleWrapper>
    <Box
      sx={{
        zIndex: 1,
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: 0,
        alignSelf: 'center'
      }}
    >
      <SvgIcon name="image_slider" size={mobile ? '32' : '64'} />
    </Box>
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        pointerEvents: 'auto',
        position: 'absolute',
        height: '100%',
        left: ['15px', '15px', '30px'],
        flexGrow: 1,
        width: 4
      }}
    />
  </TabasHandleWrapper>
);

export const PlusMinusIconExpansion = styled(props => (
  <div {...props}>
    <SvgIcon className="minus" name="minus_icon"/>
    <SvgIcon className="plus" name="plus_icon"/>
  </div>
))`
  & > .plus {
    display: block;
  }
  & > .minus {
    display: none;
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

export const SliderButton = styled(Button).attrs(({ $mobile }) => ({
  height: $mobile ? 'small' : 'large'
}))`
  font-size: ${({ $mobile }) => ($mobile ? '0.8rem' : '1.2rem')};
  padding: ${({ $mobile }) => ($mobile ? '0.5rem 1rem' : '1.25rem 2rem')};
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.palette.primary.main};
`;
