import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import {
  Button,
  Box,
  Container,
  Grid,
  Typography,
  HtmlTranslation
} from '../UI/elements';
import {
  SearchField,
  FeedbackSlider,
  PropertyTabs,
  BuildingSection
} from '../UI/modules';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import xlHero from '../../images/home/hero/xl.jpg';
import xlHeroWebp from '../../images/home/hero/xl.webp';
import livingBookingImg from '../../images/home/living_booking.jpg';
import livingInteriorImg from '../../images/home/living_interior_design.png';
import livingSupportImg from '../../images/home/living_support.png';
import livingUtilitiesImg from '../../images/home/living_utilities.png';
import livingBookingImgWebp from '../../images/home/living_booking.webp';
import livingInteriorImgWebp from '../../images/home/living_interior_design.webp';
import livingSupportImgWebp from '../../images/home/living_support.webp';
import livingUtilitiesImgWebp from '../../images/home/living_utilities.webp';
import dinnerRoom from '../../images/about_us/dinner-room.png';
import lgCorporateImg from '../../images/home/corporate/lg.jpg';
import lgCorporateImgWebp from '../../images/home/corporate/lg.webp';
import smCorporateImg from '../../images/home/corporate/sm.jpg';
import smCorporateImgWebp from '../../images/home/corporate/sm.webp';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { checkWebp } from '../utils/checkWebp';

import {
  HeroSection,
  HeroContent,
  WhyTabasBox,
  BoxImage,
  TestimonialsSection,
  CorporateBanner,
  HeroImg,
  CorporateBannerImg
} from './Home.styles';
import LazyLoad from 'react-lazyload';

const Home = ({
  feedbacks,
  highlightedBuildings,
  isTabasUser,
  highlightedPropertiesByCity = []
}) => {
  const currentBreakpoint = useBreakpoint();
  const isWebp = checkWebp();
  const [currentCityTab, setCurrentCityTab] = useState(0);

  const firstSectionContent = [
    {
      image: isWebp ? livingBookingImgWebp : livingBookingImg,
      description: t('home_page.why-tabas.feature.flexible-booking')
    },
    {
      image: isWebp ? livingInteriorImgWebp : livingInteriorImg,
      description: t('home_page.why-tabas.feature.interior-design')
    },
    {
      image: isWebp ? livingSupportImgWebp : livingSupportImg,
      description: t('home_page.why-tabas.feature.support')
    },
    {
      image: isWebp ? livingUtilitiesImgWebp : livingUtilitiesImg,
      description: t('home_page.why-tabas.feature.utilities')
    }
  ];

  const heroImg = {
    xl: isWebp ? xlHeroWebp : xlHero
  };

  const corporateImgs = {
    sm: isWebp ? smCorporateImgWebp : smCorporateImg,
    lg: isWebp ? lgCorporateImgWebp : lgCorporateImg
  };

  return (
    <ThemeProvider>
      <HeroSection>
        <HeroImg component="picture">
          <HeroImg
            component="img"
            src={heroImg.xl}
            alt={`${t('dictionary.alt_img')}${t('home_page.hero.title')}`}
            sx={{
              ...(currentBreakpoint === 'xs' && {
                objectPosition: '65% center'
              })
            }}
          />
        </HeroImg>
        <HeroContent sx={{ p: 1, height: 1 }}>
          <Typography
            variant="h1"
            align="center"
            color="secondary"
            thickness="600"
            sx={{ fontFamily: 'Cambon' }}
            withShadow
          >
            {t('home_page.hero.title')}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            align="center"
            color="secondary"
            mt={1}
            withShadow
            sx={{
              ...(currentBreakpoint === 'xs' && {
                background: 'rgba(30, 37, 54, 0.2)',
                borderRadius: '0.5rem',
                padding: '1rem'
              })
            }}
          >
            {t('home_page.hero.description')}
          </Typography>
          <Box sx={{ width: 1, mt: 2, display: 'flex', justifyContent: 'center' }}>
            <SearchField variant="hero" />
          </Box>
        </HeroContent>
      </HeroSection>
      <Box component="section" className="reason-why" sx={{ bgcolor: 'background.default' }}>
        <Container sx={{ py: 3, textAlign: ['center', 'center', 'left'] }}>
          <Grid container columnSpacing={2}>
            <Grid
              xs={12}
              md={5}
              lg={6}
              item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="lead"
                sx={{
                  color: 'primary.light',
                  fontWeight: 'bold'
                }}
              >
                {t('home_page.why-tabas.section')}
              </Typography>
              <Typography
                variant="h1"
                component="h2"
                mt={1}
                mb={1.5}
                sx={{ fontFamily: 'Cambon' }}
              >
                <HtmlTranslation text={t('home_page.why-tabas.title_html')} />
              </Typography>
              <Typography
                component="p"
                variant="h5"
                sx={{
                  fontWeight: 'normal',
                  color: 'primary.light',
                  mb: 1
                }}
              >
                {t('home_page.why-tabas.description')}
              </Typography>
              <Box sx={{ display: { xs: 'none', md: 'block' }, mt: 1 }}>
                <Button
                  href={t('home_page.why-tabas.feature.book-now-path')}
                  size="large"
                >
                  {t('home_page.why-tabas.feature.book-now')}
                </Button>
              </Box>
            </Grid>
            <Grid
              xs={12}
              md={7}
              lg={6}
              item
            >
              <Grid
                pt={[0, 0, 2.25]}
                my={[0, 0, 2.5]}
                container
                rowSpacing={{ xs: 1 }}
                columnSpacing={{ xs: 1, md: 2 }}
              >
                {firstSectionContent.map((content, index) => (
                  <Grid
                    key={index}
                    xs={useMediaQuery('(max-width: 450px)') ? 12 : 6}
                    item
                    sx={{
                      ':nth-of-type(even)': {
                        mt: [0, 0, -2.25]
                      },
                      display: ['flex', 'flex', 'initial'],
                      justifyContent: 'center'
                    }}
                  >
                    <WhyTabasBox>
                      <BoxImage image={content.image} />
                      <Typography
                        component="span"
                        py={[0.875, 1.5]}
                        px={0.5}
                        sx={{ typography: 'h6' }}
                      >
                        {content.description}
                      </Typography>
                    </WhyTabasBox>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 2 }}>
                <Button
                  href={t('home_page.why-tabas.feature.book-now-path')}
                  size="large"
                >
                  {t('home_page.why-tabas.feature.book-now')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {!!highlightedBuildings.length && (
        <BuildingSection
          buildings={highlightedBuildings}
        />)}
      <Box component="section" className="property-owner" sx={{ bgcolor: isTabasUser ? 'background.default' : 'secondary.light', py: [3, 3, 5] }}>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ textAlign: ['center', 'left'] }}>
              <Typography
                variant="lead"
                sx={{
                  color: 'primary.light',
                  fontWeight: 'bold'
                }}
              >
                {t('home_page.landlords.section')}
              </Typography>
              <Typography
                variant="h1"
                component="h2"
                mt={1}
                mb={1.5}
                sx={{ fontFamily: 'Cambon' }}
              >
                <HtmlTranslation text={t('home_page.landlords.title_html')} />
              </Typography>
              <Typography
                variant="h5"
                component="p"
                sx={{
                  fontWeight: 'normal',
                  color: 'primary.light',
                  mb: 2
                }}
              >
                {t('home_page.landlords.description')}
              </Typography>
              <Box>
                <Button
                  href={t('home_page.landlords.landlords_path')}
                  size="large"
                  sx={{ display: ['none', 'inline-flex'] }}
                >
                  {t('home_page.landlords.cta')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LazyLoad>
                <Box
                  component="img"
                  alt={`${t('dictionary.alt_img')} ${t('home_page.landlords.section')}`}
                  src={dinnerRoom}
                  sx={{
                    width: 1,
                    height: 'auto',
                    borderRadius: 2.5
                  }}
                />
              </LazyLoad>
              <Box
                sx={{
                  mt: 2,
                  display: ['flex', 'none'],
                  width: 1,
                  justifyContent: 'center'
                }}
              >
                <Button href={t('home_page.landlords.landlords_path')} size="large">
                  {t('home_page.landlords.cta')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {!!feedbacks?.length && (
        <TestimonialsSection sx={{ py: 3 }} backgroundColor={isTabasUser ? 'secondary.light' : 'background.default'}>
          <Container sx={{ height: 1, marginTop: '1rem' }}>
            <Typography
              variant="h1"
              component="h2"
              align="center"
              sx={{ fontFamily: 'Cambon' }}
            >
              {t('home_page.feedbacks.title')}
            </Typography>
            <Box mt={1} sx={{ flex: 1 }}>
              <FeedbackSlider feedbacks={feedbacks} />
            </Box>
          </Container>
        </TestimonialsSection>
      )}
      <Box component="section" className="property-cards" sx={{ bgcolor: isTabasUser ? 'background.default' : 'secondary.light', py: 3 }}>
        <Container sx={{ px: [1, 1, 2] }} maxWidth="xl">
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h2"
              mt={1}
              mb={1.5}
              sx={{ fontFamily: 'Cambon' }}
            >
              {t('home_page.highlights.title')}
            </Typography>
            <Typography
              variant="h5"
              component="p"
              sx={{
                fontWeight: 'normal',
                color: 'primary.light',
                mb: 2
              }}
            >
              {t('home_page.highlights.description')}
            </Typography>
          </Box>
          <PropertyTabs
            tabs={highlightedPropertiesByCity.map(city => ({ ...city, label: city.name }))}
            activeTab={currentCityTab}
            onTabActiveChange={(tabIndex) => setCurrentCityTab(tabIndex)}
          />
        </Container>
      </Box>
      <Box
        className="corporate"
        component="section"
        sx={{ bgcolor: isTabasUser ? 'secondary.light' : 'background.default', py: [0, 0, 3.5] }}>
        <Container sx={{ px: [0, 0, 3] }}>
          <CorporateBanner>
            <CorporateBannerImg component="picture">
              <LazyLoad>
                <CorporateBannerImg
                  component="img"
                  src={corporateImgs[['xs', 'sm'].includes(currentBreakpoint) ? 'sm' : 'lg']}
                  alt={t('home_alt.corporate_furnished')}
                />
              </LazyLoad>
            </CorporateBannerImg>
            <Grid container>
              <Grid item xs={12} md={7} sx={{ textAlign: ['center', 'center', 'left'], zIndex: 1, p: 2.5 }}>
                <Typography
                  variant="lead"
                  component="span"
                  fontWeight="bold"
                  color={{ xs: 'secondary.dark', md: 'primary.light' }}
                >
                  {t('home_page.companies.section')}
                </Typography>
                <Typography
                  variant="h1"
                  component="h2"
                  color={{ xs: 'secondary.dark', md: 'primary.main' }}
                  mt={1}
                  mb={1.5}
                  sx={{ fontFamily: 'Cambon' }}
                >
                  {t('home_page.companies.title')}
                </Typography>
                <Typography
                  variant="h5"
                  component="p"
                  color={{ xs: 'secondary.dark', md: 'primary.light' }}
                  fontWeight={400}
                  mb={2}
                >
                  {t('home_page.companies.description')}
                </Typography>
                <Button
                  href={t('home_page.companies.companies_path')}
                  size="large"
                >
                  {t('home_page.companies.cta')}
                </Button>
              </Grid>
            </Grid>
          </CorporateBanner>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
