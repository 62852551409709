import arrayCompactor from './arrayCompactor';

export default (regexp, value) => {
  const reg = new RegExp(regexp);

  const rs = [];

  while (true) {
    const result = reg.exec(value);

    if (!result) break;

    result.forEach((res, index) => {
      if (index !== 0) {
        const val = rs[index - 1] ? rs[index - 1] : [];
        rs[index - 1] = [...val, res];
      }
    });
  }

  return arrayCompactor(rs);
};
