import styled from 'styled-components';
import { Box, Typography, Link } from '../UI/elements';

export const HeroWrapper = styled(Box).attrs({
  py: 2
})`
  height: calc(100vh - 60px);
`;

export const HeroContainer = styled(Box).attrs({
  component: 'section',
  display: 'flex',
  className: 'hero',
  position: 'relative',
  alignItems: 'flex-end',
  justifyContent: 'flex-start'
})`
  height: 100%;
`;

export const Hero = styled(Box).attrs({
  height: 1,
  width: 1,
  maxHeight: { xs: '100%', lg: 1000 },
  position: 'absolute',
  borderRadius: 3,
  minHeight: { xs: 600, md: 500 }
})`
  height: 100%;
  object-fit: cover;

  &::after {
    content: '';
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.95) 0%, rgba(51, 51, 51, 0) 53.13%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 1rem;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    object-position: ${({ casatb }) => casatb && '-270px'}
  }
`;

export const HeroInfoWrapper = styled(Box).attrs({
  minWidth: 272,
  maxWidth: { xs: 400, lg: 800 },
  textAlign: 'center',
  m: { xs: 1, md: 3 },
  zIndex: 5
})``;

export const HeroTitle = styled(Typography).attrs({
  variant: 'h1',
  color: 'white',
  fontSize: '2rem',
  fontWeight: 'bold',
  mt: 1.5,
  fontFamily: 'Cambon'
})``;

export const HeroPreTitle = styled(Typography).attrs({ variant: 'span' })`
  color: white;
  font-weight: bold;
`;

export const Card = styled(Box)`
  width: 250px;
  height: 100%;
  background: ${({ theme }) => theme.palette.secondary.dark};
  padding: 1.75rem 1.25rem;
  box-shadow: 0px 0px 2px rgba(41, 45, 50, 0.25);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
export const SvgWrapper = styled(Box)`
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;
export const CardInfoTitle = styled(Typography).attrs({
  variant: 'h5',
  component: 'h4'
})`
  font-size: 1rem;
  margin-bottom: 0.75rem;
`;

export const CardInfoText = styled(Typography).attrs({
  variant: 'body1',
  component: 'span'
})`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const CardInfoLinks = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.9rem;
  & span {
    font-size: 0.78rem;
  }
`;

export const SwiperWrapper = styled(Box)`
  display: none;
  justify-content: center;

  @media (max-width: 460px) {
    display: flex;
  }
`;

export const HorizontalWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  width: 100%;

  @media (max-width: 1024px) {
    justify-content: flex-start;
    overflow-x: scroll;
  }

  @media (max-width: 460px) {
    display: none;
  }
`;
